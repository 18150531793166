import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { GenericListApiResponse } from 'src/app/core/_interface/apiResponse';
import { environment } from 'src/environments/environment';
import { ConnectionMetadata } from '../_interface/ConnectionMetadata';

@Injectable({
  providedIn: 'root'
})
export class WSConnectionService {

  constructor(private http: HttpClient) {

  }

  connectionsForCompany(companyID: number) {
    const apiAddress = environment.apiBase + `connections/forCompany/${companyID}`;

    const response = this.http.post<GenericListApiResponse<ConnectionMetadata>>(apiAddress, null).pipe(
      map(data => {
        if (data.success) {
          return data.items
        }

        return null;
      })
    );

    return response;
  }

  all() {
    const apiAddress = environment.apiBase + `connections/all`;

    const response = this.http.post<GenericListApiResponse<ConnectionMetadata>>(apiAddress, null).pipe(
      map(data => {
        if (data.success) {
          return data.items
        }

        return null;
      })
    );

    return response;
  }
}
