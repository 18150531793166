
import { Pipe, PipeTransform } from '@angular/core';
import { BundleService } from '../_services/bundle.service';

@Pipe({ name: 'toBundleName' })
export class ToBundleNamePipe implements PipeTransform {
  constructor(private bundleService: BundleService) {

  }
  transform(id: number) {
    return this.bundleService.getName(id);
  }
}
