
export const accessDeniedDescriptions = {
  buyAddon: 'da kupite dodatak',
  getActiveAddons: 'da vidite aktivne dodatke',
  getOfficeElements: 'da vidite rezervacije',
  saveProduct: 'da sačuvate uslugu',
  getEmployees: 'da vidite zaposlene',
  saveEmployee: 'da sačuvate podatke o zaposlenom',
  saveCompany: 'da sačuvate podatke o firmi',
  saveOffice: 'da sačuvate podatke o poslovnici',
  getUsers: 'da vidite podatke o korisnicima',
  getStats: 'da vidite statistiku',
  saveSlot: 'da sačuvate podatke o poziciji',
  getReservations: 'da vidite rezervacije',
  setReservationStatus: 'da promenite status rezervacije',
  saveReservation: 'da sačuvate podatke o rezervaciji',
  changeCompanyBundle: 'da promenite paket firme',
  deleteSlot: 'da obrišete poziciju',
  generateApiKey: 'da kreirate API ključ',
  viewApiKey: 'da vidite API ključ',
  saveTimeSlot: 'da sačuvate radno vreme',
  getPriceList: 'da vidite cenovnike',
  savePriceList: 'da sačuvate cenovnik',
  saveLocation: 'da sačuvate lokaciju',
  saveMetadata: 'da sačuvate metadata',
  getInvoices: 'da vidite račune',
  saveMobileDevice: 'da sačuvate uređaj',
  getMobileDeviceList: 'da vidite listu uređaja'
};

