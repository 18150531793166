import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capsWarning'
})
export class CapsWarningPipe implements PipeTransform {

  transform(value?: string) {
    if (!value) {
      return false;
    }

    var capsCount = (value.match(/[A-Z]/g) || []).length;
    var ratio = capsCount / value.length;

    return capsCount > 1 && ratio > 0.4;

  }

}
