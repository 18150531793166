import Dexie, { Table } from 'dexie';

export interface ILogItem {
  timestamp: string;
  message: string;
  level: number;
  additional: any[] | undefined;
}

export class LogDB extends Dexie {
  log!: Table<ILogItem, number>;

  constructor() {
    super('log');

    this.version(1).stores({
      log: 'timestamp',
    });

    var startDate = new Date();
    startDate.setHours(startDate.getHours() - 48);


    this.log.where('timestamp').below(startDate.toISOString()).delete();
  }
}

export const logDB = new LogDB();
