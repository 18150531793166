<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 matDialogTitle cdkDragHandle>{{ config.dialogTitle }}</h2>
</div>
<mat-dialog-content *ngIf="config?.dialogMsg">
  <span [innerHTML]="config.dialogMsg" *ngIf="isDialogMsgHtml"></span>
  <span *ngIf="!isDialogMsgHtml">{{ config.dialogMsg }}</span>

  <input matInput [(ngModel)]="txtBoxValue" *ngIf="config?.dialogType === 'prompt'">

</mat-dialog-content>
<mat-dialog-actions class="btnContainer float-right">
  <button
    [matDialogClose]="config.dialogType === 'prompt' ? '' : 'cancel'"
    *ngIf="config?.dialogType !== 'alert'"
    class="btn btn-secondary"
  >
    {{ getValOrDefaultVal(config.cancelBtnLabel, "Cancel") }}
  </button>
  <button class="btn btn-primary" [matDialogClose]="config.dialogType === 'prompt' ? txtBoxValue : 'ok'">
    {{ getValOrDefaultVal(config.okBtnLabel, "Ok") }}
  </button>
</mat-dialog-actions>
