<app-dialog-title>Pretraga korisnika</app-dialog-title>
<mat-dialog-content>
  <mat-form-field class="example-full-width">
    <mat-label>Naziv/broj telefona</mat-label>
    <input
      type="text"
      [formControl]="nameControl"
      autocomplete="off"
      matInput
      #input
    />
  </mat-form-field>
  <mat-progress-spinner
    *ngIf="loading"
    mode="indeterminate"
  ></mat-progress-spinner>
  <div
    class="customer"
    *ngFor="let customer of customers$ | async"
    (click)="selected = customer"
    [class.selected]="selected === customer"
  >
    {{ customer.name }}
    <div class="detail">
      Tel: <span class="phoneNumber"> {{ customer.phoneNumber }}</span>
    </div>
    <div class="detail address">Adresa: {{ customer.address }}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="createNewCustomer()" >Novi korisnik</button>
  <button mat-stroked-button matDialogClose="">Otkaži</button>
  <button
    mat-raised-button
    color="primary"
    (click)="ok()"
    [disabled]="!selected"
  >
    OK
  </button>
</mat-dialog-actions>
