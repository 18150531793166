import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-select-due-date',
  templateUrl: './selectDueDate.component.html',
  styleUrls: ['./selectDueDate.component.scss'],
})
export class SelectDueDateComponent {
  selected = new Date();

}
