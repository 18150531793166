
import { Pipe, PipeTransform } from '@angular/core';
import { Allergens } from 'src/app/core/_interface/product';


@Pipe({ name: 'toAllergenClass' })
export class ToAllergenClassPipe implements PipeTransform {

  transform(allergen: Allergens): string {
    switch (allergen) {
      case (Allergens.Wheat):
        return 'icon-wheat';
      case (Allergens.Sulfur):
        return 'icon-sulphurdioxide';
      case (Allergens.Nut):
        return 'icon-treenut';
      case (Allergens.Sesame):
        return 'icon-sesame';
      case (Allergens.Soya):
        return 'icon-soya';
      case (Allergens.Peanuts):
        return 'icon-peanut';
      case (Allergens.Mustard):
        return 'icon-mustard';
      case (Allergens.Mollusca):
        return 'icon-molluscs';
      case (Allergens.Milk):
        return 'icon-milk';
      case (Allergens.Crustacean):
        return 'icon-crustacens';
      case (Allergens.Lupine):
        return 'icon-lupin';
      case (Allergens.Fish):
        return 'icon-fish';
      case (Allergens.Egg):
        return 'icon-eggs';
      case (Allergens.Celery):
        return 'icon-celery';
    }

    return '';
  }

}
