<div class="error" *ngIf="invalidSession">
  {{ "sessionExpired" | translate }}
</div>
<ng-container
  *ngIf="(myAuthService.currentUser | async) === null; else loggedIn"
>
  <ng-container *ngIf="pinConfig && showPINLogin; else noCompany">
    <app-pin-login
      [pinConfig]="pinConfig"
      (onLogout)="pinLogout()"
      (onLogedIn)="pinLoginCompleted($event)"
    ></app-pin-login>
  </ng-container>
  <ng-template #noCompany>
    <ng-container *ngIf="showLogInForm && !showLostPassword">
      <form
        class="loginForm"
        *ngIf="!isBusy; else loading"
        [formGroup]="loginForm"
      >
        <mat-form-field>
          <mat-label>eMail</mat-label>
          <input
            matInput
            autocomplete="username"
            required
            formControlName="username"
            #eMail
          />
          <app-virtual-keyboard
            matSuffix
            [control]="loginForm.get('username')"
            [inputElement]="eMail"
          ></app-virtual-keyboard>
          <mat-error
            *ngIf="
              loginForm.get('username')!.touched &&
              loginForm.get('username')!.hasError('required')
            "
          >
            {{ "fieldRequired" | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              loginForm.get('username')!.touched &&
              loginForm.get('username')!.hasError('email')
            "
          >
            {{ "invalidFormat" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Šifra</mat-label>
          <input
            matInput
            autocomplete="current-password"
            required
            formControlName="password"
            type="password"
            #password
          />
          <app-virtual-keyboard
            matSuffix
            [control]="loginForm.get('password')"
            [inputElement]="password"
          ></app-virtual-keyboard>

          <mat-error
            *ngIf="
              loginForm.get('password')!.touched &&
              loginForm.get('password')!.hasError('required')
            "
          >
            {{ "fieldRequired" | translate }}
          </mat-error>
        </mat-form-field>

        <button
          class="submit dropShadowPrimary"
          mat-raised-button
          color="primary"
          (click)="login()"
        >
          Login
        </button>

        <a
          [routerLink]=""
          class="lostPassword"
          (click)="showLostPassword = true"
          >Zaboravili ste šifru?</a
        >
      </form>
    </ng-container>

    <div *ngIf="showLostPassword && !showResetPasswordSent" class="loginForm">
      <h2>Zaboravljena Šifra</h2>
      <p>Unesti eMail adresu sa kojom ste se registrovali na sajt.</p>
      <mat-form-field>
        <input #resetMail matInput placeholder="eMail" autocomplete="off" />
      </mat-form-field>

      <button
        class="submit dropShadowPrimary"
        mat-raised-button
        color="primary"
        (click)="sendResetPasswordLink(resetMail.value)"
      >
        Pošalji
      </button>
    </div>

    <div *ngIf="showResetPasswordSent">
      <h2>Link poslat</h2>
      <p>Link za promenu šifre je poslat na navedenu adresu</p>
    </div>
    <div class="socialLogin" *ngIf="showLoginButtons">
      <div>
        <div class="line"></div>
        <span>Login</span>
      </div>

      <div class="buttons">
        <button
          class="socialLoginButton custom pinLogin"
          *ngIf="pinConfig"
          (click)="showPINLogin = true"
        >
          <mat-icon>password</mat-icon>
        </button>

        <button
          class="socialLoginButton custom"
          *ngIf="enableUsernamePasswordSignin"
          (click)="showLogInForm = true"
        >
          <img src="./assets/icons/icon-96x96.png" alt="" />
        </button>

        <button
          class="socialLoginButton custom"
          (click)="signInWithCode()"
          *ngIf="showQRLogin"
        >
          <img src="./assets/qr-icon.png" alt="" />
        </button>

        <div
          appGoogleSigninButtonDirective
          class="socialLoginButton custom"
          *ngIf="enableGoogleSignin"
        >
          <img src="./assets/google-icon.png" alt="" />
        </div>

        <button
          class="socialLoginButton custom"
          (click)="signInWithFB()"
          *ngIf="enableFacebookSignin"
        >
          <img src="./assets/FB-icon.png" alt="" />
        </button>
      </div>
    </div>
    <app-code-signin
      class="codeSignin"
      [successLoginRedirect]="successLoginRedirect"
      (loginCompleted)="codeLoginCompleted($event)"
      *ngIf="enableCodeSignin"
    ></app-code-signin>
  </ng-template>
</ng-container>

<ng-template #loading>
  <div class="loading">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>

<ng-template #loggedIn
  ><button mat-stroked-button (click)="logout()">LOGOUT</button></ng-template
>
