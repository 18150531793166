export class ShellAppConfig {
  appVersion = "";
  hasPrinter = false;
  get hasPayment (){
    return this.cardPaymentProvider !== "";
  };
  cardPaymentProvider = "";
  printOrderConfirmation = false;
  printLineCharacterCount = 0;
}

export var SHELL_APP_CONFIG: ShellAppConfig   = new ShellAppConfig();
