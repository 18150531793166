import { INGXLoggerConfig, INGXLoggerMetadata, INGXLoggerWriterService, NGXLoggerWriterService } from "ngx-logger";
import { CrossAppMessagingService } from "../_services/crossApp-messaging.service";
import { IMessage } from "../_interface/crossAppMessaging";
import { environment } from "src/environments/environment";



export class NativeLogger extends NGXLoggerWriterService {
  constructor(
    private crossAppMessaging: CrossAppMessagingService,
  ) {
    super(1);
  }

  override writeMessage(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): void {


    var msk: IMessage = {
      action: 'log',
      data: {
        message: metadata.message,
        level: metadata.level,
        additional: JSON.stringify(metadata.additional, this.errorReplacer),
      }
    };
    this.crossAppMessaging?.sendRawMessage(msk)

    if (!environment.production) {
      console.log(metadata.message);
    }
  }

  errorReplacer(key: any, value: any) {
    if (value instanceof Error) {
      return {
        name: value.name,
        message: value.message,
        stack: value.stack,
      };
    }
    return value;
  }


}
