<mat-form-field>
  <mat-label>Naziv</mat-label>
  <input matInput />
</mat-form-field>

<h3>Dostava</h3>
<mat-form-field>
  <mat-label>Naziv</mat-label>
  <input matInput formControlName="customer_userName" />
</mat-form-field>

<mat-form-field>
  <mat-label>Broj telefona</mat-label>
  <input matInput formControlName="customer_phone" />
</mat-form-field>

<mat-form-field>
  <mat-label>Adresa</mat-label>
  <input matInput formControlName="customer_address" />
</mat-form-field>
<button mat-raised-button color="primary" (click)="save()">Snimi</button>
