<mat-progress-bar
  [appProgressBarColor]="progressValue | orderProgressColor"
  [value]="progressValue"
  class="progressBar"
  [class.showTimer]="showTimer"
>
</mat-progress-bar>

<div
  class="timer"
  [class.timeUp]="timeUp"
  [class.noDueDate]="noDueDate"
  *ngIf="showTimer"
>
  <!-- uklanja space pre i posle datuma
    -->{{ remaining | date : "mm:ss"
  }}<!--
  -->
</div>
