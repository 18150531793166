import { map } from 'rxjs/operators';
import { PaymentType } from './../_interface/payment';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../_services/session.service';
import { Client } from '../_interface/client';

@Pipe({
  name: 'isPaymentTypeEnabled'
})
export class IsPaymentTypeEnabledPipe implements PipeTransform {

  constructor(private session: SessionService) {


  }
  transform(value: PaymentType, client?: Client) {
    return this.session.companySettings.pipe(
      map(val => {
        if (!val) {
          return false;
        }
        var enabled = val.allowedPaymentTypes.indexOf(value) > -1

        if (enabled) {
          enabled = (client && client.allowedPaymentTypes && client.allowedPaymentTypes.length > 0 && client.allowedPaymentTypes.indexOf(value) > -1) ?? true;
        }

        return enabled
      })
    )
  }

}
