<app-dialog-title>
  Detalji o porudžbini <span class="orderNo">#{{ orderID }}</span>
</app-dialog-title>

<mat-dialog-content>
  <ng-container *ngIf="order; else loading">
    <app-property-item name="id" [value]="order.id"></app-property-item>
    <app-property-item
      name="Broj porudžbine"
      [value]="order.orderSerial"
    ></app-property-item>
    <app-property-item
      name="Kreirana"
      [value]="order.acceptedAt | date: 'dd.MM.yyyy HH:mm:ss'"
    ></app-property-item>
    <app-property-item
      name="Preuzimanje"
      [value]="order.pickupAt | date: 'dd.MM.yyyy HH:mm:ss'"
    ></app-property-item>
    <app-property-item
      name="Isporučeno"
      [value]="order.deliveredAt | date: 'dd.MM.yyyy HH:mm:ss'"
    ></app-property-item>
    <app-property-item name="Status" [value]="order.status"></app-property-item>
    <app-property-item name="Ukupno">
      <span class="price">
        {{ 0 | number: "1.2-2" }}
      </span>
    </app-property-item>
    <app-property-item
      name="Napomena"
      [value]="order.comment"
    ></app-property-item>

    <app-group-box title="Korisnik">
      <app-property-item
        name="Naziv"
        [value]="order.userName"
      ></app-property-item>
    </app-group-box>
    <app-group-box title="Artikli">
      <div *ngFor="let item of order.orderItems">
        <div class="item">
          <div class="name">
            {{ item.count }}x
            <span>{{ item.itemName }} - {{ item.option }} </span>
          </div>
          <div class="price">
            {{ item.optionPrice | number: "1.2-2" }}
          </div>
        </div>

        <div class="modifiers">
          <div *ngFor="let option of item.addons" class="item">
            <div class="name">
              <span>{{ option.group }}</span
              >-<span>{{ option.name }}</span>
            </div>
            <div class="price"></div>
          </div>
        </div>
      </div>
    </app-group-box>
  </ng-container>

  <ng-template #loading>
    <mat-spinner></mat-spinner>
  </ng-template>
</mat-dialog-content>
