import { AuthorizationService } from 'src/app/core/_services/authorization.service';
import { SessionService } from '../_services/session.service';
import { OrderStatus } from '../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';
import { AdminPermissions, AllPermissions, Permissions } from '../_interface/permissions';


@Pipe({ name: 'hasAdminPermission' })
export class HasAdminPermissionPipe implements PipeTransform {

  transform(permissions: AdminPermissions[] | Permissions[] | null, required: Permissions | AdminPermissions): boolean {


    return permissions?.some(p => p == required) || false;
  }
}
