import { OrderService } from '../_services/order.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer, DeliveryType, Order, OrderStatus, PlacedOrder } from 'src/app/core/_interface/order';
import { SessionService } from 'src/app/core/_services/session.service';
import { CustomerEditComponent } from '../customer-edit/customer-edit.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  orders?: PlacedOrder[];

  @Input() customer?: Customer;

  constructor(private orderService: OrderService,
    private router: Router,
    private toast: ToastrService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Customer,
    private session: SessionService) {
    this.customer = data;
  }

  ngOnInit(): void {
    if (this.customer!.customerID) {
      this.orderService.customerOrderHistory(this.session.companyID, this.customer!.customerID).subscribe(
        orders => this.orders = orders
      )
    }
  }

  repeatOrder(oldOrder: PlacedOrder, e: Event) {

    var o = new Order();
    o.customer = this.customer;
    o.deliveryDetail = {
      name: this.customer!.name,
      phoneNumber: this.customer!.phoneNumber,
      address: this.customer!.address,
      note: ''
    };

    o.deliveryType = DeliveryType.Pickup;
    o.companyID = this.session.companyID;
    o.status = OrderStatus.Accepted;

    oldOrder.items.forEach(i => {
      o.addItem(i)
    });

    this.router.navigate(["/app/order"], { state: o });

    this.dialog.closeAll();

    e.preventDefault();
    e.stopPropagation();
  }

  editCustomerDetails() {
    var ref = this.dialog.open(CustomerEditComponent, { data: this.customer });
    ref.afterClosed().subscribe({
      next: (customer: Customer) => {
        if (customer) {
          Object.assign(this.customer!, customer);
        }
      }
    });
  }

  showOrder(order: PlacedOrder) {
    this.orderService.getOrder(order.orderID).subscribe({
      next: (order: Order) => {
        this.dialog.open(OrderDetailsComponent, { data: order });
      },
      error: (err) => {
        this.toast.error("Greška prilkom učitavanja porudžbine");
      }
    });
  }
}
