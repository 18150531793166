import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceNewLine'
})
export class ReplaceNewLinePipe implements PipeTransform {

  transform(value: string, replaceChar = '; ') {
    return value.split('\n').filter(a => a).join(replaceChar);
    //return value.replace(/(?:\r\n|\r|\n)/g, replaceChar);
  }

}
