import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class OfflineDetectorInterceptor implements HttpInterceptor {
  messageVisible = false;

  constructor() {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // check to see if there's internet
    if (!window.navigator.onLine) {
      // if there is no internet, throw a HttpErrorResponse error
      // since an error is thrown, the function will terminate here
      if (!this.messageVisible) {
        this.messageVisible = true;
        Swal.fire({
          icon: 'error',
          title: 'Niste povezani na internet',
        })
          .finally(() => {
            this.messageVisible = false;
          });
      }

      throw new HttpErrorResponse({ error: 'Internet is required.' });

    } else {
      if (this.messageVisible){
        Swal.close();
      }
      // else return the normal request
      return next.handle(request);
    }
  }
}
