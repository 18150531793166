import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParse'
})
export class JsonParsePipe implements PipeTransform {

  transform(value: string ) {
    return JSON.parse(value);
  }

}
