<ng-container *ngIf="order">
  <mat-icon

    *ngIf="order | isInvalidOrder"
    class="material-symbols-outlined invalidOrder"
  >
    priority_high</mat-icon
  >
  <mat-icon
    *ngIf="
      order.status === OrderStatus.New ||
      order.status === OrderStatus.NewPreorder
    "
  >
    new_releases</mat-icon
  >
  <mat-icon
    *ngIf="
      order.status === OrderStatus.NewPreorder ||
      order.status === OrderStatus.Pending
    "
  >
    timer</mat-icon
  >
  <ng-container [ngSwitch]="order.deliveryType">
    <ng-container *ngSwitchCase="DeliveryType.Pickup">
      <mat-icon> takeout_dininl </mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="DeliveryType.Delivery">
      <mat-icon>delivery_dining</mat-icon>
    </ng-container>
  </ng-container>
</ng-container>
