import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap } from 'rxjs';
import { SessionService } from '../_services/session.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Client } from '../_interface/client';
import { ClientService } from '../_services/client.service';
import { ClientEditComponent } from '../client-edit/client-edit.component';
import { ClientEditDialogComponent } from '../client-edit-dialog/client-edit-dialog.component';

@Component({
  selector: 'app-search-clients',
  templateUrl: './search-clients.component.html',
  styleUrls: ['./search-clients.component.scss']
})
export class SearchClientsComponent implements OnInit, AfterViewInit {
  clients$: Observable<Client[]>;
  nameControl = new FormControl();
  selected?: Client;
  loading = false;

  @ViewChild("input") input?: ElementRef<HTMLInputElement>;

  constructor(
    clientService: ClientService,
    private session: SessionService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SearchClientsComponent>

  ) {

    dialogRef.disableClose = true;

    this.clients$ = this.nameControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter((name) => !!name && !(typeof (name) === "object")),
      switchMap(name => {
        this.loading = true;
        return clientService.search(this.session.companyID, name).pipe(
          catchError(() => []),
          finalize(() => this.loading = false)
        )
      })
    );

  }
  ngAfterViewInit(): void {
    this.input?.nativeElement.focus();
  }

  ngOnInit(): void {
  }


  createNewClient() {
    var ref = this.dialog.open(ClientEditDialogComponent);
    ref.afterClosed().subscribe((client) => {
      if (client) {
        this.selected = client;
        this.ok();
      }
    });
  };

  ok() {
    this.dialogRef.close(this.selected);
  }
}
