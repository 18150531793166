<app-dialog-title>Promena podataka</app-dialog-title>
<mat-dialog-content>
  <!-- define dialog for all properties in Customer using formcontrol -->
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Naziv</mat-label>
      <input matInput placeholder="Naziv" formControlName="name" required />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Adresa</mat-label>
      <input matInput placeholder="Adresa" formControlName="address" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Broj telefona</mat-label>
      <input
        matInput
        placeholder="Broj telefona"
        formControlName="phoneNumber"
      />
    </mat-form-field>
    <ng-container
      *ngIf="
        (session.userPermissions | async) || []
          | hasPermission : Permissions.ModifyPrices
      "
    >
      <mat-form-field>
        <mat-label>Popust</mat-label>
        <input
          matInput
          type="number"
          formControlName="discount"
          placeholder="Popust"
        />
        <span matSuffix>%</span>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Aktivni cenovnik</mat-label>
        <mat-select formControlName="priceListName">
          <mat-option [value]="">---</mat-option>
          <mat-option *ngFor="let item of priceListNames" [value]="item">{{
            item
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <mat-form-field>
      <mat-label>Napomena</mat-label>
      <textarea
        matInput
        placeholder="Napomena"
        formControlName="note"
      ></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button matDialogClose="">Otkaži</button>
  <button mat-raised-button color="primary" (click)="save()">Sačuvaj</button>
</mat-dialog-actions>
