import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PriceList } from '../_interface/pricelist';
import { ApiResponse, GenericItemApiResponse, GenericListApiResponse } from 'src/app/core/_interface/apiResponse';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceListService {

  constructor(public http: HttpClient) {

  }

  get(companyID: number) {
    const url = environment.apiBase + `price/forCompany/${companyID}`;

    return this.http.get<GenericListApiResponse<PriceList>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw response.errorDescription;
        }),
      );
  }

  getListNames(companyID: number) {
    const url = environment.apiBase + `price/listNames/${companyID}`;

    return this.http.get<GenericListApiResponse<string>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw response.errorDescription;
        }),
      );
  }


  save(companyID: number, list: PriceList) {
    const url = environment.apiBase + `price/save/${companyID}`;

    return this.http.post<GenericItemApiResponse<number>>(url, list)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          throw response.errorDescription;
        }),
      );
  }


}
