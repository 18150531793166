import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginResult } from '../core/_interface/apiResponse';
import { AuthorizationService } from '../core/_services/authorization.service';
import { NotificationService } from '../core/_services/notification.service';
import { environment } from 'src/environments/environment';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  environment = environment;
  destroy = new Subject<void>();

  @Input() companyID: number | undefined;

  constructor(activatedRoute: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private notificationService: NotificationService,
    public myAuthService: AuthorizationService) {

    myAuthService.currentUser
      .pipe(
        takeUntil(this.destroy)
      ).subscribe(x => {
        if (x) {
          this.loggedIn();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  loggedIn() {
    this.router.navigate(['/app']);

  }

  loginCompleted(result: LoginResult) {
    switch (result) {
      case (LoginResult.OK):
        this.loggedIn();
        break;
      case (LoginResult.Disabled):
        this.toast.error('Nalog je zaključan');
        break;
      case (LoginResult.InvalidToken):
        this.toast.error('Neispravan token');
        break;
      case (LoginResult.InvalidUserName):
        this.toast.error('Pogrešno korisničko ime i/ili šifra');
        break;
    }
  }


}
