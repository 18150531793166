
export interface WoltOrder {
  id: string;
  venue: Venue;
  price: Price;
  delivery: Delivery;
  items: OrderItem[];
  created_at: string;
  consumer_comment: string;
  pickup_eta: string;
  attribution_id: any;
  type: string;
  pre_order: any;
  consumer_name: string;
  consumer_phone_number: string;
  order_number: string;
  order_status: OrderStatus;
  modified_at: string;
  company_tax_id: any;
  loyalty_card_number: any;
}

export interface Venue {
  id: string;
  name: string;
}

export interface Price {
  amount: number;
  currency: string;
}

export enum OrderStatus {
  received,
  fetched,
  acknowledged,
  production,
  ready,
  delivered,
  rejected,
  refunded
}

export interface OrderItem {
  item_type: string;
  id: string;
  count: number;
  pos_id: string;
  sku: string;
  gtin: string;
  options: Option[];
  total_price: Price;
  base_price: Price;
  unit_price: Price;
  name: string;
  category: Category;
  row_number: number;
}

export interface Option {
  id: string;
  name: string;
  value: string;
  price: Price;
  pos_id: any;
  count: number;
  value_pos_id: any;
}
export interface Location {
  street_address: string;
  apartment: string;
  city: string;
  country: string;
  coordinates: Coordinates;
  formatted_address: string;
}
export interface Delivery {
  status: string;
  type: string;
  time: string | null;
  fee: Price;
  location: Location;
  small_order_surcharge: Price;
}

export interface Coordinates {
  lon: number;
  lat: number;
}

export interface Category {
  id: string;
  name: string;
}

export interface ProductMap {
  companyID: number;
  sourceProductID: number;
  optionPosID: string;
  resultProductID: number;
}
