<app-dialog-title>Istorija promena</app-dialog-title>
<div class="ids">
  <div>
    ID: <span class="primaryColor">#{{ order!.orderNo }}</span> ({{
      order!.orderID
    }})
  </div>
</div>
<table class="default">
  <thead>
    <tr>
      <th>{{ "date" | translate }}</th>
      <th>{{ "status" | translate }}</th>
      <th>{{ 'modifiedBy' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of history">
      <td>{{ item.date | date : "dd.MM.yy. HH:mm:ss" }}</td>
      <td>{{ item.status | orderStatus | translate}}</td>
      <td>{{ item.changedBy }}</td>
    </tr>
  </tbody>
</table>
