import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appPOSName',
})
export class POSNamePipe implements PipeTransform {

  transform(value?: string) {
    if (!value) {
      return '';
    }
    return value.substring(0, value.indexOf(':'));;
  }

}
