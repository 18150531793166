import { filter, map } from 'rxjs/operators';

import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../_interface/product';
import { SessionService } from '../_services/session.service';


@Pipe({ name: 'toProduct' })
export class ToProductPipe implements PipeTransform {
  constructor(private session: SessionService) {


  }
  transform(productID: number) {
    if (!productID) {
      return null;
    }

    if (!this.session.products.value) {
      return null;
    }
    return this.session.products.pipe(
      map(
        list => {
          return list.find(p => p.productID === productID);
        }
      )

    )
  }
}
