import { QRCODE_SCANER } from './../_interface/IQRCodeScanner';
import { filter, first, switchMap, take, takeUntil } from 'rxjs/operators';
import { MatKeyboardService } from 'angular-onscreen-material-keyboard';
import { Subject } from 'rxjs';
import { Component, Input, OnInit, Output, ViewEncapsulation, AfterViewInit, OnDestroy, inject, Inject, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { LoginResult } from '../_interface/apiResponse';
import { AuthorizationService } from '../_services/authorization.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { IQRCodeScaner } from '../_interface/IQRCodeScanner';
import { SessionService } from '../_services/session.service';
import { CompanyService } from '../_services/company.service';
import { ICompany } from '../_interface/company';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'core-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  isBusy = false;
  loginForm: UntypedFormGroup;
  environment = environment;
  destroyed = new Subject<void>();
  showLostPassword = false;
  showResetPasswordSent = false;

  private _showLogInForm = false;
  public get showLogInForm() {
    return this._showLogInForm;
  }
  @Input()
  public set showLogInForm(value) {
    this._showLogInForm = value;
  }

  invalidSession = false;

  @Output() loggedIn = new Subject<void>();

  @Input()
  showQRLogin = false;

  @Input()
  successLoginRedirect = '/';

  @Input()
  enableCodeSignin = false;

  @Input()
  enableGoogleSignin = true;

  @Input()
  enableFacebookSignin = true;

  @Input()
  enableUsernamePasswordSignin = true;

  @Input()
  showPINLogin = false;

  get showLoginButtons() {
    return this.enableGoogleSignin || this.enableFacebookSignin || this.enableUsernamePasswordSignin;
  }

  handleToken() {

  }
  constructor(private authService: SocialAuthService,
    fb: UntypedFormBuilder,
    public myAuthService: AuthorizationService,
    private router: Router,
    private toast: ToastrService,
    private userService: UserService,
    private ks: MatKeyboardService,
    public session: SessionService,
    private translate: TranslateService,
    private cs: CompanyService,
    location: Location,
    activatedRoute: ActivatedRoute,
    @Inject(QRCODE_SCANER) private qrCodeScaner: IQRCodeScaner
  ) {

    this.invalidSession = (location.getState() as any)?.['invalidSession'];

    this.authService.authState.pipe(
      takeUntil(this.destroyed)
    ).subscribe((user) => {
      if (!user) {
        return;
      }

      switch (user.provider) {
        case ('GOOGLE'):
          this.sendGoogleAuthToken(user.idToken);
          break;
        case ('FACEBOOK'):
          this.sendFacebookAuthToken(user.authToken);
          break;
      }
    });

    activatedRoute.params.pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      params => {
        var token = params["token"];
        if (token) {
          myAuthService.loginWithToken(token).subscribe(
            result => {
              this.loginCompleted(result);
            }
          )
        }
      }
    )

    this.loginForm = fb.group({
      'username': '',
      'password': ''
    });

  }
  ngOnDestroy(): void {
    this.ks.dismiss();
    this.destroyed.next();
    this.destroyed.complete();
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        // on success
        this.isBusy = true;
        // this will return user data from google. What you need is a user token which you will send it to the server
      });
  }

  sendGoogleAuthToken(token: string) {
    this.myAuthService.googleSignInExternal(token)
      // .pipe(finalize(() => this.isLoading = false))
      .subscribe(result => {
        this.loginCompleted(result);
      });
  }

  signInWithFB(): void {

    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, { enable_profile_selector: true })
      .then((userData) => {
        this.isBusy = true;
        // on success
        // this will return user data from google. What you need is a user token which you will send it to the server
      });
  }

  sendFacebookAuthToken(token: string) {
    this.myAuthService.facebookSignInExternal(token)
      // .pipe(finalize(() => this.isLoading = false))
      .subscribe(result => {
        this.loginCompleted(result);

      });
  }

  signInWithCode() {
    var previousData = '';

    this.qrCodeScaner.start().subscribe(
      data => {
        if (previousData == data) {
          return;
        }
        previousData = data;
        this.qrCodeScaner.disable();

        this.myAuthService.loginWithToken(data).subscribe(
          result => {
            if (result == LoginResult.OK) {
              this.qrCodeScaner.stop();
            }

            this.qrCodeScaner.enable();

            this.loginCompleted(result);
          }
        )
      }
    );
  }

  logout(): void {
    this.myAuthService.signOut();
  }


  login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.isBusy = true;

    this.myAuthService.login(this.loginForm.value).subscribe(
      result => {
        this.loginCompleted(result);
      }
    );
  }

  loginCompleted(result: LoginResult) {
    this.isBusy = false;
    switch (result) {
      case (LoginResult.OK):
        this.loggedIn.next();

        this.router.navigate([this.successLoginRedirect]);

        this.setCompanyForPINLogin();
        break;
      case (LoginResult.Disabled):
        this.toast.error(this.translate.instant('accountLocked'));
        break;
      case (LoginResult.AccessDenied):
        this.toast.error(this.translate.instant('accessDenied'));
        break;
      case (LoginResult.InvalidToken):
        this.toast.error(this.translate.instant('invalidToken'));
        break;
      case (LoginResult.InvalidUserName):
        this.toast.error(this.translate.instant('invalidUsernameOrPassword'));
        break;
    }
  }

  setCompanyForPINLogin() {
    var c: ICompany;
    this.cs.getUserCompanies().pipe(
      filter(companies => companies.length == 1),
      switchMap(company => {
        c = company[0];
        return this.cs.getSettings(c.companyID)
      })
    ).subscribe({
      next: settings => {
        if (settings) {
          if (settings.pinToken == null) {
            this.pinConfig = null;
          }
          else {
            var pinConfig = {
              companyID: c.companyID,
              token: settings.pinToken,
              companyName: c.name,
            }

            localStorage.setItem('pinConfig', JSON.stringify(pinConfig));
          }
        }
      }
    });
  }

  pinLogout() {
    this.pinConfig = null;
  }

  set pinConfig(config: any) {
    if (!config) {
      localStorage.removeItem('pinConfig');
    }
    else {
      localStorage.setItem('pinConfig', JSON.stringify(config));
    }
  }

  get pinConfig(): any {
    var config = localStorage.getItem('pinConfig');
    if (!config) {
      return null;
    }
    var jConfig = JSON.parse(config);
    if (jConfig.token) {
      return jConfig;
    }

    return null;
  }

  pinLoginCompleted(jwt: string) {
    this.loginCompleted(LoginResult.OK);
  }

  codeLoginCompleted(jwt: string) {
    this.myAuthService.loginCompleted(jwt);
    this.loginCompleted(LoginResult.OK);
  }

  sendResetPasswordLink(eMail: string) {
    this.userService.sendResetPasswordLink(eMail).subscribe(() => {
      this.showResetPasswordSent = true;
    });
  }
}
