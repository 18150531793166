<app-dialog-title>Device ID:{{ device?.deviceID }}</app-dialog-title>
<form [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input
      matInput
      autocomplete="off"
      formControlName="name"
      placeholder="Device name"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Konobar verzija</mat-label>
    <input
      matInput
      autocomplete="off"
      formControlName="waiterAppVersion"
      placeholder="Konobar verzija"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>KitchenDisplay verzija</mat-label>
    <input
      matInput
      autocomplete="off"
      formControlName="kitchenDisplayAppVersion"
      placeholder="KitchenDisplay verzija"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>CustomerDisplay verzija</mat-label>
    <input
      matInput
      autocomplete="off"
      formControlName="customerDisplayAppVersion"
      placeholder="CustomerDisplay verzija"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Admin verzija</mat-label>
    <input
      matInput
      autocomplete="off"
      formControlName="adminAppVersion"
      placeholder="Admin verzija"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Kiosk app verzija</mat-label>
    <input
      matInput
      autocomplete="off"
      formControlName="kioskAppVersion"
      placeholder="Kiosk app verzija"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>User app verzija</mat-label>
    <input
      matInput
      autocomplete="off"
      formControlName="userAppVersion"
      placeholder="User app verzija"
    />
  </mat-form-field>
  <!-- Add more mat-form-field elements for other fields -->
</form>
<mat-dialog-actions class="actions">
  <button
    mat-raised-button
    color="primary"
    [disabled]="isBusy"
    (click)="submit()"
    [IsBusy]="isBusy"
  >
    Snimi
  </button>
  <button
    [disabled]="isBusy"
    [IsBusy]="isBusy"
    [mat-menu-trigger-for]="actions"
    mat-stroked-button
    color="primary"
  >
    ...
  </button>
  <mat-menu #actions>
    <button mat-menu-item (click)="requestNativeLog()">Traži log</button>
    <button mat-menu-item (click)="checkVersion()">Check version</button>
    <button mat-menu-item (click)="reload()">Reload</button>
  </mat-menu>
</mat-dialog-actions>
