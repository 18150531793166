<div
  class="elementContainer"
  [class.top]="location === 'top'"
  [class.bottom]="location === 'bottom'"
>
  <ng-container
    *ngTemplateOutlet="description ? description : defaultDescription"
  ></ng-container>
  <button type="button" mat-raised-button color="primary" (click)="save.emit()">
    {{ 'save' | translate }}
  </button>
</div>

<ng-template #defaultDescription><div></div></ng-template>
