import { filter, map } from 'rxjs/operators';
import { OrderStatus } from 'src/app/core/_interface/order';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../_services/session.service';

@Pipe({
  name: 'statusAvailable'
})
export class StatusAvailablePipe implements PipeTransform {

  constructor(private session: SessionService) {

  }
  transform(status: OrderStatus) {

    return this.session.companySettings.pipe(
      map((settings) => settings?.allowedOrderStatus.some(s => s == status) || false)
    );

  }

}
