<core-login
  [showQRLogin]="true"
  [enableCodeSignin]="true"
  [enableGoogleSignin]="!environment.nativeApp"
  [enableFacebookSignin]="!environment.nativeApp"
  (loggedIn)="loggedIn()"
  [showPINLogin]="true"
  successLoginRedirect="/app"
  class="loginForm"
></core-login>
<a
  target="_blank"
  class="apkDownload"
  href="emeni_konobar.apk"
  *ngIf="!environment.nativeApp"
>
  <img src="assets/android_download.png" alt="Download APK" />
</a>
