import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup, FormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR, ControlContainer, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: "app-name-translation-edit",
  templateUrl: "./name-translation-edit.component.html",
  styleUrls: ["./name-translation-edit.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NameTranslationEditComponent
    }
  ]
})
export class NameTranslationEditComponent implements OnInit, ControlValueAccessor, OnDestroy {
  formGroup!: UntypedFormGroup;

  onChange: Function = () => { };
  onTouched: Function = () => { };
  onDestory = new Subject<void>();


  constructor(  private controlContainer: ControlContainer) {


  }
  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;

    this.formGroup.valueChanges.pipe(
      takeUntil(this.onDestory)
    ).subscribe(
      {
        next: (val) => this.onChange(val)
      }
    );
  }
  ngOnDestroy(): void {
    this.onDestory.next();
    this.onDestory.complete();
  }

  writeValue(obj: any): void {
    this.populateFormData(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  populateFormData(prod: any) {
    this.formGroup.patchValue(prod);
  }

  get translations():UntypedFormArray{
    return this.formGroup.get('translations') as UntypedFormArray
  }
}
