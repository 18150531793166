export enum Permissions {
  ViewSubscriptionStatus = 'ViewSubscriptionStatus',
  ViewInvoices = "ViewInvoices",
  ChangeCompanyBundle = "ChangeCompanyBundle",
  GetBundleHistory = "GetBundleHistory",
  ModifyMenu = "ModifyMenu",
  ModifyOrder = "ModifyOrder",
  ViewOrders = "ViewOrders",
  ChangeEmployees = "ChangeEmployees",
  ModifyCompany = "ModifyCompany",
  ModifyQRCode = "ModifyQRCode",
  GenerateSignInToken = "GenerateSignInToken",
  ViewEvents = "ViewEvents",
  ModifyPrices = "ModifyPrices",
  ModifyCustomers = "ModifyCustomers",
  CreateSupplyOrder = "CreateSupplyOrder",
  ViewDocuments = "ViewDocuments"
}

export enum AdminPermissions {
  ModifyBundle = "ModifyBundle",
  SetAdmin = "SetAdmin",
  ChangeAppSettings = "ChangeAppSettings",
  ModifyAddon = "ModifyAddon",
  ModifyLocations = "ModifyLocations",
  SetInvoicePaid = "SetInvoicePaid",
  ImportData = "ImportData",
  CreateTestMenu = "CreateTestMenu",
  ChangeCompanyRenewDate = "ChangeCompanyRenewDate",
  CanExtendRenewDate = "CanExtendRenewDate",
  ImpersonateUser = "ImpersonateUser",
  ViewCompanies = "ViewCompanies",
  ModifyDefaultProducts = "ModifyDefaultProducts",
  ChangePermissions = "ChangePermissions",
  ViewUsers = "ViewUsers",
  ViewLog = "ViewLog"
}

export const AllPermissions = { ...Permissions, ...AdminPermissions };
export type AllPermissions = typeof AllPermissions;

export interface UserPermissions {
  companyPermissions: CompanyPermissions[];
  adminPermissions: Permissions[];
}

export interface CompanyPermissions {
  companyID: number;
  permissions: Permissions[];
}
