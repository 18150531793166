import { Component, Input, OnInit, Output } from '@angular/core';
import { TookanProxyService } from '../_service/tookan-proxy.service';
import { Agent } from '../_interface/agent';
import { TookanService } from '../_service/tookan.service';

@Component({
  selector: 'tookan-select-agent',
  templateUrl: './select-agent.component.html',
  styleUrls: ['./select-agent.component.scss']
})
export class SelectAgentComponent implements OnInit {

  @Input() selectedAgentID: number = 0;

  constructor(public tookanService: TookanService) {

  }

  ngOnInit(): void {
  }

}
