import { Pipe, type PipeTransform } from '@angular/core';
import { Order } from '../_interface/order';

@Pipe({
  name: 'isInvalidOrder'
})
export class IsInvalidOrderPipe implements PipeTransform {

  transform(value: Order): unknown {
    return (value.invalidOrderDetails?.items.length || 0) > 0 ||
      value.items.some(i => (i.invalidOrderDetails?.length || 0) > 0)
      ;
  }

}
