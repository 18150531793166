import Swal from 'sweetalert2';
import { SessionService } from './../_services/session.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Order } from 'src/app/core/_interface/order';
import { Component, Input, OnInit } from '@angular/core';
import { ChargeComponent } from '../charge/charge.component';
import { Payment } from '../_interface/payment';
import { TableService } from '../_services/table.service';
import { catchError, map } from 'rxjs/operators';
import { Client } from '../_interface/client';
import { ErrorCodes } from '../_interface/apiResponse';
import { POSNamePipe } from '../_pipes/POSName.pipe';

@Component({
  selector: 'app-order-details-dialog',
  templateUrl: './order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.scss']
})
export class OrderDetailsDialogComponent {
  @Input() order?: Order;
  @Input() groupInTable?: string;
  isBusy = false;

  constructor(private dialgRef: MatDialogRef<OrderDetailsDialogComponent>,
    private dialog: MatDialog,
    private tableService: TableService,
    public session: SessionService) { }

  statusChanged() {
    this.dialgRef.close();
  }

  charge() {
    var ref = this.dialog.open(ChargeComponent, {
      autoFocus: false
    });

    ref.componentInstance.totalAmount = this.order!.total;
    ref.componentInstance.orders = [this.order!.orderID];
    ref.componentInstance.client = this.order!.client;
    ref.componentInstance.paymentsSelected.subscribe(
      (p) => {
        this.sendPayments(p);
      }
    )
  }


  sendPayments(payments: { payments: Payment[], client?: Client }) {
    if (this.isBusy || !this.order) {
      return;
    }

    this.isBusy = true;
    return this.tableService.chargeSingleOrder(this.order?.orderID, payments.payments, this.order?.total, payments.client).subscribe(
      {
        error: error => {
          if (error.errorCode == ErrorCodes.NoResponseFromPOS) {
            var pp = new POSNamePipe();
            var posName = pp.transform(this.session.selectedCompany.value?.posName);
            var message = posName + " nije povezan. Proverite komunikaciju programa sa eMeni sistemom";
            Swal.fire({
              title: "Greška",
              text: message,
              icon: "error"
            });
          }
          else {
            Swal.fire({
              title: "Greška",
              text: error.errorDescription,
              icon: "error"
            });
          }
          this.isBusy = false;

          throw 'error';
        }
        , next: val => {
          this.isBusy = false;
          this.dialgRef.close();
        }

      }
    );
  }

}
