import { Injectable } from '@angular/core';
import { IStorage } from '../_interface/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements IStorage {

  constructor() { }

  get(key: string): Promise<any> {
    return Promise.resolve(localStorage.getItem(key));
  }
  set(key: string, value: any): Promise<any> {
    return Promise.resolve(localStorage.setItem(key, value));
  }
  remove(key: string): Promise<any> {
    return Promise.resolve(localStorage.removeItem(key));
  }
}
