import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitString'
})
export class SplitStringPipe implements PipeTransform {

  transform(value: string, separator: string = '') {
    return value.split(separator);
  }

}
