import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {


  constructor(private http: HttpClient) { }

  uploadLog(formData: any) {
    const url = environment.apiBase + `log`;
    this.http.post<void>(url, formData).subscribe();
  }
}
