import { Injectable } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.log("core missing translation:" + params.key);

  }

}
