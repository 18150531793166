import { ActivatedRoute, Router } from '@angular/router';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../core/_services/authorization.service';
import { SessionService } from '../core/_services/session.service';
import { CompanyService } from '../core/_services/company.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  destroyed = new Subject<void>();
  companyID: number | undefined;

  constructor(public auth: AuthorizationService,
    session: SessionService,
    companyService: CompanyService,
    router: Router,
    route: ActivatedRoute) {

    auth.tokenLoaded.pipe(
      takeUntil(this.destroyed),
      filter(t => t),
      switchMap(() => {
        return route.params;
      }
      ))
      .subscribe(
        p => {
          if (p['companyid']) {
            this.companyID = +p['companyid'];
            if (!this.companyID) {
              return;
            }

            /*
                      if (!this.companyID) {
                        if (session.selectedCompany.value) {
                          return;
                        }
                        else {
                          router.navigate(['/companies']);
                          return;
                        }
                      }
            */
            if (session.companyID != this.companyID) {
              companyService.getCompany(this.companyID).subscribe({
                next: company => {
                  if (!company) {
                    router.navigate(['/app']);
                  }
                  else {
                    session.selectedCompany.next(company);
                  }
                },
                error: err => {
                  router.navigate(['/app']);
                }
              });
            }


          }
        }
      )
    auth.currentUser.pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      u => {
        if (u) {
          router.navigate(['/app'])
        }
      }
    )
  }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete()
  }

  ngOnInit(): void {
  }

}
