import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../_interface/mrd';
import { MrDService } from '../_services/mr-d.service';

@Component({
  selector: 'app-mr-dorder-details',
  templateUrl: './mr-dorder-details.component.html',
  styleUrls: ['./mr-dorder-details.component.scss']
})
export class MrDOrderDetailsComponent implements OnInit {
  order?: Order;

  @Input() orderID?: number;

  constructor(
    private mrdService: MrDService
  ) { }

  ngOnInit(): void {
    this.mrdService.getOrder(this.orderID!).subscribe(
      order => {
        this.order = order;
      }
    )
  }


}
