export enum InvoiceItemType {
  Bundle = 0,
  Addon = 1
}

export enum InvoiceType {
  Invoice = 0,
  Order = 1
}

export class Invoice {
  invoiceID: number = 0;
  invoiceNo: string = '';
  type: InvoiceType = InvoiceType.Order;
  companyID: number = 0;
  createdBy: number = 0;
  createdAt: string = '';
  amount: number = 0;
  bankAccountNumber: string = '';
  referenceNo: string = '';
  dueDate: string = '';
  dataFrom: string | null = null;
  dateTo: string | null = null;
  name: string = '';
  items: InvoiceItem[] = [];
  billingName: string = '';
  billingAddress: string = '';
  billingZipCode: string = '';
  billingCity: string = '';
  taxNo: string = '';
  eMail: string = '';
  paidAt: Date | null = null;

  get isPaid(): boolean {
    return this.paidAt != null;
  }
  get total(): number {
    return this.items.reduce((p, c) => p + c.total, 0);
  }
}
export class InvoiceItem {
  // ID stavke iz baze
  invoiceItemID?: number;

  // ID itema iz baze
  itemID: number=0;
  price: number=0;
  name: string='';
  type: InvoiceItemType = InvoiceItemType.Bundle;
  tax: number = 20;
  qty: number = 1;

  // Ukupna cena bez poreza
  get totalPrice(): number {
    return this.price * this.qty;
  }

  // ukupan porez
  get taxAmount(): number {
    return this.totalPrice * this.tax / 100;
  }

  // Ukupna cena sa porezom
  get total(): number {
    return this.totalPrice + this.taxAmount;

  }
}
