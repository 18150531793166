import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '../_interface/payment';

@Pipe({
  name: 'paymentMethodName'
})
export class PaymentMethodNamePipe implements PipeTransform {

  transform(value: PaymentType) {

    var translationKey = 'paymentTypes.' + Object.keys(PaymentType)[Object.values(PaymentType).indexOf(value)].toLowerCase();

    return translationKey;

    switch (value) {
      case (PaymentType.Cash):
        return "Gotovina";
      case (PaymentType.CreditCard):
        return "Kartica";
      case (PaymentType.Instant):
        return "Instant plaćanje";
      case (PaymentType.Check):
        return "Ček";
      case (PaymentType.WireTransfer):
        return "Prenos na račun";
      case (PaymentType.Voucher):
        return "Vaučer";
      case (PaymentType.Other):
        return "Ostalo";
      case (PaymentType.None):
        return "Storno porudžbine";
      case (PaymentType.Presentation):
        return "Reprezentacija";
    }

  }
}
