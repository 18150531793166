import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  phoneNumberUtil = PhoneNumberUtil.getInstance();
  transform(phoneValue: number | string): any {
    try {
      const phoneNumber = this.phoneNumberUtil.parse(phoneValue + "");
      return this.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    } catch (error) {
      return phoneValue;
    }
  }



}
