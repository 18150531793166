import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  _printIframe?: HTMLIFrameElement;

  constructor() { }

  print(data:any){
    const fileURL = URL.createObjectURL(data);
    var link = window.document.createElement('a');
    link.href = fileURL;
    //   link.download = this.order?.orderID + ".pdf";
    //  link.click();


    var iframe = this._printIframe;
    if (!this._printIframe) {
      iframe = this._printIframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.onload = function () {
        setTimeout(function () {
          iframe?.focus();
          iframe?.contentWindow?.print();
        }, 1);
      };
    }

    iframe!.src = fileURL
  }
}
