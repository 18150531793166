import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GenericListApiResponse, GenericItemApiResponse, ApiResponse } from 'src/app/core/_interface/apiResponse';
import { IModifier } from 'src/app/core/_interface/modifier';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModifierService {
  modifierSaved = new Subject<IModifier>();
  modifierDeleted = new Subject<number>();

  constructor(private http: HttpClient) {

  }


  get(companyID: number): Observable<IModifier[] | undefined> {
    const url = environment.apiBase + `modifier/get?companyID=${companyID}`;
    return this.http.get<GenericListApiResponse<IModifier>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          return undefined;
        })
      );
  }

  save(companyID: number, modifier: IModifier): Observable<IModifier | undefined> {
    const url = environment.apiBase + `modifier/save?companyID=${companyID}`;
    return this.http.post<GenericItemApiResponse<IModifier>>(url, modifier)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          return undefined;
        }),
        tap(s => {
          if (s) {
            this.modifierSaved.next(s);
          }
        })
      );
  }


  delete(modifierID: number): Observable<boolean> {
    const url = environment.apiBase + `modifier/delete?modifierID=${modifierID}`;
    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => response.success),
        tap(s => {
          if (s) {
            this.modifierDeleted.next(modifierID);
          }
        })
      );
  }

  translate(companyID: number, from: any, to: any) {
    const url = environment.apiBase + `modifier/translate?companyID=${companyID}&from=${from}&to=${to}`;
    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => {
          if (response.success) {
            return true;
          }
          throw response;
        })
      );
  }
}
