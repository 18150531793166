
import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from "@angular/core";
import { map } from 'rxjs/operators';
import { SessionService } from '../_services/session.service';
import { Table } from '../_interface/table';

@Pipe({ name: "getTableByID" })
export class GetTableByIDPipe implements PipeTransform {
  constructor(private session: SessionService) {

  }

  transform(id: number | undefined) {
    if (id) {
      return this.session.tables.pipe(
        map((val) => {
          if (val) {
            return val.find(g => g.tableID === id) || null;
          }
          return null;
        })
      );
    }
    return null;
  }
}
