import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { interval } from 'rxjs/internal/observable/interval';
import { Order } from '../_interface/order';

@Component({
  selector: 'app-order-progress-bar',
  templateUrl: './order-progress-bar.component.html',
  styleUrls: ['./order-progress-bar.component.scss']
})
export class OrderProgressBarComponent implements OnInit, OnDestroy {
  _oldDueDate?: string;
  remaining?: Date;
  destroyed = new Subject<void>();
  progressValue = 0;
  timeUp = false;
  noDueDate = false;

  progressUpdater?: Subscription;

  private _order?: Order | undefined;
  public get order(): Order | undefined {
    return this._order;
  }

  @Input()
  public set order(value: Order | undefined) {
    this._order = value;
    this.updateTimer();
  }

  @Input() showTimer = false;

  constructor() { }


  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.order?.dueDate != this._oldDueDate) {
      this._oldDueDate = this.order?.dueDate;
      this.updateTimer();
    }
  }

  updateTimer() {
    if (!this.order) {
      return;
    }

    var totalTime = 0;
    var startDate = new Date(this.order.modified!);

    if (!this.order.dueDate) {
      this.noDueDate = true;
    }
    else {
      var dueDate = new Date(this.order.dueDate);

      totalTime = dueDate.getTime() - startDate.getTime();
    }



    if (this.progressUpdater) {
      this.progressUpdater.unsubscribe();
    }

    this.progressUpdater = interval(100).pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      sec => {
        var elapsed = (new Date()).getTime() - startDate.getTime();
        this.progressValue = elapsed * 100 / totalTime;
        if (this.progressValue > 100) {
          this.progressValue = 100;
        }

        if (totalTime >= elapsed) {
          this.remaining = new Date(totalTime - elapsed);
        }
        else {
          this.remaining = new Date(elapsed - totalTime);

          if (totalTime != 0) {
            this.timeUp = true;
          } else {
            this.progressValue = 0;
          }
        }
        //console.log(this.remaining);


      }
    )
    this.progressValue = 0;
  }
}
