import { SHELL_APP_CONFIG } from '../_interface/shellAppConfig';
import { SwUpdate } from '@angular/service-worker';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {
  version = environment.appVersion;
shellAppConfig = SHELL_APP_CONFIG;

  constructor(private swUpdate: SwUpdate) { }

  update() {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.checkForUpdate().then(
      hasUpdate => {
        if (hasUpdate) {
          this.swUpdate.activateUpdate().then(() => location.reload());
        }
      }
    )
  }
}
