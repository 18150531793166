import { Order, OrderStatus } from 'src/app/core/_interface/order';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOrdersByStatus'
})
export class FilterOrdersByStatusPipe implements PipeTransform {

  transform(orders: Order[] | null, status: OrderStatus[], negate=false) {
    if (!orders){
      return [];
    }

    if (negate) {
      return orders.filter(o => !status.some(s => s == o.status));
    }

    return orders.filter(o => status.some(s => s == o.status));
  }

}
