import { Pipe, PipeTransform } from "@angular/core";
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product, ProductType } from "../_interface/product";
import { SessionService } from "../_services/session.service";

@Pipe({ name: "productsInGroup" })
export class ProductsInGroupPipe implements PipeTransform {

  constructor(private session: SessionService) {


  }
  transform(groupID: number | undefined) {

    return this.session.groups.pipe(
      map((groups) => {
        const group = this.session.groups.value.find(g => g.groupID == groupID);
        if (!group) {
          return [];
        }
        return group.products;
      })
    );
  }
}
