import { AfterViewInit } from '@angular/core';
import { Translation } from './tranlsation';

export enum Allergens {
  Celery = 1,
  Egg = 2,
  Peanuts = 3,
  Lupine = 4,
  Sesame = 5,
  Mustard = 6,
  Crustacean = 7,
  Sulfur = 8,
  Soya = 9,
  Milk = 10,
  Fish = 11,
  Mollusca = 12,
  Nut = 13,
  Wheat = 14
}

export enum ProductType {
  Product = 0,
  ProductGroup = 1,
  Modifier = 2,
}

export interface ProductPrice {
  productID: number;
  price: number;
}
export interface Suggestion {
  suggestionID: number;
  name: string;
  productIDs: number[];
  available: boolean;
  companyID: number;
}

export interface IName {
  name: string;
}
export interface ISortable {
  waiterSortOrder?: number;
  userSortOrder?: number;
  sortOrder?: number;
}

export interface ProductBase extends IName {
  description: string;
  image: string;
  translations: Translation[]

}

export interface DefaultProduct extends ProductBase {
  productID: number;
  tags: string[];
  allergens: Allergens[];
}

export interface Product extends ProductBase, ISortable {
  productID: number;
  price: number;
  isFeatured: boolean;
  allergens: Allergens[];
  referenceID: string;
  type: ProductType;
  modifierIDs: number[];
  deleted: boolean;
  parentProductID?: number;
  suggestionIDs: number[];
  groupIDs: number[];
  available: boolean;
  isPublic: boolean;
  validFrom?: string;
  validUntil?: string;
  autoShowModifier: boolean;
  preparationTime: number | null;
  dontGroupProductInOrder: boolean;
  kitchenDisplayID: number;
  customerDisplayID: number;
  orderable: boolean;
  hasSuppliers: boolean;
  minimumOrderQuantity: number;
  defaultSupplierID: number;
  minimalQuantity: number;
  eanCode: string;
}

export enum GroupCategory {
  Default = 0,
  Drink,
  Food,
  Berbecue,
  Sandwich,
  Alcohol,
  NonAlcohol,
  Cigarette,
  KidsMenu,
  Desert,
  Brekfast,
  Coctail,
  Startup,
  Sallate,
  Pasta,
  Beer,
  Fish,
  Cheese,
  Soup,
  HotDrinks,
  Vine
}


export interface GroupProduct {
  productID: number;
  userSortOrder: number;
  waiterSortOrder: number;
}
export interface Group extends ISortable {
  name: string;
  category: GroupCategory;
  groupID: number;
  modifierIDs: number[];
  translations: Translation[]
  suggestionIDs: number[];
  products: GroupProduct[];
  validFrom?: string;
  validUntil?: string;
  available: boolean;
  image: string;
}

export const createProduct = () => {
  return {
    name: '',
    waiterSortOrder: 0,
    userSortOrder: 0,
    translations: [],
    allergens: [],
    available: true,
    isPublic: true,
    description: '',
    image: '',
    productID: 0,
    deleted: false,
    price: 0,
    isFeatured: false,
    referenceID: '',
    type: ProductType.Product,
    modifierIDs: [],
    suggestionIDs: [],
    groupIDs: [],
    autoShowModifier: false,
    preparationTime: null,
    dontGroupProductInOrder: false,
    kitchenDisplayID: 0,
    customerDisplayID: 0,
    tags: [],
    orderable: false,
    hasSuppliers: false,
    minimumOrderQuantity: 0,
    defaultSupplierID: 0,
    minimalQuantity: 0,
    eanCode: ''
  } as Product;
}


export const createGroup = () => {
  return {
    name: '',
    category: GroupCategory.Default,
    groupID: 0,
    waiterSortOrder: 0,
    userSortOrder: 0,
    translations: [],
    modifierIDs: [],
    suggestionIDs: [],
    products: [],
    productIDs: [],
    available: true,
    image: ''
  } as Group;
}


export interface ProductSupplier {
  productID: number;
  supplierCompanyID: number | null;
  supplierClientID: number | null;
  supplierProductName: string;
  packageSize: number;
  minimalQuantity: number;
  isDefault: boolean;
  outOfStock: boolean;
}
