import { DeliveryType } from './../_interface/order';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-delivery-type-icon',
  templateUrl: './order-delivery-type-icon.component.html',
  styleUrls: ['./order-delivery-type-icon.component.scss']
})
export class OrderDeliveryTypeIconComponent {
  @Input() deliveryType?: DeliveryType;
  DeliveryType = DeliveryType
  constructor() { }

}
