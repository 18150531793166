import { Router } from '@angular/router';
import { interval, Observable, observable, Subject, Subscription, takeUntil } from 'rxjs';
import { CodeSigninService } from './../_services/code-signin.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { SignInCode } from '../_interface/signiCode';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-code-signin',
  templateUrl: './code-signin.component.html',
  styleUrls: ['./code-signin.component.scss']
  , encapsulation: ViewEncapsulation.None
})
export class CodeSigninComponent implements OnInit, OnDestroy {
  destroy = new Subject<void>();

  progressbarTimer?: Subscription;
  progressbarValue = 0;
  currentSec = 0;

  @Input()
  successLoginRedirect = '';

  @Output() loginCompleted = new EventEmitter();

  private _code?: SignInCode | undefined;
  public get code(): SignInCode | undefined {
    return this._code;
  }
  public set code(value: SignInCode | undefined) {
    this._code = value;

    if (value) {
      this.startProgressbarTimer(value.expires);
      //
    }
  }

  constructor(private codeSigninService: CodeSigninService,
    private logger: NGXLogger,
    private router: Router) {
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();

    //this.stopLoginTimer();
    this.progressbarTimer?.unsubscribe();

  }

  ngOnInit(): void {
    this.generateCode();
    this.startLoginTimer();
  }

  generateCode() {
    this.codeSigninService.generate().subscribe({
      next: code => {
        this.code = code
      },
      error: () => {
        this.code = undefined;
        setTimeout(() => {
          this.generateCode();
        }, 5000);
      }
    }
    );
  }


  startLoginTimer() {
    interval(5000).pipe(
      takeUntil(this.destroy)
    ).subscribe(() => {
      if (!this.code) {
        return;
      }

      this.codeSigninService.tryLogin(this.code!.code, this.code!.token).subscribe(
        {
          next: jwtToken => {
            if (jwtToken) {
              this.loginCompleted.emit(jwtToken);
            }
          }

          , error: (e) => {
            this.logger.warn("Error sigingin in with code", e)
          }
        })
    });
  }


  startProgressbarTimer(seconds: number) {
    const time = seconds;
    const timer$ = interval(100);

    this.progressbarTimer?.unsubscribe();
    this.progressbarValue = 100;

    this.progressbarTimer =
      timer$.subscribe((sec) => {
        var val = sec / 10;
        this.progressbarValue = 100 - (val + 1) * 100 / seconds;
        this.currentSec = val;

        if (this.currentSec === seconds) {
          this.progressbarTimer?.unsubscribe();
          this.generateCode();

        }
      });
  }

}
