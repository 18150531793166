import { Component, Input, OnInit } from '@angular/core';
import { DeliveryType, Order, OrderStatus } from '../_interface/order';

@Component({
  selector: 'app-order-icons',
  templateUrl: './order-icons.component.html',
  styleUrls: ['./order-icons.component.scss']
})
export class OrderIconsComponent implements OnInit {
  DeliveryType = DeliveryType;
  OrderStatus = OrderStatus;

  @Input() order?: Order;

  constructor() { }

  ngOnInit(): void {
  }

}
