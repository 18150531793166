<ng-container *ngIf="(ws.connected | async) === false">
  <div
    class="noConnection"
    *ngIf="
      showFullScreen &&
        fullScreenWarningThreshold <= ((ws.connectRetryCount | async) || 0);
      else icon
    "
    [innerHtml]="'notConnectedCheckConnection' | translate"
  ></div>
</ng-container>

<ng-template #icon>
  <mat-icon>sync</mat-icon>
</ng-template>
