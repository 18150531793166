
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Payment, PaymentType } from '../_interface/payment';
import { SearchClientsComponent } from '../search-clients/search-clients.component';
import { Client } from '../_interface/client';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ClientService } from '../_services/client.service';
import { CrossAppMessagingService } from '../_services/crossApp-messaging.service';
import { AuthorizationService } from '../_services/authorization.service';
import Swal from 'sweetalert2';
import { NGXLogger } from 'ngx-logger';
import { SHELL_APP_CONFIG } from '../_interface/shellAppConfig';
import { Customer } from '../_interface/order';
import { SearchCustomersComponent } from '../search-customers/search-customers.component';
import { TableService } from '../_services/table.service';
import { catchError, debounce, debounceTime, finalize, Subject, tap } from 'rxjs';
import { PrecheckRequest } from '../_interface/table';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss']
})
export class ChargeComponent implements OnInit {
  SHELL_APP_CONFIG = SHELL_APP_CONFIG;
  PaymentMethod = PaymentType;
  remainingAmountInput: number = 0;
  originalAmount: number = 0;

  remainingAmount = 0;
  isBusy = false;
  precheckActive = false;
  precheckActive$ = new Subject<boolean>();

  selectedPaments: Payment[] = [];

  @Output() paymentsSelected = new EventEmitter<{ client?: Client, payments: Payment[] }>();

  @ViewChild("amountField") amountField?: ElementRef;
  @ViewChild("printCopy") printCopy?: SwalComponent;

  @Input() companyID: number = 0;
  @Input() totalAmount: number = 0;
  @Input() client?: Client;
  @Input() customer?: Customer;
  @Input() orders?: number[];
  @Input() tableID?: number;

  constructor(
    private dialogRef: MatDialogRef<ChargeComponent>,
    private dialog: MatDialog,
    private auth: AuthorizationService,
    private clientService: ClientService,
    private logger: NGXLogger,
    private crossAppMessaging: CrossAppMessagingService,
    private tableService: TableService
  ) {

    this.precheckActive$.pipe(
      tap((v) => {
        if (!v) {
          this.precheckActive = false;
        }
      }),
      debounceTime(500)
    )
      .subscribe(
        (v) => {
          this.precheckActive = v;
        }
      )
  }

  ngOnInit(): void {
    this.originalAmount = this.totalAmount;
    this.updateAmount();

    this.precheck();
  }

  updateAmount() {
    var selectedAmount = this.selectedPaments.reduce((p, c) => p + c.amount, 0);

    this.remainingAmountInput = this.totalAmount - selectedAmount;
    this.remainingAmountInput = Math.round((this.remainingAmountInput + Number.EPSILON) * 100) / 100
    this.remainingAmount = this.remainingAmountInput;



    if (this.remainingAmountInput <= 0) {
      this.sendCharge();
    }

  }

  precheck() {
    this.precheckActive$.next(true);
    var data: PrecheckRequest = {
      companyID: this.companyID,
      amount: this.originalAmount,
      tableID: this.tableID,
      orderIDs: this.orders,
      customerID: this.customer?.customerID
    };
    this.tableService.precheck(data)
      .pipe(
        finalize(() => {
          this.precheckActive$.next(false);
        }),
        catchError((e) => {
          Swal.fire({
            title: "Greška",
            text: e.errorDescription,
            icon: "error"
          });

          this.dialogRef.close();
          throw e;
        })
      )
      .subscribe(
        (result) => {
          this.totalAmount = result.amount;
          this.updateAmount();
        }
      )
  }

  sendCharge() {
    if (this.client && this.client.printInvoiceCopy == null) {
      this.printCopy?.fire().then((result) => {
        this.logger.debug(result);
        if (result.isConfirmed) {
          this.client!.printInvoiceCopy = true;
        } else if (result.isDenied) {
          this.client!.printInvoiceCopy = false;
        } else {
          return;
        }

        if (result.value) {
          this.clientService.save(this.client!).subscribe();
        }
        this.sendCharge();
      });
      return;
    }

    this.paymentsSelected.next({
      payments: this.selectedPaments,
      client: this.client
    });

    this.dialogRef.close();
  }

  addPayment(type: PaymentType) {
    if (this.remainingAmountInput > 0 && !this.isBusy) {
      var p: Payment = {
        amount: this.remainingAmountInput,
        type: type
      };

      if (type == PaymentType.CreditCard && SHELL_APP_CONFIG.hasPayment) {
        this.makePayment(p);
      } else {
        this.selectedPaments.push(p);
      }
      this.updateAmount();
    }

  }

  makePayment(p: Payment) {
    this.logger.debug("Making crossapp payment: ", p);

    this.isBusy = true;
    this.crossAppMessaging?.sendRawMessage({
      action: 'makePayment',
      data: {
        amount: p.amount,
        operatorCode: this.auth.currentUserID + "",
        transactionReference: this.orders?.join(",") || "",
      }
    }, true, 120000, true)
      .then((msg) => {

        this.logger.debug("Payment result: ", msg);

        if (msg == "null" || msg == null) {
          Swal.fire("Greška", "Greška prilikom naplate putem platne kartice", "error");
        }
        else if (msg.error) {
          Swal.fire("Greška", msg.error, "error");
        }
        else {
          p.transaction = msg;
          this.selectedPaments.push(p);

          this.updateAmount();
        }
      })
      .catch((msg) => {
        if (msg == "null" || msg == null) {
          Swal.fire("Greška", "Greška prilikom naplate putem platne kartice", "error");
        }
        else if (msg.error) {
          Swal.fire("Greška", msg.error, "error");
        }

      }).finally(() => {
        this.isBusy = false;
      });
  }


  voidOrders(type: PaymentType) {
    var p: Payment = {
      amount: this.totalAmount,
      type: type
    };

    this.selectedPaments = [p];

    this.updateAmount();

  }

  selectClient() {
    var ref = this.dialog.open(SearchClientsComponent, { autoFocus: false });
    ref.afterClosed().subscribe((client) => {
      this.client = client;
    });
  }

  selectCustomer() {
    var ref = this.dialog.open(SearchCustomersComponent, { autoFocus: false });
    ref.afterClosed().subscribe((customer) => {
      this.customer = customer;
      this.precheck();
    });
  }

  inactivityElapsed() {
    this.logger.debug("Inactivity elapsed. Closing charge dialog.");
    this.dialogRef.close();
  }
}
