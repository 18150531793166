import { map } from 'rxjs';
import { AppSettingsService } from './../_services/AppSettings.service';
import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appSortLanguages'
})
export class SortLanguagesPipe implements PipeTransform {

  constructor(private AppSettingsService: AppSettingsService) { }
  transform(value: {
    code: string;
    name: string;
  }[]) {
    return this.AppSettingsService.current.pipe(
      map(settings => {
        return value.sort((a, b) => {
          return a.code == settings.defaultLanguage ? -1 :
            b.code == settings.defaultLanguage ? 1 : a.name.localeCompare(b.name)
        });
      }
      )
    )
  }

}
