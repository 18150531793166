import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/core/_services/user.service';

@Pipe({
  name: 'getUserFromID'
})
export class GetUserFromIDPipe implements PipeTransform {

  constructor(private userService: UserService){}

  transform(userID: number) {
    return this.userService.getUser(userID);
  }

}
