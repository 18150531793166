import { Component, OnInit, Output, EventEmitter, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent {
  @Output()
  save = new EventEmitter();

  @Input()
  location: 'top' | 'bottom' = 'top';

  @Input() description: TemplateRef<any> | null = null;

  constructor() { }


}
