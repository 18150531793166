<div class="container" *ngIf="formGroup" [formGroup]="formGroup">
  <div class="details">
    <app-property-item name="{{ 'tin' | translate }}">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'tin' | translate }}"
          formControlName="tin"
          required
          (focusout)="searchPIB()"
        />
      </mat-form-field>
    </app-property-item>
    <app-property-item name="{{ 'name' | translate }}">
      <mat-form-field>
        <mat-label>{{ "name" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'name' | translate }}"
          formControlName="name"
          required
        />
      </mat-form-field>
    </app-property-item>
    <app-property-item name="{{ 'address' | translate }}">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'address' | translate }}"
          formControlName="address"
          required
        />
      </mat-form-field>
    </app-property-item>
    <app-property-item name="{{ 'city' | translate }}">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'city' | translate }}"
          formControlName="city"
          required
        />
      </mat-form-field>
    </app-property-item>
    <app-property-item name="{{ 'identificationNumber' | translate }}">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'identificationNumber' | translate }}"
          formControlName="identificationNo"
        />
      </mat-form-field>
    </app-property-item>
    <app-property-item name="{{ 'eMail' | translate }}">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'eMail' | translate }}"
          formControlName="eMail"
        />
      </mat-form-field>
    </app-property-item>
    <app-property-item name="{{ 'reference' | translate }}">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'reference' | translate }}"
          formControlName="referenceID"
        />
      </mat-form-field>
    </app-property-item>
    <app-property-item name="{{ 'supplier' | translate }}">
      <mat-slide-toggle #isSupplier formControlName="isSupplier">{{
        "supplier" | translate
      }}</mat-slide-toggle>
    </app-property-item>
    <app-property-item name="eMeni ID" *ngIf="isSupplier.checked">
      <mat-form-field>
        <input
          matInput
          placeholder="eMeni ID"
          formControlName="clientCompanyID"
        />
        <mat-hint>{{ "supplierIDDescription" | translate }} </mat-hint>
      </mat-form-field>
    </app-property-item>
  </div>
  <div class="paymentOptions">
    <mat-checkbox
      [indeterminate]="formGroup.get('printInvoiceCopy')?.value === null"
      formControlName="printInvoiceCopy"
      >{{ "printInvoiceCopy" | translate }}</mat-checkbox
    >
    <app-group-box [titleTemplate]="title">
      <ng-template #title>
        <mat-checkbox #specialOptions>
          {{ "paymentMethods" | translate }}
        </mat-checkbox>
      </ng-template>
      <app-enum-checkbox-selector
        [enum]="PaymentType"
        formControlName="allowedPaymentTypes"
        [itemTemplate]="paymentTypeTemplate"
      ></app-enum-checkbox-selector>
      <ng-template #paymentTypeTemplate let-item="item">
        {{ item | paymentMethodName | translate }}
      </ng-template>
    </app-group-box>
  </div>
</div>
