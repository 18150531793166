import { InjectionToken } from "@angular/core";
import { Subscription } from "rxjs";
import { OrderStatus } from "./order";

export interface INotificationToken {
  provider: string;
  token: string;
}

export const NOTIFICATION_PROVIDER = new InjectionToken<INotificationProvider>('notification provuder');
export interface INotificationProvider {
  getToken(): Promise<INotificationToken | null>;
  removeNotification(tag: string): void;
  cancelWaiterCall(fn: (tableID: number) => void): void;
  setOrderStatus(fn: (orderID: number, orderStatus: OrderStatus) => void): void;
}
