import { TranslatePipe } from '@ngx-translate/core';
import { OrderItemStatus, OrderStatus, OrderStatusLog } from '../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'orderItemStatus' })
export class OrderItemStatusPipe implements PipeTransform {

  transform(status: OrderItemStatus, currentStatus?: OrderItemStatus): string {
    var translationKey = 'orderItemStatus.';
    translationKey += OrderItemStatus[status]?.toLowerCase();
    if (currentStatus && status !== currentStatus) {
      translationKey += '.change';
    } else {
      translationKey += '.status';
    }

    return translationKey;
  }
}
