import { Client } from './../_interface/client';
import { ClientService } from './../_services/client.service';
import { AfterViewInit, Component, Inject, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyService } from '../_services/company.service';
import { ICompany } from '../_interface/company';
import { SessionService } from '../_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentType } from '../_interface/payment';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit, AfterViewInit {
  formGroup!: FormGroup;
  PaymentType = PaymentType;

  private _client: Client;
  public get client(): Client {
    return this._client;
  }
  @Input()
  public set client(value: Client) {
    if (value) {
      this._client = value;
      this.updateForm(value);
    }
  }

  constructor(private fb: FormBuilder,
    private session: SessionService,
    private toast: ToastrService,
    private clientService: ClientService,
    private companyService: CompanyService) {

    this._client = {
      clientID: 0,
      name: "",
      address: "",
      note: "",
      city: '',
      companyID: 0,
      eMail: '',
      identificationNo: '',
      referenceID: '',
      tin: '',
      printInvoiceCopy: null,
      allowedPaymentTypes: [],
      isSupplier: false
    };
  }

  ngAfterViewInit(): void {

    this.formGroup = this.fb.group({
      clientID: this.client.clientID,
      name: this.client.name,
      address: this.client.address,
      note: this.client.note,
      city: this.client.city,
      companyID: this.client.companyID,
      eMail: this.client.eMail,
      identificationNo: this.client.identificationNo,
      referenceID: this.client.referenceID,
      printInvoiceCopy: this.client.printInvoiceCopy,
      tin: this.client.tin,
      allowedPaymentTypes: this.client.allowedPaymentTypes,
      isSupplier: this.client.isSupplier,
      clientCompanyID: this.client.clientCompanyID
    });
  }


  updateForm(client: Client): void {

    this.formGroup?.patchValue(client);
  }



  ngOnInit(): void {
  }

  save() {
    if (this.formGroup.invalid || this.formGroup.disabled) {
      this.formGroup.markAllAsTouched();
      return;
    }

    var client = this.formGroup.value as Client;
    if (!client.companyID) {
      client.companyID = this.session.companyID;
    }


    return new Promise((resolve, reject) => {
      this.clientService.save(client).subscribe({
        next: r => {
          Object.assign(this.client!, r);
          this.toast.success("Podaci snimljeni");
          resolve(r);
        },
        error: err => {
          this.toast.error(err);
          reject(err);
        }
      });
    })

  }


  searchPIB() {
    if (this.formGroup.get("name")?.value) {
      return;
    }

    this.formGroup.disable();
    var tin = this.formGroup.value.tin;
    this.companyService.searchCompanyByTaxNo(tin).subscribe({

      next: r => {
        this.formGroup.enable();

        if (isNaN(r as any)) {
          var company = r as ICompany;
          if (!this.formGroup.value.identificationNo) {
            this.formGroup.get('identificationNo')?.setValue(company.identificationNo);
          }

          if (!this.formGroup.value.name) {
            this.formGroup.get('name')?.setValue(company.name);
          }

          if (!this.formGroup.value.address) {
            this.formGroup.get('address')?.setValue(company.address);
          }

          if (!this.formGroup.value.city) {
            this.formGroup.get('city')?.setValue(company.city);
          }
        }
      }
      , error: () => {
        this.formGroup.enable();

      }
    }
    );
  }
}
