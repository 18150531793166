import { Injectable } from '@angular/core';


import { logDB } from '../data/logDB';
import * as JSZip from 'jszip';
import { FileUploadService } from '../_services/FileUpload.service';
import { WSService } from './ws.service';
import { CrossAppMessagingService } from './crossApp-messaging.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SendLogService {

  constructor(
    ws: WSService,
    private fileUploadService: FileUploadService,
    crossAppMessaging: CrossAppMessagingService,

  ) {
    ws.observe("sendNativeLog").subscribe((msg) => {
      if ((environment as any)["nativeApp"] === true) {
        crossAppMessaging?.sendRawMessage({ action: 'sendNativeLog' });
      }
      else {
        this.sendLogFromDB();
      }
    });
  }


  sendLogFromDB() {
    logDB.log.toArray().then(
      async logs => {
        // Kreiraj sadržaj fajla
        const content = logs.map(log => `${log.timestamp} - ${log.level} - ${log.message} - ${JSON.stringify(log.additional)}`).join('\n');
        const blob = new Blob([content], { type: 'text/plain' });
        const file = new File([blob], 'log.txt', { type: 'text/plain' });

        // Kreiraj ZIP arhivu
        const zip = new JSZip();

        zip.file(file.name, file, {
          compression: 'DEFLATE'
        });

        // Generiši ZIP fajl kao Blob
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const zipFile = new File([zipBlob], 'log.zip', { type: 'application/zip' });

        // Pošalji fajl koristeći servis
        this.fileUploadService.uploadLog(zipFile);
      }
    )
  }
}
