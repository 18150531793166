import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-reject-reason',
  templateUrl: './select-reject-reason.component.html',
  styleUrls: ['./select-reject-reason.component.scss']
})
export class SelectRejectReasonComponent implements OnInit {
  rejectReasons = ['Artikal nedostupan', 'Uskoro zatvaramo'];

  constructor() { }


  ngOnInit(): void {
  }
}
