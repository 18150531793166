<div class="invoiceDetails">
  <div>PIB:{{ invoice.taxNo }}</div>
  <div>Naziv firme :{{ invoice.billingName }}</div>
  <div>Email :{{ invoice.eMail }}</div>
  <div>Rok za uplatu:{{ invoice.dueDate | date }}</div>
  <div>Broj računa:{{ invoice.bankAccountNumber }}</div>
  <div>Poziv na broj (mod 97):{{ invoice.referenceNo }}</div>
  <div>Iznos:{{ invoice.total | number: "1.2" }}</div>
  <h3>Stavke</h3>
  <table class="defaultTable table-hover">
    <thead>
      <tr>
        <th>Naziv</th>
        <th>Osnovica</th>
        <th>Količina</th>
        <th>PDV (%)</th>
        <th>Ukupno</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of invoice.items">
        <td>{{ item.name }}</td>
        <td class="text-right">{{ item.price | number: "1.2-2" }}</td>
        <td class="text-right">{{ item.qty }}</td>
        <td class="text-center">{{ item.tax }}</td>
        <td class="text-right">{{ item.total | number: "1.2-2" }}</td>
      </tr>
      <tr>
        <td colspan="4" class="text-right">Ukupno:</td>
        <td class="text-right">
          {{ invoice.total | number: "1.2-2" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="invoice.paidAt" class="d-flex justify-content-between">
  <button class="btn btn-primary" (click)="download()">Preuzmi</button>
  <button
    class="btn btn-outline-primary"
    *ngIf="(auth.currentUser | async)?.isAdmin"
    [IsBusy]="isBusy"
    (click)="recreateReceipt()"
  >
    Generiši račun
  </button>
</div>

<div *ngIf="(auth.currentUser | async)?.isAdmin && !invoice.paidAt">
  <button class="btn btn-primary" [IsBusy]="isBusy" (click)="markAsPaid()">
    Plaćen
  </button>
</div>
