import { first, pipe } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SelectRejectReasonComponent } from './../select-reject-reason/select-reject-reason.component';
import { SelectPreparationTimeComponent } from './../select-preparation-time/select-preparation-time.component';
import { MatDialog } from '@angular/material/dialog';

import { OrderStatusPipe } from './../_pipes/orderStatus.pipe';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { DeliveryType, Order, OrderStatus } from '../_interface/order';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionService } from '../_services/session.service';
import { AuthorizationService } from '../_services/authorization.service';
import { DueDateSelectorType } from '../_interface/company';
import { SelectDueDateComponent } from '../selectDueDate/selectDueDate.component';

@Component({
  selector: 'app-order-status-selector',
  templateUrl: './order-status-selector.component.html',
  styleUrls: ['./order-status-selector.component.scss']
})
export class OrderStatusSelectorComponent {
  OrderStatus = OrderStatus;
  DeliveryType = DeliveryType;

  @Input() disabled = false;

  @ViewChild('mySwal')
  public readonly mySwal!: SwalComponent;

  enabledStatuses: { [key in OrderStatus]: OrderStatus[] } = {
    [OrderStatus.New]: [OrderStatus.Accepted, OrderStatus.Canceled, OrderStatus.Rejected],
    [OrderStatus.Accepted]: [OrderStatus.InProduction, OrderStatus.Ready, OrderStatus.Canceled, OrderStatus.Completed],
    [OrderStatus.InProduction]: [OrderStatus.Ready, OrderStatus.Canceled],
    [OrderStatus.Ready]: [OrderStatus.Dispached, OrderStatus.Delivered, OrderStatus.Completed, OrderStatus.Canceled],
    [OrderStatus.Dispached]: [OrderStatus.Delivered, OrderStatus.Completed, OrderStatus.Canceled],
    [OrderStatus.Delivered]: [OrderStatus.Accepted, OrderStatus.InProduction, OrderStatus.Ready, OrderStatus.Completed],
    [OrderStatus.Completed]: [],
    [OrderStatus.Rejected]: [],
    [OrderStatus.Canceled]: [],
    [OrderStatus.Invalid]: [OrderStatus.Accepted, OrderStatus.Canceled, OrderStatus.Rejected],
    [OrderStatus.NewPreorder]: [],
    [OrderStatus.Pending]: [OrderStatus.New, OrderStatus.Accepted, OrderStatus.Canceled, OrderStatus.Rejected],
    [OrderStatus.PendingPayment]: [OrderStatus.New, OrderStatus.Accepted, OrderStatus.Canceled],
  }


  @Input() order?: Order;

  @Output() statusChanged = new EventEmitter<{
    orderID: number,
    status: OrderStatus,
    dueDate?: Date
    preparationTime?: number,
    rejectReason?: string
  }>();

  constructor(private session: SessionService,
    public auth: AuthorizationService,
    private TranslateService: TranslateService,
    private dialog: MatDialog) {

  }

  changeOrderStatus(orderID: number, status: OrderStatus, preparationTime?: number, dueDate?: Date, rejectReason?: string) {
    this.statusChanged.emit({ orderID, status, preparationTime, dueDate, rejectReason });
  }

  isDisabled(status: OrderStatus) {
    if (!this.order) {
      return false;
    }


    return !this.enabledStatuses[this.order.status].some(i => i == status) && status != this.order.status;
  }

  statusName(status: OrderStatus) {
    var p = new OrderStatusPipe();
    var key = p.transform(status);
    return this.TranslateService.instant(key);
  }

  async confirmStatusChange(newStatus: OrderStatus) {
    if (this.order?.status == newStatus) {
      return;
    }

    if ((newStatus == OrderStatus.Accepted ||
      newStatus == OrderStatus.Pending ||
      newStatus == OrderStatus.InProduction) && this.order?.deliveryType != DeliveryType.InHouse) {

      this.session.companySettings.pipe(
        first(),
      )
        .subscribe(
          settings => {
            if (settings?.dueDateSelectorType == DueDateSelectorType.Minutes) {
              this.selectPreparationTime(newStatus);
            } else {
              this.selectDueDate(newStatus);
            }
          }
        )
      return;
    }

    if (newStatus == OrderStatus.Rejected) {
      this.selectRejectReason();
      return;
    }


    this.mySwal.swalOptions = {
      text: this.TranslateService.instant("changeOrderStatusConfirmation", { orderStatus: this.statusName(newStatus) })
    };

    var result = await this.mySwal.fire();

    if (result.isConfirmed) {
      this.changeOrderStatus(this.order!.orderID, newStatus);
    }
  }


  selectPreparationTime(newStatus: OrderStatus) {
    var ref = this.dialog.open(SelectPreparationTimeComponent);
    ref.componentInstance.order = this.order;
    ref.afterClosed().subscribe(
      time => {
        if (time) {
          this.changeOrderStatus(this.order!.orderID, newStatus, time);
        }
      }
    )
  }

  selectDueDate(newStatus: OrderStatus) {
    var ref = this.dialog.open(SelectDueDateComponent);
    ref.afterClosed().subscribe(
      (time: Date) => {
        if (time) {
          time.setHours(12);
          this.changeOrderStatus(this.order!.orderID, newStatus, undefined, time);
        }
      }
    )
  }

  selectRejectReason() {
    var ref = this.dialog.open(SelectRejectReasonComponent);
    ref.afterClosed().subscribe(
      reason => {
        if (reason) {
          this.changeOrderStatus(this.order!.orderID, OrderStatus.Rejected, undefined, undefined, reason);
        }
      }
    )
  }

}
