import { SwPush } from '@angular/service-worker';
import { BeepService } from './beep.service';
import { Subject } from 'rxjs';
import { vapidKey } from 'src/environments/vapid';
import { INotificationProvider, INotificationToken } from '../_interface/notificationProvider';
import { OrderStatus } from '../_interface/order';

export class PWANotificationProvider implements INotificationProvider {
  messageReceived = new Subject<any>();
  private cancelWaiterCallFn = (tableID: number) => { };
  private setOrderStatusFn = (orderID: number, orderStatus: OrderStatus) => { };

  cancelWaiterCall(fn: (tableID: number) => void): void {
    this.cancelWaiterCallFn = fn;
  }
  setOrderStatus(fn: (orderID: number, orderStatus: OrderStatus) => void): void {
    this.setOrderStatusFn = fn;
  }

  showNotification(title: string, options: NotificationOptions) {
    navigator.serviceWorker.getRegistration().then(reg => {
      if (reg == null) {
        return;
      }

      reg.showNotification(title, options).then(res => {
        console.log("showed notification", res)
      }, err => {
        console.error(err)
      });
    });
  }

  constructor(private swPush: SwPush,
    private beepService: BeepService,
  ) {

    swPush.messages.subscribe({
      next: (msg: any) => {
        var type = msg.notification.data.type;
        switch (type) {
          case ("callWaiter"): {
            var tableID = msg.notification.data["tableID"];
            var tag = "waiterCall_" + tableID;
            var called = msg.notification.data["called"] === "true";

            if (called) {
              /*   var tableName = msg.notification.data["tableName"];
                var note = msg.notification.data["note"];
                var notificationText = `Pozvan konobar za sto: ${tableName}` + (!note ? "" : ` (${note})`);

                this.showNotification("Konobar pozvan", {
                  body: notificationText,
                  tag: tag + "_" + Math.random()
                }); */
            }
            else {
              this.removeNotification(tag);
            }
            break;
          }
        }
      }
    });


    this.swPush.notificationClicks.subscribe(
      ({ action, notification }) => {
        if (action == "confirm") {
          var tableID = notification.data.tableID;
          this.cancelWaiterCallFn(tableID);
        } else if (action == "accept") {
          var orderID = notification.data.orderID;
          this.setOrderStatusFn(orderID, OrderStatus.Accepted);
        } else if (action == "reject") {
          var orderID = notification.data.orderID;
          this.setOrderStatusFn(orderID, OrderStatus.Rejected);
        }
      }); 0
  }


  getToken(): Promise<INotificationToken | null> {

    if (!this.isEnabled) {
      return Promise.resolve(null);
    }

    return this.swPush.requestSubscription({
      serverPublicKey: vapidKey
    }).then(sub => {
      return {
        provider: 'pwa',
        token: JSON.stringify(sub)
      };
    });
  }

  removeNotification(tag: string): void {
    navigator.serviceWorker.ready.then(function (registration) {
      registration.getNotifications().then(
        notifications => {

          notifications.filter(n => n.tag.startsWith(tag)).forEach(n => n.close());
        }
      );
    });
  }

  get isEnabled(): boolean {
    return this.swPush.isEnabled;
  }

  unsubscribe() {
    this.swPush.unsubscribe();
  }
}
