import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { accessDeniedDescriptions } from 'src/app/core/_helpers/accessDeniedDescriptions';
import { ApiResponse, GenericItemApiResponse, GenericListApiResponse } from 'src/app/core/_interface/apiResponse';
import { CompanyAdminSettings, CompanySettings, ICompany } from 'src/app/core/_interface/company';
import { Permissions, UserPermissions } from 'src/app/core/_interface/permissions';
import { Employee, User } from 'src/app/core/_interface/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {




  saved = new Subject<ICompany>();
  companySettingsSaved = new Subject<CompanySettings>();

  constructor(private http: HttpClient) {

  }

  searchCompanyByTaxNo(taxNo: string): Observable<ICompany | number> {
    const apiAddress = environment.apiBase + 'company/getByTaxNo/?taxNo=' + taxNo;

    const response = this.http.get<GenericItemApiResponse<ICompany>>(apiAddress).pipe(
      map((data) => {
        if (data.success) {
          return data.item;
        } else {
          return data.errorCode;
        }
      })
    );
    return response;
  }

  getCompany(companyID: number) {
    const apiAddress = environment.apiBase + 'company/getByID/?id=' + companyID;

    const response = this.http.get<GenericItemApiResponse<ICompany>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.item;
        }
        return null;
      })
    );

    return response;
  }

  getCompaniesByName(name: string): Observable<ICompany[] | null> {
    const apiAddress = environment.apiBase + 'company/getByName/?name=' + name;

    const response = this.http.get<GenericListApiResponse<ICompany>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.items;
        }
        return null;
      })
    );

    return response;
  }

  getCompaniesByOwnerID(id: number): Observable<ICompany[] | null> {
    const apiAddress = environment.apiBase + 'company/getByOwnerID/?userid=' + id;

    const response = this.http.get<GenericListApiResponse<ICompany>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.items;
        }
        return null;
      })
    );
    return response;
  }

  getCompaniesByExpirationDate(): Observable<ICompany[] | null> {
    const apiAddress = environment.apiBase + 'company/getByExpirationDate/';

    const response = this.http.get<GenericListApiResponse<ICompany>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.items;
        }
        return null;
      })
    );
    return response;
  }

  getNewest(): Observable<ICompany[] | null> {
    const apiAddress = environment.apiBase + 'company/getNewest/';

    const response = this.http.get<GenericListApiResponse<ICompany>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.items;
        }
        return null;
      })
    );
    return response;
  }

  getUserCompanies(): Observable<ICompany[]> {
    const url = environment.apiBase + `company/GetUserCompanies`;
    return this.http.get<GenericListApiResponse<ICompany>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          return [];
        })
      );
  }

  save(company: ICompany): Observable<ICompany | null> {
    const apiAddress = environment.apiBase + 'company/save';

    const response = this.http.post<GenericItemApiResponse<ICompany>>(apiAddress, company).pipe(
      map((data) => {
        if (data.success) {
          return data.item;
        }
        return null;
      }),
      tap(data => {
        if (data) {
          this.saved.next(data)
        }
      }));

    return response;
  }


  saveEmployee(companyID: number, employee: Employee, permissions: Permissions[]): Observable<number> {
    const apiAddress = environment.apiBase + 'company/saveEmployee';
    const postData = {
      companyID: companyID,
      userID: employee.userID,
      firstName: employee.firstName,
      lastName: employee.lastName,
      eMail: employee.eMail,
      referenceID: employee.referenceId,
      sendNotifications: employee.sendNotifications,
      permissions: permissions,
      pin: employee.pin
    };



    const response = this.http.post<GenericItemApiResponse<number>>(apiAddress, postData, {
      headers: { accessDeniedDescription: accessDeniedDescriptions.saveEmployee }
    }).pipe(
      map((data) => {
        if (data.success) {
          return data.item;
        }
        return 0;
      }
      ));

    return response;
  }

  removeEmployee(companyID: number, userID: number): Observable<boolean> {
    const apiAddress = environment.apiBase + 'company/RemoveEmployee';
    const postData = {
      companyID: companyID,
      userID: userID
    };

    const response = this.http.post<ApiResponse>(apiAddress, postData).pipe(
      map((data) => data.success)
    );

    return response;
  }


  uploadImage(companyID: number, type: 'logo' | 'background' | 'QRCodeLogo' | 'userAppIcon' | 'printImage' | 'eatInImage' | 'pickupImage', file: File): Observable<boolean> {
    const url = environment.apiBase + 'company/uploadImage';

    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('companyID', companyID.toString());
    formData.append('type', type);

    return this.http.post<ApiResponse>(url, formData)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }


  getEmployees(companyID: number): Observable<User[] | null> {
    if (!companyID) {
      return of(null);
    }

    const apiAddress = environment.apiBase + 'company/GetEmployees/?companyid=' + companyID;

    const response = this.http.get<GenericListApiResponse<User>>(apiAddress, {
      headers: { accessDeniedDescription: accessDeniedDescriptions.getEmployees }
    }).pipe(
      map((data) => {
        if (data.success) {
          return data.items;
        }
        return null;
      })
    );
    return response;

  }



  getEmployee(companyID: number, uniqueID: string): Observable<User | null> {
    if (!companyID) {
      return of(null);
    }

    const apiAddress = environment.apiBase + 'company/GetEmployee';
    var data = {
      companyID: companyID,
      uniqueID: uniqueID
    };

    const response = this.http.post<GenericItemApiResponse<User>>(apiAddress, data).pipe(
      map((data) => {
        if (data.success) {
          return data.item;
        }
        throw data;
      })
    );
    return response;
  }


  getSettings(id: number): Observable<CompanySettings | null> {
    const apiAddress = environment.apiBase + 'company/getSettings/?companyID=' + id;

    const response = this.http.get<GenericItemApiResponse<CompanySettings>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.item;
        }
        return null;
      })
    );

    return response;
  }

  getAdminSettings(id: number) {
    const apiAddress = environment.apiBase + 'company/getAdminSettings/' + id;

    const response = this.http.get<GenericItemApiResponse<CompanyAdminSettings>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.item;
        }
        throw data;
      })
    );

    return response;
  }

  saveSettings(companyID: number, settings: CompanySettings): Observable<boolean> {
    const apiAddress = environment.apiBase + 'company/saveSettings/?companyID=' + companyID;

    const response = this.http.post<ApiResponse>(apiAddress, settings).pipe(
      tap(
        result => {
          if (result.success) {
            this.companySettingsSaved.next(settings);
          }
        }
      ),
      map((data) => {
        return data.success;
      })
    );
    return response;
  }

  saveAdminSettings(companyID: number, settings: CompanyAdminSettings): Observable<boolean> {
    const apiAddress = environment.apiBase + 'company/saveAdminSettings/?companyID=' + companyID;

    const response = this.http.post<ApiResponse>(apiAddress, settings).pipe(
      map((data) => {
        if (data.success)
          return data.success;

        throw data;

      })
    );
    return response;
  }

  getPermissions(companyID: number, userID: number): Observable<Permissions[] | null> {
    const apiAddress = environment.apiBase + `company/getPermissions/?companyID=${companyID}&userID=${userID}`;

    const response = this.http.get<GenericListApiResponse<Permissions>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.items?.map(i => Permissions[i] as any); //dobijamo string, konvertujemo u ENUM
        }
        return null;
      })
    );

    return response;
  }

  getAPIKey(companyID: number): Observable<string | null> {
    const apiAddress = environment.apiBase + `company/getAPIKey/?companyID=${companyID}`;

    const response = this.http.get<GenericItemApiResponse<string>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.item;
        }
        return null;
      })
    );

    return response;
  }


  assignAPIKey(companyID: number): Observable<string | null> {
    const apiAddress = environment.apiBase + `company/assignAPIKey/?companyID=${companyID}`;

    const response = this.http.get<GenericItemApiResponse<string>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.item;
        }
        return null;
      })
    );

    return response;
  }


  generateSignInUrl(companyID: number, userID: number) {
    const apiAddress = environment.apiBase + `company/GenerateSignInToken/?companyID=${companyID}&userID=${userID}`;

    const response = this.http.post<GenericItemApiResponse<string>>(apiAddress, null).pipe(
      map(data => {
        if (data.success) {
          return data.item;
        }
        return null;
      })
    );

    return response;
  }

  deleteDatabase(companyID: number, type: number) {
    const apiAddress = environment.apiBase + `company/DeleteDatabase/?companyID=${companyID}&type=${type}`;

    const response = this.http.post<ApiResponse>(apiAddress, null).pipe(
      map(data => {
        return data.success
      })
    );

    return response
  }


  logOutUser(companyID: number, userID: number) {
    const apiAddress = environment.apiBase + `company/LogOutUser/?companyID=${companyID}&userid=${userID}`;

    const response = this.http.post<ApiResponse>(apiAddress, null).pipe(
      map(data => {
        return data.success
      })
    );

    return response
  }


}
