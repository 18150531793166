import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '../_interface/wolt';

@Pipe({
  name: 'woltOrderStatusName'
})
export class WoltOrderStatusNamePipe implements PipeTransform {

  transform(value: OrderStatus) {
    switch (value) {
      case (OrderStatus.acknowledged):
        return "acknowledged";
      case (OrderStatus.delivered):
        return "delivered";
      case (OrderStatus.fetched):
        return "fetched";
      case (OrderStatus.production):
        return "production";
      case (OrderStatus.ready):
        return "ready";
      case (OrderStatus.received):
        return "received";
      case (OrderStatus.refunded):
        return "refunded";
      case (OrderStatus.rejected):
        return "rejected";
    }
  }

}
