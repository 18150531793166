import { Directive, ElementRef, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
// watch dom change and fire (dom-changed) event
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[wathcDOMChange]'
})
export class DomChangedDirective implements OnInit, OnChanges {
  private observer: MutationObserver;

  @Output()
  domChanged = new EventEmitter();

  @Input() wathcDOMChange: MutationObserverInit = {};

  constructor(private elRef: ElementRef) {
    this.observer = new MutationObserver(list => {
      const evt =
        new CustomEvent('dom-changed',
          { detail: list, bubbles: true });

      this.domChanged.emit(evt);
      //      el.dispatchEvent(evt);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.registerDomChangedEvent(this.elRef.nativeElement);
  }

  ngOnInit() {
    this.registerDomChangedEvent(this.elRef.nativeElement);
  }

  registerDomChangedEvent(el: any) {
    this.observer.disconnect();
    this.observer.observe(el, this.wathcDOMChange);
  }
}
