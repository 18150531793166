import { DeliveryType } from "./order";


export interface TableLayoutItem {
  tableID: number;
  name: string;
  groupName:string;
  xPosition: number;
  yPosition: number;
  type: TableType;
  data?: any;
  fillColor?: string;
}

export interface Table {
  tableID: number;
  name: string;
  group: string;
  qrCodes: string[];
  referenceID: string;
  deliveryType: DeliveryType;
  deliveryAddress: string;
  type: TableType;
  xPosition: number,
  yPosition: number,
}

export interface TableTotal {
  orders: TableTotalOrder[];
  source: TableTotalSource;
}

export enum TableTotalSource {
  eMeni = 1,
  POS = 2
}

export interface TableTotalOrder {
  date: string;
  createdBy: string;
  items: TableTotalItem[];
}
export interface TableTotalItem {
  productID: number;
  quantity: number;
  price: number;
  name: string;
}

export enum TableType {
  Round = 1,
  Rectangle = 2,
  Square = 3,
  RectangleVertical = 4,
  Square45 = 5
}

export interface PrecheckRequest {
  companyID: number;
  tableID?: number;
  orderIDs?: number[];
  amount: number;
  customerID?: number;
}

export interface PrecheckResponse {
  amount: number;
}
