import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
  name: 'field'
})
export class FieldPipe implements PipeTransform {

  transform(value: any | any[], field: string) {
    if (Array.isArray(value)) {
      return value.map((v) => v[field]);
    }
    else {
      return value[field];
    }
  }

}
