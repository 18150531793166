
export enum PaymentType {
  None = 0,
  Cash = 1,
  CreditCard = 2,
  Instant = 3,
  Check = 4,
  WireTransfer = 5,
  Voucher = 6,
  Other = 7,
  Presentation = 100,
}


export interface Payment {
  type: PaymentType;
  amount: number;
  transaction?: any;
}

export enum TransactionStatus {
  Canceled = 1,
  Declined = 2,
  Failed = 3,
  Success = 0
}
