import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumItemName'
})
export class EnumItemNamePipe implements PipeTransform {

  transform(item: any, names: any[]) {
    return names == null ? item : names[item] || item;
    ;
  }

}
