import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle-full-screen',
  templateUrl: './toggle-full-screen.component.html',
  styleUrls: ['./toggle-full-screen.component.scss']
})
export class ToggleFullScreenComponent implements OnInit {
  isFullScreen = false;
  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  updateIsFullScreen() {
    this.isFullScreen = !!document.fullscreenElement;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    this.updateIsFullScreen();
  }

  toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      var elem = document.documentElement;

      elem.requestFullscreen();
    }
  }
}
