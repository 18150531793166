import { Pipe, PipeTransform } from '@angular/core';
import { interval, map } from 'rxjs';

@Pipe({
  name: 'elapsedMinutes'
})
export class ElapsedMinutesPipe implements PipeTransform {

  transform(value: Date | string) {
    return interval(100).pipe(
      map(() => Math.floor((new Date().getTime() - new Date(value).getTime()) / 60000))
    );
  }

}
