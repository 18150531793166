import { Pipe, PipeTransform } from "@angular/core";
import { IName, ISortable } from "../_interface/product";

export enum SORT {
  General = 0,
  UserApp = 1,
  WaiterApp = 2
}

@Pipe({ name: "sortByName" })
export class SortByNamePipe implements PipeTransform {
  transform<T extends IName>(products: T[] | null): T[] | null {
    if (!products) {
      return null;
    }

    const sorted = products.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sorted;
  }
}
