import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { WSService } from './ws.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AppVersionCheckService {
  autoUpdate = false;

  constructor(private updates: SwUpdate,
    private logger: NGXLogger,
    private TranslateService: TranslateService,
    snackBar: MatSnackBar,
    ws: WSService) {


    if (updates.isEnabled) {
      updates.versionUpdates.subscribe(
        e => {
          if (e.type == "VERSION_READY") {
            this.createPopup();

            if (this.autoUpdate) {
              this.update();
            }
          }
        }
      );

      interval(10 * 60 * 1000).subscribe(() => this.checkForUpdates());  // update sw na 10 min

      ws.observe("checkVersion").subscribe(() => this.checkForUpdates());
    }
  }

  checkForUpdates() {
    if (!this.updates.isEnabled) {
      this.logger.warn("SW not enabled.");
      return;
    }

    this.updates.checkForUpdate();
  }

  update() {
    this.logger.log("Updating application");
    this.updates.activateUpdate().then(
      () => {
        this.logger.log("Update completed. Reloading");
        document.location.reload();
      }
    )
  }

  createPopup() {
    var el = document.getElementById("newVersionFound");
    if (!el) {
      el = document.createElement("div");
      el.id = 'newVersionFound';
      el.innerHTML = '<div style="display: flex; cursor: pointer; position: absolute; left: 10px; bottom: 10px; z-index: 10000; border-radius: 5px; overflow: hidden; padding: 15px; background-color: black; color: #e0e0e0">' +
        '<img src="/assets/icons/icon-192x192_white.png" style="height: 46px; margin-right: 10px"/>' +
        '<div>' +
        '<div style="font-weight: bold; margin-bottom: 10px">' +
        this.TranslateService.instant('newVersionDetected') +
        '</div>' +
        this.TranslateService.instant('clickToActivateNewVersion') +
        '</div>' +
        '</div>';

      el.onclick = (e) => { this.update() };

      document.body.appendChild(el);
    }
  }
}
