import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DeviceService } from "./_services/device.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {

  constructor(private ds: DeviceService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var newHeaders = req.headers.set("app", environment.appVersion);
    var r = req.clone(
      {
        headers: newHeaders
      }
    );

    return next.handle(r);
  }

}
