import { Pipe, PipeTransform } from '@angular/core';
import { Translation } from 'src/app/core/_interface/tranlsation';

@Pipe({
  name: 'selectLanguage'
})
export class SelectLanguagePipe implements PipeTransform {

  transform(value: Translation[], language: string) {
    return value?.find(i => i.language == language);
  }

}
