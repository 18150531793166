import { WSService } from 'src/app/core/_services/ws.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-websocket-connection',
  templateUrl: './no-websocket-connection.component.html',
  styleUrls: ['./no-websocket-connection.component.scss']
})
export class NoWebsocketConnectionComponent implements OnInit {
  @Input() fullScreenWarningThreshold = 2;

  @Input() showFullScreen = false;

  constructor(public ws: WSService) {


  }

  ngOnInit(): void {
  }

}
