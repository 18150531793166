import { Pipe, PipeTransform } from '@angular/core';
import { OrderItem } from '../_interface/order';

@Pipe({
  name: 'hasInvalidDetails'
})
export class HasInvalidDetailsPipe implements PipeTransform {

  transform(value: OrderItem[]) {
    return value?.some(i => (i.invalidOrderDetails?.length || 0) > 0);
  }

}
