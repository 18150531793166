import { Pipe, PipeTransform } from "@angular/core";
import { ISortable } from "../_interface/product";

@Pipe({ name: "imageSize" })
export class ImageSizePipe implements PipeTransform {
  transform(url: string, width: number, height: number) {
    if (!url) {
      return url;
    }

    return url + "_" + width + "x" + height;
  }
}
