<div
  class="order"
  [class.invalid]="order | isInvalidOrder"
  (click)="onClick(order)"
>
  <div class="header">
    <div class="title">
      <div class="time">
        {{ order.created | date : "HH:mm" }} ({{
          order.accepted || order.created | elapsedMinutes | async
        }}')
      </div>
      <div class="orderNo">#{{ order.orderNo }}</div>
      <div class="delivery">
        <app-order-icons [order]="order"></app-order-icons>
        <!-- <app-order-delivery-type-icon
          *ngIf="order.deliveryType === DeliveryType.Delivery"
          [deliveryType]="order.deliveryType"
        ></app-order-delivery-type-icon> -->
        <app-provider-icon [provider]="order.provider"></app-provider-icon>
      </div>
    </div>
    <div class="waiterAndTable" *ngIf="showDetails">
      <div class="waiter">{{ order.waiter?.name }}</div>
      <div class="table">
        <ng-container *ngIf="order.deliveryType === DeliveryType.InHouse">
          Sto: {{ (order.tableID | getTableByID | async)?.name }}
        </ng-container>
      </div>
    </div>
  </div>
  <app-order-progress-bar
    *ngIf="showProgressBar"
    [showTimer]="order.status != OrderStatus.New"
    [order]="order"
  ></app-order-progress-bar>

  <div class="items" *ngIf="showDetails">
    <div class="warning orderNote" *ngIf="order.note">{{ order.note }}</div>
    <ng-container *ngFor="let item of items || order.items">
      <div
        [appOrderItemColor]="
          showProgressBar &&
          showItemProgress &&
          order.status != OrderStatus.New &&
          item.status != OrderItemStatus.Ready &&
          order.status != OrderStatus.Ready
        "
        [preparationTime]="
          (item.productID | toProduct | async)?.preparationTime
        "
        [startDate]="order.accepted || order.created"
        class="item"
        [class.canceled]="item.isCanceled"
      >
        <div class="quantity">{{ item.quantity }}x</div>
        <div class="details">
          <div
            class="name productName"
            (click)="onOrderItemClicked(item)"
            [clickStopPropagation]="enableOrderItemStatusChange"
          >
            {{ item.name }}

            <mat-icon
              *ngIf="
                item.status == OrderItemStatus.Delivered ||
                  order.status == OrderStatus.Delivered;
                else readyCheck
              "
              class="ready"
              >done_all</mat-icon
            >
            <ng-template #readyCheck>
              <mat-icon
                *ngIf="
                  item.status == OrderItemStatus.Ready ||
                  order.status == OrderStatus.Ready
                "
                class="ready"
                >done</mat-icon
              >
            </ng-template>
          </div>
          <div
            class="modifierItem"
            *ngFor="
              let group of (modifiersFilter(item.modifiers)
                | groupOrderItemModifiers
                | async) || []
            "
          >
            + {{ group.modifier.name }}:
            <span class="name modifierProduct" *ngFor="let item of group.items">
              <span *ngIf="item.quantity > 1" class="quantity"
                >{{ item.quantity }}x</span
              >
              {{ (item.productID | getProductByID | async)?.name }}</span
            >
          </div>
          <div
            class="itemNote"
            [innerHTML]="item.note | preserveNewLine"
          ></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
