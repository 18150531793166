import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CompanyService } from '../_services/company.service';
import { AuthorizationService } from '../_services/authorization.service';

@Component({
  selector: 'app-pin-login',
  templateUrl: "./pin-login.component.html",
  styleUrls: ['./pin-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinLoginComponent {
  isBusy = false;

  pin: string = '';

  @Input() pinConfig: any;

  @Output() onLogedIn = new EventEmitter();
  @Output() onLogout = new EventEmitter();

  constructor(private authService: AuthorizationService) {

  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];


    if (event.key === 'Enter') {
      this.login();
    }
    else if (event.key === 'Backspace') {
      this.delete();
    }
    else if (validkeys.indexOf(event.key) > -1) {
      this.addPin(event.key);
    }
  }

  addPin(char: string | number) {
    this.pin += char;

  }

  delete() {
    this.pin = this.pin.slice(0, -1);
  }

  login() {
    this.isBusy = true;
    this.authService.loginWithPin(this.pinConfig.companyID, this.pin, this.pinConfig.token).subscribe((res) => {
      this.onLogedIn.next(res);
    }).add(() => {
      this.pin = '';
      this.isBusy = false;
    });
  }

  logout() {
    this.onLogout.emit();
  }

}
