import { Component, Input, OnInit } from '@angular/core';
import { Order, OrderStatusChangeItem } from '../_interface/order';
import { OrderService } from '../_services/order.service';

@Component({
  selector: 'app-order-status-change-log',
  templateUrl: './order-status-change-log.component.html',
  styleUrls: ['./order-status-change-log.component.scss']
})
export class OrderStatusChangeLogComponent implements OnInit {
  history?: OrderStatusChangeItem[];

  @Input()
  private _order?: Order;

  public get order(): Order | undefined {
    return this._order;
  }

  public set order(value: Order | undefined) {
    this._order = value;
    this.load();

  }

  constructor(public orderService: OrderService) { }

  load() {
    this.orderService.getStatusChangeLog(this.order!.orderID).subscribe(
      val => this.history = val
    )
  }

  ngOnInit(): void {
  }

}
