import { DeliveryType } from '../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';
import { Allergens } from 'src/app/core/_interface/product';


@Pipe({ name: 'toDeliveryTypeName' })
export class ToDeliveryTypeNamePipe implements PipeTransform {

  transform(value: DeliveryType): string {
    var translationKey = 'deliveryTypes.' + Object.keys(DeliveryType)[Object.values(DeliveryType).indexOf(value)].toLowerCase();

    return translationKey;

    switch (value) {
      case (DeliveryType.InHouse):
        return 'Za stolom';
      case (DeliveryType.Pickup):
        return 'Preuzimanje';
      case (DeliveryType.Delivery):
        return 'Dostava';
    }
  }

}
