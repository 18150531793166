import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[selectOnFocus]'
})
export class SelectOnFocusDirective {

  constructor(private element: ElementRef<HTMLInputElement>) { }

  @HostListener("focus")
  onFocus() {
    this.element.nativeElement.select();
  }
}
