import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-group-box',
  templateUrl: './group-box.component.html',
  styleUrls: ['./group-box.component.scss']
})
export class GroupBoxComponent implements OnInit {
  @Input() title: string = "";
  @Input() titleTemplate: TemplateRef<any> | null = null;


  constructor() { }

  ngOnInit(): void {
  }

}
