<button class="flat action switchCamera" (click)="toggleCamera()">
  <i class="emenu icon-flip_camera"></i>
</button>

<button
  class="flat action flash"
  (click)="toggleTorch()"
  *ngIf="scaner.torchCompatible | async"
>
  <i
    class="emenu icon-flash_on"
    [class.icon-flash_on]="!scaner.torch"
    [class.icon-flash_off]="scaner.torch"
  ></i>
</button>

<zxing-scanner
  class="videoPreview"
  [previewFitMode]="'fill'"
  #scaner
  [delayBetweenScanSuccess]="delayBetweenScanSuccess"
  (camerasFound)="camerasFound($event)"
  (scanSuccess)="scanSuccessHandler($event)"
>
</zxing-scanner>
