import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSince'
})
export class TimeSincePipe implements PipeTransform {

  transform(value: Date): unknown {

    var seconds = Math.floor((new Date().getTime() - value.getTime()) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " godina";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " meseci";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " dana";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " sati";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minuta";
    }
    return Math.floor(seconds) + " sekundi";
  }

}
