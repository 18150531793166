<div class="container" [class.vertical]="vertical">
  <span class="name">
    <ng-container
      [ngTemplateOutlet]="nameTemplate ?? defaultNameTemplate"
      [ngTemplateOutletContext]="{ $implicit: name }"
    ></ng-container>
  </span>
  <div class="value">
    <ng-container
      [ngTemplateOutlet]="valueTemplate ?? defaultValueTemplate"
      [ngTemplateOutletContext]="{ value: value }"
    ></ng-container>
  </div>
</div>
<ng-template #defaultNameTemplate>
  {{ name }}
</ng-template>

<ng-template #defaultValueTemplate>
  <ng-container *ngIf="value !== undefined; else content">
    {{ value }}
  </ng-container>
</ng-template>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
