import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[IsBusy]'
})
export class IsBusyDirective {
  @Input()
  set IsBusy(val: any) {
    if (val) {
      this.renderer.addClass(this.hostElement.nativeElement, 'isBusy');

      if (val == 'white') {
        this.renderer.addClass(this.hostElement.nativeElement, 'white');
      }
    } else {
      this.renderer.removeClass(this.hostElement.nativeElement, 'isBusy');
      this.renderer.removeClass(this.hostElement.nativeElement, 'white');
    }
  }

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {
    this.renderer.addClass(this.hostElement.nativeElement, 'isBusyContainer');

  }

}
