import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduceStringArray'
})
export class ReduceStringArrayPipe implements PipeTransform {

  transform(value: string[] | undefined, separator: string = ",") {
    if (!value) {
      return value;
    }
    return value.reduce((a, b) => a + (!!a ? separator + " " : " ") + b, "");
  }

}
