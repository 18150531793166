import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDragToScroll]'
})
export class DragToScrollDirective {
  pos = { top: 0, left: 0, x: 0, y: 0 };
  @Input() deltaThreshold = 10;

  trackMove = false;
  scrolled = false;

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.cursor = 'grab';

  }

  @HostListener('mousedown', ['$event'])
  mouseDown(e: MouseEvent) {
    this.pos = {
      // The current scroll
      left: this.el.nativeElement.scrollLeft,
      top: this.el.nativeElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    this.trackMove = true;
    this.scrolled = false;
    this.el.nativeElement.style.cursor = 'grabbing';

    e.stopPropagation();
    e.preventDefault();
  };

  @HostListener('document:mousemove', ['$event'])
  mouseMoveHandler(e: MouseEvent) {
    if (!this.trackMove) {
      return;
    }

    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;

    //console.log(this.pos.left, dx, this.pos.top, dy);

    // Scroll the element
    this.el.nativeElement.scrollTop = this.pos.top - dy;
    this.el.nativeElement.scrollLeft = this.pos.left - dx;
    if (Math.abs(dy) > this.deltaThreshold || Math.abs(dx) > this.deltaThreshold) {
     // console.log("scrolled");
      this.scrolled = true;
      this.el.nativeElement.style['pointer-events'] = 'none';

    }
    e.preventDefault();
  };

  @HostListener('document:mouseup', ['$event'])
  mouseUpHandler(e: MouseEvent) {
    if (!this.trackMove) {
      return;
    }

    this.trackMove = false;
    this.el.nativeElement.style.cursor = 'grab';
    this.el.nativeElement.style['pointer-events'] = 'unset';
  };

}
