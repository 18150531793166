import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponse, GenericItemApiResponse, GenericListApiResponse } from '../_interface/apiResponse';
import { INotificationToken } from '../_interface/notificationProvider';
import { map, of, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Device } from '../_interface/device';

export enum PushNotificationProvider {
  FCM = "fcm",
  WebPush = "webPush"
}

export enum DeviceApplication {
  User = 0,
  Waiter = 1
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  _deviceID: number = 0;

  _udid: string | null = null;

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  setNotificationChannel(application: DeviceApplication, subscription: INotificationToken) {

    const url = environment.apiBase + `device/SetNotificationChannel`;
    var data = {
      application: application,
      subscription: subscription.token,
      provider: subscription.provider
    }

    return this.http.post<ApiResponse>(url, data).subscribe();
  }

  getDevice(deviceID: any) {
    const url = environment.apiBase + `device/get/${deviceID}`;

    return this.http.get<GenericItemApiResponse<Device>>(url)
      .pipe(
        map((response: GenericItemApiResponse<Device>) => {
          if (response.success) {
            return response.item;
          }

          throw new Error(response.errorDescription);
        })
      );
  }

  companyDevices(companyID: number) {
    const url = environment.apiBase + `device/forCompany/${companyID}`;

    return this.http.get<GenericListApiResponse<Device>>(url)
      .pipe(
        map((response: GenericListApiResponse<Device>) => {
          if (response.success) {
            return response.items;
          }

          throw new Error(response.errorDescription);
        })
      );
  }

  updateDevice(device: Device) {
    const url = environment.apiBase + `device/save`;
    var data = {
      name: device.name,
      deviceID: device.deviceID,
      waiterAppVersion: device.waiterAppVersion,
      kitchenDisplayAppVersion: device.kitchenDisplayAppVersion,
      customerDisplayAppVersion: device.customerDisplayAppVersion,
      adminAppVersion: device.adminAppVersion,
      kioskAppVersion: device.kioskAppVersion,
    }

    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map((response: ApiResponse) => {
          if (response.success) {
            return response.success;
          }

          throw new Error(response.errorDescription);
        })
      );
  }

  requestNativeLog(deviceID: number) {
    const url = environment.apiBase + `device/RequestNativeLog/${deviceID}`;

    return this.http.post<ApiResponse>(url, null).subscribe();
  }

  checkVersion(deviceID: number) {
    const url = environment.apiBase + `device/checkVersion/${deviceID}`;

    return this.http.post<ApiResponse>(url, null).subscribe();
  }

  reload(deviceID: number) {
    const url = environment.apiBase + `device/reload/${deviceID}`;

    return this.http.post<ApiResponse>(url, null).subscribe();
  }

  sendConfig(deviceID: number, config: any) {
    const url = environment.apiBase + `device/sendconfig`;
    var data = {
      deviceID: deviceID,
      config: JSON.stringify(config)
    }
    return this.http.post<ApiResponse>(url, data).subscribe();
  }

  getID() {
    if (this._deviceID) {
      return of(this._deviceID);
    }
    const url = environment.apiBase + `device/id`;

    return this.http.get<number>(url)
      .pipe(
        tap((id: number) => {
          this._deviceID = id;
        }
        )
      );
  }

  get deviceID(): string {
    if (!this._udid) {
      this._udid = this.cookieService.get("udid");
      if (this._udid) {
        return this._udid;
      }

      this._udid = localStorage.getItem("udid");
      if (!this._udid) {
        this._udid = this.makeid(48);
        localStorage.setItem("udid", this._udid);
      }

      this.cookieService.set("udid", this._udid, 365, "/", this.topLevelDomain);

    }
    return this._udid;
  }

  get topLevelDomain() {
    var parts = location.hostname.split('.');
    return parts.slice(-2).join('.');
  }

  makeid(length: number): string {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

}
