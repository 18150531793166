import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from "@angular/core";
import { SessionService } from "../_services/session.service";
import { Product } from "../_interface/product";
import { map } from 'rxjs/operators';

@Pipe({ name: 'parentProduct' })
export class ParentProductPipe implements PipeTransform {
  constructor(private session: SessionService) {

  }

  transform(product: Product | undefined | null): Observable<Product | null> | undefined {
    if (!product) {
      return of(null);
    }

    return this.session.products?.pipe(
      map(
        list => {
          return list.find(p => p.productID === product.parentProductID) || null;
        }
      )
    )
  }
}
