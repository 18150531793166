<div>
  <mat-form-field>
    <mat-label> {{ "language" | translate }}</mat-label>
    <mat-select
      #selectedTranslation
      [value]="availableTranslations[0].code"
      (selectionChange)="languageChanged(selectedTranslation.value)"
    >
      <mat-option
        *ngFor="let item of availableTranslations | appSortLanguages | async"
        [value]="item.code"
        >{{ item.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<ng-container [formGroup]="getTranslation(selectedTranslation.value)">
  <div>
    <mat-form-field>
      <mat-label>{{ "name" | translate }} </mat-label>
      <input
        matInput
        autocomplete="off"
        #productName
        maxlength="50"
        formControlName="name"
        type="text"
      />
      <mat-hint align="end">{{ productName.value.length }}/50</mat-hint>
      <button mat-icon-button matSuffix (click)="setProperCase('name')">
        <mat-icon class="material-symbols-outlined">match_case</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>
        {{ "description" | translate }}
      </mat-label>
      <textarea
        matInput
        #description
        class="description"
        formControlName="description"
      ></textarea>
      <mat-hint align="end">{{ description.value.length }}/2000</mat-hint>
      <button mat-icon-button matSuffix (click)="setProperCase('description')">
        <mat-icon class="material-symbols-outlined">match_case</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-chip-list #tagList aria-label="Tag">
        <mat-chip
          *ngFor="
            let tag of getTranslation(selectedTranslation.value).get('tags')
              ?.value || []
          "
          (removed)="removeTag(tag)"
        >
          {{ tag }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          #chipInput
          #trigger="matAutocompleteTrigger"
          placeholder="Tag ..."
          [matAutocomplete]="filterOptions"
          [matChipInputFor]="tagList"
          (keyup.enter)="addTag(chipInput.value)"
        />
      </mat-chip-list>
      <mat-autocomplete #filterOptions (optionSelected)="addTag($event)">
        <mat-optgroup label="Status">
          <mat-option *ngFor="let tag of existingTags" [value]="tag">
            {{ tag }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-container>
