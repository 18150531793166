import { PrintService } from './../_services/print.service';
import { CustomerDetailsComponent } from '../customer-details/customer-details.component';
import { OrderStatusChangeLogComponent } from './../order-status-change-log/order-status-change-log.component';
import { MrDOrderDetailsComponent } from './../mr-dorder-details/mr-dorder-details.component';
import { WoltOrderDetailsComponent } from './../wolt-order-details/wolt-order-details.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit, Output, EventEmitter, inject, Inject } from '@angular/core';
import { DeliveryType, Order, OrderStatus } from 'src/app/core/_interface/order';
import { OrderService } from 'src/app/core/_services/order.service';
import Swal from 'sweetalert2';
import { SessionService } from '../_services/session.service';
import { AppSettingsService } from '../_services/AppSettings.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent {
  DeliveryType = DeliveryType;
  OrderStatus = OrderStatus;
  canChangeOrderStatus = true;

  @Input() order?: Order;
  @Input() groupInTable?: string;

  @Output() statusChanged = new EventEmitter<OrderStatus>();
  constructor(private ordersService: OrderService,
    private dialog: MatDialog,
    public appSettingsService: AppSettingsService,
    public session: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: Order,
    private PrintService: PrintService,
    private toast: ToastrService) {
    this.order = data;

  }



  changeOrderStatus(e: { orderID: number, status: OrderStatus, preparationTime?: number, dueDate?: Date, rejectReason?: string }) {
    this.setOrderStatus(e.orderID, e.status, e.preparationTime, e.dueDate, e.rejectReason);
  }


  setOrderStatus(orderID: number, status: OrderStatus, preparationTime?: number, dueDate?: Date, rejectReason?: string) {
    this.canChangeOrderStatus = false;

    this.ordersService.setStatus(orderID,
      status,
      this.groupInTable || this.order!.group,
      preparationTime,
      dueDate,
      rejectReason).subscribe({
        next: success => {
          this.statusChanged.emit(status);
          this.order!.status = status;
          this.order!.rejectReason = rejectReason || '';

          this.toast.success("Status promenjen");
        },
        error: (e: string) => {
          Swal.fire({
            title: "Greška",
            text: e,
            icon: "error"
          });
        }
      }

      ).add(() => {
        this.canChangeOrderStatus = true;
      });
  }

  showProviderDetails() {
    switch (this.order?.provider) {
      case ("wolt"):
        var ref = this.dialog.open(WoltOrderDetailsComponent);
        ref.componentInstance.orderID = this.order.orderID;
        break;
      case ("mrd"):
        var refMrd = this.dialog.open(MrDOrderDetailsComponent);
        refMrd.componentInstance.orderID = this.order.orderID;
        break;
    }
  }

  showOrderStatusLog() {
    var ref = this.dialog.open(OrderStatusChangeLogComponent);

    ref.componentInstance.order = this.order;
  }

  showCustomerDetails() {
    this.dialog.open(CustomerDetailsComponent, { data: this.order?.customer });
  }

  printOrder() {
    if (!this.order) {
      this.toast.error("Nema podataka za kreiranje PDF dokumenta");
      return;
    }

    this.ordersService.getOrderPDF(this.order?.orderID)
      .subscribe({
        next:
          (res) => {
            this.PrintService.print(res);
          }
        , error: (e) => {
          this.toast.error("Greška prilikom preuzimanja PDF dokumenta - " + e.description);
        }
      }
      );
  }

}
