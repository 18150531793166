import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { AuthorizationService } from '../_services/authorization.service';

@Injectable()
export class AuthTokenLoadedResolver implements Resolve<any> {

  constructor(private auth: AuthorizationService) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.tokenLoaded.asObservable().pipe(
      filter(val=>val),
      first()
    );
  }
}
