<app-dialog-title title="{{ 'chosePreparationTime' | translate }}"></app-dialog-title>
<mat-dialog-content>
  <button
    mat-raised-button
    color="primary"
    *ngIf="defaultPreparationTime"
    [matDialogClose]="defaultPreparationTime"
  >
    {{ defaultPreparationTime }}
  </button>

  <button
    mat-stroked-button
    [matDialogClose]="item"
    *ngFor="let item of [10, 15, 20, 25, 30]"
  >
    {{ item }}
  </button>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" matDialogClose="">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
