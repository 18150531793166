import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss']
})
export class PropertyItemComponent implements OnInit {
  @Input() name: string = '';
  @Input() value?: any;

  @Input() vertical = false;

  @Input() valueTemplate?: TemplateRef<any>;
  @Input() nameTemplate?: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
