import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, Injectable, InjectionToken, Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { AuthorizationService } from './core/_services/authorization.service';
import { ActivatedRoute, UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './core/LowerCaseUrlSerializer';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import 'hammerjs';
import { HttpClient } from '@angular/common/http';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export function SetEnvironmentFlags() {
  return () => {
    const url = new URL(window.location.href);

    var isNative = url.searchParams.get('native');
    if (isNative == null) {
      isNative = localStorage.getItem("nativeApp");
    } else {
      localStorage.setItem("nativeApp", isNative);
    }
    environment.nativeApp = isNative == "1";

  }
}


export function HttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';
  const options: IModuleTranslationOptions = {
    modules: [
      // Fetches file at e.g. /assets/i18n/en.json
      { baseTranslateUrl },
      // Fetches file at e.g. /assets/i18n/non-lazy/en.json
      { baseTranslateUrl, moduleName: 'core' }
    ],
    disableNamespace: true
  };

  return new ModuleTranslateLoader(http, options);
}


const config: SocialAuthServiceConfig = {
  providers: [

    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleClientID, {
        oneTapEnabled: false
      }),
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookAppID)
    }]
};


export function provideConfig() {
  return config;
}

export function tokenGetter() {
  return localStorage.getItem('authToken');
}

export function jwtOptionsFactory(inject: Injector) {


  return {
    tokenGetter: () => {
      const auth = inject.get(AuthorizationService);
      return auth?.getToken();
    },
    allowedDomains: [
      environment.host
    ]
  };
}
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: false },
    'rotate': { enable: false }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SocialLoginModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    HammerModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Injector]
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    }),
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2').then(({ default: swal }) => swal.mixin({
        // example: set global options here
        confirmButtonText: `Da`,
        cancelButtonText: `Ne`,
        scrollbarPadding: false,
        heightAuto: false,
        showClass: {
          popup: 'swal2-noanimation',
          backdrop: 'swal2-noanimation'
        }

      }))
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'sr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }
    )
  ],
  exports: [
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: SetEnvironmentFlags, multi: true },
    { provide: 'SocialAuthServiceConfig', useFactory: provideConfig },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(jwt: JwtHelperService,
    inject: Injector) {



    // circual depencency problem
    // kreiramo JWT service pre prvog requesta kako bi se isti uspešno povezao na dependecy service AuthServiceService
    inject.get(AuthorizationService);

  }
}

