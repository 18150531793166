import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-enum-checkbox-selector',
  templateUrl: './enum-checkbox-selector.component.html',
  styleUrls: ['./enum-checkbox-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnumCheckboxSelectorComponent,
      multi: true
    }
  ]
})
export class EnumCheckboxSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  formGroup!: UntypedFormGroup;

  private _selectedValues: any[] = [];
  public get selectedValues(): any[] {
    return this._selectedValues;
  }
  @Input()
  public set selectedValues(value: any[]) {
    this._selectedValues = value;
    this.updateSelection();
  }

  @Input() itemTemplate?: TemplateRef<any>;
  @Input() nameTemplate?: TemplateRef<any>;

  @Input()
  enum!: any;

  @Input()
  names: any;

  //ControlValueAccessor
  onChange = (value: any) => { };
  onTouched = () => { };
  writeValue(obj: any): void {
    this.selectedValues = obj;
    this.updateSelection();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({ emitEvent: false });
    }
    else {
      this.formGroup.enable({ emitEvent: false });
    }
  }
  //

  constructor(private fb: UntypedFormBuilder) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateSelection();
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      items: this.fb.array(this.keys.map(i => 0))
    });

    this.formGroup.valueChanges.subscribe((val) => {
      this.onTouched();
    });

    this.updateSelection();
  }


  get itemCheckControls(): UntypedFormControl[] {
    if (this.formGroup) {
      return (<UntypedFormArray>this.formGroup.get('items')).controls as UntypedFormControl[];
    }

    return [];
  }


  itemName(item: any) {
    return this.names == null ? item : this.names[item] || item;
  }

  get keys(): any[] {
    return Object.keys(this.enum).reduce((arr, key) => {
      if (!arr.includes(key)) {
        arr.push(this.enum[key]);
      }
      return arr;
    }, [] as any);
  }

  selectionChanged(): void {
    const currentValues = this.formGroup.get('items')!.value as [];
    const newList: any = [];
    const list = this.keys;
    currentValues.forEach((val, index) => {
      if (val) {
        newList.push(this.enum[list[index]]);
      }
    });

    this.selectedValues = newList;
    this.onChange(newList);
  }

  updateSelection() {
    var selectedItems = this.selectedValues;

    const keyList = this.keys;
    this.itemCheckControls.forEach((element, controlIndex) => {
      const enumKey = keyList[controlIndex];
      const enumValue = this.enum[enumKey];

      if (!selectedItems) {
        element.setValue(false);
      } else {
        var selected = selectedItems.indexOf(enumKey) !== -1 ||
          selectedItems.indexOf(enumValue) !== -1;

        element.setValue(selected);
      }
    });

  }

}
