import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericItemApiResponse } from '../_interface/apiResponse';
import { Order } from '../_interface/mrd';

@Injectable({
  providedIn: 'root'
})
export class MrDService {
  constructor(public http: HttpClient) {
  }

  getOrder(orderID: number) {
    const url = environment.apiBase + `mrd/getOrder/${orderID}`;
    return this.http.post<GenericItemApiResponse<Order>>(url, null).pipe(
      map(val => {
        if (val.success) {
          return val.item;
        }
        throw val;
      })
    )
  }
}
