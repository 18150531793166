import { TableService } from './table.service';
import { TableService as CoreTableService } from './../../core/_services/table.service';

import { Table } from './../../core/_interface/table';
import { SessionService as CoreSession } from './../../core/_services/session.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, first, map, tap } from 'rxjs/operators';
import { GenericItemApiResponse } from 'src/app/core/_interface/apiResponse';
import { ICompany } from 'src/app/core/_interface/company';
import { DeliveryType, Order, OrderItem, OrderStatus } from 'src/app/core/_interface/order';
import { environment } from 'src/environments/environment';
import { CompanyMenu } from '../_interface/CompanyMenu';
import { TableDetails } from '../_interface/table';
import { AuthorizationService } from 'src/app/core/_services/authorization.service';
import { CompanyService } from 'src/app/core/_services/company.service';
import { WSService } from 'src/app/core/_services/ws.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  _tables?: BehaviorSubject<Table[]>;
  _userCompanies?: BehaviorSubject<ICompany[] | null>;

  activeTable = new BehaviorSubject<TableDetails | null>(null);
  activeOrder = new BehaviorSubject<Order | null>(null);

  _assignedTables?: BehaviorSubject<Table[]>;

  constructor(private http: HttpClient,
    private companyService: CompanyService,
    public coreSession: CoreSession,
    private coreTableService: CoreTableService,
    private ws: WSService,
    private logger: NGXLogger,
    private auth: AuthorizationService) {

    auth.currentUser.subscribe(
      (user) => {
        if (!user) {
          return;
        }

        this.loadUserRelatedData();
      }
    )

    coreSession.selectedCompany.subscribe(
      company => {
        if (company?.companyID) {
          // inicijalizujemo učitavanje stavki kompanije
          this.load();
        }
      }
    )

    this.attachToWSMessages();
  }

  getActiveTable() {
    return this.activeTable.value;
  }

  setActiveTable(table: TableDetails | null) {
    this.logger.debug("Setting active table", table);
    this.activeTable.next(table);
  }

  attachToWSMessages() {
    this.ws.observe("orderStatusChanged").subscribe(
      order => {
        var activeTable = this.activeTable.value;
        if (!activeTable) {
          return;
        }
        var orderID = order.data.orderID;
        var o = activeTable.orders.find(o => o.orderID == orderID);
        var newStatus = order.data.newStatus
        if (o) {
          this.logger.trace("Received WS orderStatusChanged - updating order status", o, newStatus);

          o.status = newStatus;
          o.group = order.data.group;

          switch (newStatus) {
            case (OrderStatus.Canceled):
            case (OrderStatus.Rejected):
              o.items.forEach(i => i.isCanceled = true);
              break;
            case (OrderStatus.Completed):
              var orderIndex = activeTable.orders.indexOf(o);
              activeTable.orders.splice(orderIndex, 1);

              break;

            default:
              break;
          }
        }

        activeTable.orders = [...activeTable.orders];

      });


    this.ws.observe("orderCreated").subscribe(
      order => {
        var activeTable = this.activeTable.value;
        if (!activeTable) {
          return;
        }

        if (order.data.order.status == OrderStatus.Rejected) {
          return;
        }

        if (order.data.order.tableID == activeTable.tableID) {
          var obj = new Order(order.data.order);
          this.logger.trace("Received WS orderCreated - adding order to active table", obj);
          activeTable.addOrder(obj);
        }
      }
    )
  }

  loadUserRelatedData() {
    if (this._userCompanies) {
      this._userCompanies.next(null);
      this.loadUserCompanyies();
    }
    /*
        if (this._assignedTables) {
          this._assignedTables.next([]);
          this.loadAssignedTables();
        }*/
  }

  load() {
    if (this._tables) {
      this.loadTables();
    }

    this.products.pipe(first()).subscribe()
    this.modifiers.pipe(first()).subscribe();
  }


  get tables() {
    if (!this._tables) {
      this._tables = new BehaviorSubject<Table[]>([]);
      this.loadTables();
    }
    return this._tables;
  }

  getTable(tableID: number) {
    return this.tables.value.find(t => t.tableID == tableID) || null;
  }

  loadTables() {
    if (this.selectedCompany.value?.companyID) {
      this.coreTableService.get(this.selectedCompany.value?.companyID).subscribe(
        val => {
          if (val) {
            this._tables?.next(val);
          }
        }
      )
    }
  }

  get userCompanies(): BehaviorSubject<ICompany[] | null> {
    if (!this._userCompanies) {
      this._userCompanies = new BehaviorSubject<ICompany[] | null>(null);
      this.loadUserCompanyies();
    }

    return this._userCompanies;
  }

  loadUserCompanyies() {
    this.companyService.getUserCompanies().subscribe({
      next: (val) => this._userCompanies?.next(val?.sort((a, b) => a.name.localeCompare(b.name)))
    });
  }

  get assignedTables() {
    if (!this._assignedTables) {
      this._assignedTables = new BehaviorSubject<Table[]>([]);
      this.loadAssignedTables();

      this.subscribeAndUpdateAssignedTables();
    }

    return this._assignedTables;
  }

  private subscribeAndUpdateAssignedTables() {
    this.assignedTables.pipe(
      debounceTime(2000)
    )
      .subscribe(
        val => {
          var tableIDs = val.map(t => t.tableID);

          this.coreTableService.assignTables(this.companyID, this.auth.currentUserID, tableIDs).pipe(
          ).subscribe(
            s => {
            }
          );
        }
      );
  }

  loadAssignedTables() {

    this.tables.subscribe(
      () => {
        if (!this.companyID) {
          return;
        }
        this.coreTableService.assignedTables(this.auth.currentUserID, this.companyID).subscribe(
          val => {
            if (val) {
              this._assignedTables?.next(val);
            }
          }
        )
      });
  }

  getData(lang: string, companyID: number): Observable<CompanyMenu | null> {


    const url = environment.apiBase + `menu/Get?companyID=${companyID}&lang=${lang}`;

    return this.http.get<GenericItemApiResponse<CompanyMenu>>(url).pipe(
      map(
        data => {
          if (data.success) {
            return data.item;
          }

          return null;
        }
      ),
      tap(
        data => {
          if (data) {
            data.settings.allowedDeliveryType = <any>data.settings.allowedDeliveryType?.map(i => DeliveryType[i]);
          }
        }
      )
    );
  }

  getProduct(productID: number) {
    return this.coreSession.getProduct(productID);
  }

  get groups() {
    return this.coreSession.groups;
  }

  get modifiers() {
    return this.coreSession.modifiers;
  }

  get products() {
    return this.coreSession.products;
  }

  get companyID() {
    return this.coreSession.companyID;
  }

  get selectedCompany() {
    return this.coreSession.selectedCompany;
  }


  get companySettings() {
    return this.coreSession.companySettings;
  }

}
