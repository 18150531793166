import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'preserveNewLine'
})
export class PreserveNewLinePipe implements PipeTransform {

  transform(value: string) {
    return value.replace(/\n/g, '<br />');
  }

}
