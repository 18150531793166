import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericItemApiResponse, GenericListApiResponse } from '../_interface/apiResponse';
import { Client } from '../_interface/client';
import { map, tap } from 'rxjs';
import { ICompany } from '../_interface/company';
import { PaymentType } from '../_interface/payment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {




  constructor(private http: HttpClient) { }


  search(copmanyID: number, name: string) {
    const url = environment.apiBase + `client/search?companyID=${copmanyID}&name=${name}`;

    return this.http.get<GenericListApiResponse<Client>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw response.errorDescription;
        })
      );
  }

  suppliers(copmanyID: number) {
    const url = environment.apiBase + `client/suppliers?companyID=${copmanyID}`;

    return this.http.get<GenericListApiResponse<Client>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw response.errorDescription;
        })
      );
  }
  get(copmanyID: number, clientID: number) {
    const url = environment.apiBase + `client/get?companyID=${copmanyID}&clientID=${clientID}`;

    return this.http.get<GenericItemApiResponse<Client>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          throw response.errorDescription;
        })
      );
  }

  getAll(companyID: number, clientID: (number | null)[]) {
    const url = environment.apiBase + `client/getAll`;
    var data = {
      companyID,
      clientIDs: clientID
    };

    return this.http.post<GenericListApiResponse<Client>>(url, data)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw response.errorDescription;
        })
      );
  }

  save(client: Client) {
    const url = environment.apiBase + `client/save`;

    return this.http.post<GenericItemApiResponse<Client>>(url, client)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          throw response.errorDescription;
        }),
      );
  }

}
