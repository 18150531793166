import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, ViewContainerRef, inject } from '@angular/core';
import { IQRCodeScaner } from '../_interface/IQRCodeScanner';
import { QRCodeScannerComponent } from '../qrcode-scanner/qrcode-scanner.component';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebQRCodeScanerService implements IQRCodeScaner {
  ref: MatDialogRef<QRCodeScannerComponent> | null = null;
  dlg: MatDialog;
  private result$?: Subject<string>;

  constructor(injector: Injector
  ) {
    this.dlg = injector.get(MatDialog);
  }


  enable(): void {
    if (this.ref) {
      this.ref.componentInstance.enabled = true;
    }
  }

  disable(): void {
    if (this.ref) {
      this.ref.componentInstance.enabled = false;
    }
  }

  get isEnabled(): boolean {
    return this.ref?.componentInstance.enabled ?? false;
  };

  scan() {
    return this.result$;
  }

  stop(): void {
    this.result$?.complete();
    this.ref?.close();
  }

  start() {
    this.result$ = new Subject<string>();

    this.ref = this.dlg.open(QRCodeScannerComponent);
    this.ref.componentInstance.scanCompleted.subscribe(
      {
        next: (result: string) => {
          this.result$!.next(result);
        }
        ,
        error: (error: any) => {
          this.result$!.error(error);
        }
      });

    return this.result$;
  }
}
