import { User } from "../_interface/user";

export const mapJsonToUsers = (jsonItems: User[] | null): User[] => {
  const list: User[] = [];
  jsonItems?.forEach(u => {
    const newUser = {} as User;
    Object.assign(newUser, u);
    list.push(newUser);
  });
  return list;
};


export const toLocalDateJsonString = (date: Date) => {
  if (!date) {
    return null;
  }

  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T' +
    ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
};

export const parseToLocalDate = (date: string | Date | null) => {
  if (!date) {
    return null;
  }

  // var s = '2011-06-21T14:27:28.593Z';
  const a = date.toString().split(/[^0-9]/).map(i => parseInt(i, 10));

  const d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  return d;
};
