<img src="../../assets/icons/icon-128x128.png" />
<ng-container *ngIf="auth.currentUser | async; else notLogged">
  <div>
    <button [routerLink]="['/app']" mat-raised-button color="primary">
      App
    </button>
    <button [routerLink]="['/logout']" mat-raised-button color="secondary">
      Logout
    </button>
  </div>
</ng-container>

<ng-template #notLogged>
  <app-login [companyID]="companyID"></app-login>
</ng-template>
