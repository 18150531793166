import { Pipe, PipeTransform } from '@angular/core';
import { GroupCategory } from 'src/app/core/_interface/product';


@Pipe({ name: 'toGroupIconClass' })
export class ToGroupIconClassPipe implements PipeTransform {

  transform(icon: GroupCategory): string {
    switch (icon || 0) {
      case (GroupCategory.Default):
        return 'icon-default';
      case (GroupCategory.Drink):
        return 'icon-drink';
      case (GroupCategory.Food):
        return 'icon-food';
      case (GroupCategory.Berbecue):
        return 'icon-barbecue';
      case (GroupCategory.Sandwich):
        return 'icon-sandwich';
      case (GroupCategory.Alcohol):
        return 'icon-alcohol';
      case (GroupCategory.NonAlcohol):
        return 'icon-nonAlcohol';
      case (GroupCategory.Cigarette):
        return 'icon-cigarette';
      case (GroupCategory.KidsMenu):
        return 'icon-kidsMenu';
      case (GroupCategory.Desert):
        return 'icon-desert';
      case (GroupCategory.Brekfast):
        return 'icon-brekfast';
      case (GroupCategory.Coctail):
        return 'icon-coctail';
      case (GroupCategory.Startup):
        return 'icon-startup';
      case (GroupCategory.Sallate):
        return 'icon-sallate';
      case (GroupCategory.Pasta):
        return 'icon-pasta';
      case (GroupCategory.Beer):
        return 'icon-beer';
      case (GroupCategory.Fish):
        return 'icon-fish';
      case (GroupCategory.Cheese):
        return 'icon-cheese';
      case (GroupCategory.Soup):
        return 'icon-soup';
      case (GroupCategory.HotDrinks):
        return 'icon-hotDrinks';
      case (GroupCategory.Vine):
        return 'icon-vine';

    }

    return '';
  }

}
