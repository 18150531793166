import { AfterViewInit, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Translation } from './../_interface/tranlsation';
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormArray } from '@angular/forms';
import { Subject, first } from 'rxjs';
import { availableLanguages } from '../_helpers/config';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { SessionService } from '../_services/session.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnDestroy, AfterViewInit {
  availableTranslations = availableLanguages;

  onDestory = new Subject<void>();
  existingTags: string[] = [];

  @ViewChild("chipInput") chipInput!: ElementRef<HTMLInputElement>;
  @ViewChild("selectedTranslation") selectedTranslation!: MatSelect;

  @Input() translations?: UntypedFormArray | null;

  constructor(private fb: UntypedFormBuilder, private session: SessionService) {


  }
  ngAfterViewInit(): void {
    this.languageChanged(this.selectedTranslation.value);
  }

  languageChanged(language: string) {

    this.session.products.pipe(
      first()
    ).subscribe(products => {
      this.existingTags = products.reduce((acc, p) => {
        var translation = p.translations.find(t => t.language == language);
        if (translation) {
          translation.tags.forEach(t => {
            if (acc.indexOf(t) == -1) {
              acc.push(t);
            }
          }
          );
        }
        return acc;
      }, [] as string[]);
    }
    )
  }

  ngOnDestroy(): void {
    this.onDestory.next();
    this.onDestory.complete();
  }

  getTranslation(selected: any): UntypedFormGroup {

    let el = this.translations?.controls?.find(c => c.value.language == selected) as UntypedFormGroup;
    if (!el) {
      el = this.fb.group({
        'language': selected,
        'name': '',
        'description': '',
        'tags': this.fb.array([])
      });
      this.translations?.push(el);
    }

    return el;

  }


  removeTag(tag: string) {
    var tags = this.getTranslation(this.selectedTranslation.value).get('tags') as any;

    const index = tags?.value.findIndex((i: string) => i === tag);
    if (index !== undefined && index > -1) {
      tags?.removeAt(index);
    }
  }

  addTag(tag: MatAutocompleteSelectedEvent | string) {
    var valueToAdd = "";
    if (typeof (tag) == 'string') {
      valueToAdd = tag;
    }
    else {
      valueToAdd = tag.option.value;
    }

    if (valueToAdd.trim() == "") {
      return;
    }


    var tags = this.getTranslation(this.selectedTranslation.value).get('tags') as any;

    tags?.push(this.fb.control(valueToAdd));
    this.chipInput.nativeElement.value = '';
  }
  setProperCase(fieldName: string) {
    var oldVal = this.getTranslation(this.selectedTranslation.value).get(fieldName)?.value;
    var newVal = oldVal.charAt(0).toUpperCase() + oldVal.slice(1).toLowerCase();

    this.getTranslation(this.selectedTranslation.value).get(fieldName)?.setValue(newVal );
    this.getTranslation(this.selectedTranslation.value).markAsDirty();
  }
}
