import { Pipe, PipeTransform } from '@angular/core';
import { Table } from '../_interface/table';

@Pipe({
  name: 'tableGroups'
})
export class TableGroupsPipe implements PipeTransform {

  transform(tables?: Table[] | null) {
    if (!tables) {
      return [];
    }

    return tables.reduce<string[]>((prev, curr) => {
      if (prev.indexOf(curr.group) == -1 && curr.group) {
        prev.push(curr.group);
      }
      return prev;
    }, [])
  }

}
