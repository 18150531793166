import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[clickStopPropagation]"
})
export class ClickStopPropagationDirective {
  @Input() clickStopPropagation = true;

  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    if (this.clickStopPropagation) {
      event.stopPropagation();
    }
  }
}
