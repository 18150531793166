import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[transformRotate]'
})
export class TransformRotateDirective {
  private _transformRotate: number = 0;
  public get transformRotate(): number {
    return this._transformRotate;
  }

  @Input()
  public set transformRotate(value: number) {
    this._transformRotate = value;
    this.setRotation();
  }

  constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.setRotation();
  }

  setRotation() {
     // Dohvati trenutni transform stil
    let currentTransform = this.elementRef.nativeElement.style.transform;

    // Regex koji prepoznaje rotate transformaciju
    const rotateRegex = /rotate\([^)]+\)/;

    var newRotate = `rotate(${this.transformRotate}deg)`;
    // Ako postoji rotate, zamenjujemo ga
    if (rotateRegex.test(currentTransform)) {
      // Zamenjujemo postojeći rotate novim
      currentTransform = currentTransform.replace(rotateRegex, newRotate);
    } else {
      // Ako rotate ne postoji, samo dodajemo novi
      currentTransform += ' ' + newRotate;
    }

    this.renderer.setStyle(this.elementRef.nativeElement, "transform", currentTransform);

  }
}
