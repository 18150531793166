<app-dialog-title>Pretraga kupaca</app-dialog-title>
<mat-dialog-content>
  <mat-form-field class="example-full-width">
    <mat-label>Naziv</mat-label>
    <input
      type="text"
      [formControl]="nameControl"
      autocomplete="off"
      matInput
      #input
    />
  </mat-form-field>
  <mat-progress-spinner
    *ngIf="loading"
    mode="indeterminate"
  ></mat-progress-spinner>
  <ng-container *ngIf="clients$ | async as clients">
    <ng-container *ngIf="nameControl.value">
      <ng-container *ngIf="clients.length > 0; else notFound">
        <div
          class="customer"
          *ngFor="let client of clients"
          (click)="selected = client"
          [class.selected]="selected === client"
        >
          {{ client.name }}
          <div class="detail">
            PIB: <span class="phoneNumber"> {{ client.tin }}</span>
          </div>
          <div class="detail address">Adresa: {{ client.address }}</div>
        </div>
      </ng-container>

      <ng-template #notFound>
        <div>Nije pronađen ni jedan klijent koji odgovara traženom podatku</div>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="createNewClient()">Novi kupac</button>
  <button mat-stroked-button matDialogClose="">Otkaži</button>
  <button
    mat-raised-button
    color="primary"
    (click)="ok()"
    [disabled]="!selected"
  >
    OK
  </button>
</mat-dialog-actions>
