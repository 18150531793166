import { SHELL_APP_CONFIG } from '../_interface/shellAppConfig';
import { OrderStatus } from './../_interface/order';
import { Order } from 'src/app/core/_interface/order';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject, Subscription, interval } from 'rxjs';
import { map, switchMap, retryWhen, delay, skip } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { Waiter } from '../_interface/user';
import { DeviceService } from './device.service';
import { NGXLogger } from 'ngx-logger';

export interface WebsocketData {
  type: string;
  data: any;
}

export interface WebsocketResult {
  success?: boolean;
  data?: any;
}

export interface WebsocketResultOrderData {
  action: string;
  order: Order
}

export interface WebsocketResultOrderStatus {
  newStatus: OrderStatus;
  dueDate: Date,
  orderID: number;
  waiter: Waiter;
}

export interface WebsocketResultOrderPaid {
  orderID: number;
}

@Injectable({
  providedIn: 'root'
})
export class WSService {
  private connection: WebSocketSubject<WebsocketData> | null = null;
  connected = new BehaviorSubject<boolean>(false);
  socketID = "";
  connectRetryCount = new BehaviorSubject<number>(0);
  authenticated = new BehaviorSubject<boolean>(false);

  connectionPending = false;

  private subscription?: Subscription;

  private RETRY_SECONDS = 5;

  private subscriptions: { [key: string]: Subject<WebsocketResult> } = {};

  constructor(private ds: DeviceService,
    private logger: NGXLogger
  ) {
    this.runSleepModeDetection();

    this.connected
      .pipe(
        skip(1)
      )
      .subscribe(
        connected => {
          if (!connected) {
            this.authenticated.next(false);
          } else {
            this.sendVersion();
            this.sendDeviceID();
          }
        }
      )

    this.observe("auth").subscribe(
      msg => {
        if (msg.success) {
          this.authenticated.next(true);
        }
      }
    )

    this.observe("connected").subscribe(
      msg => {
        this.socketID = msg.data;
        this.logger.info('connected to WS - socketID: ' + msg.data);
      }
    )

    this.observe("reload").subscribe(() => location.reload());

  }


  sendVersion() {
    var data: WebsocketData = {
      type: 'version',
      data: {
        deviceID: this.ds.deviceID,
        appVersion: environment.appVersion,
        nativeAppVersion: SHELL_APP_CONFIG.appVersion,
        cardPaymentProvider: SHELL_APP_CONFIG.cardPaymentProvider,
        hasPrinter: SHELL_APP_CONFIG.hasPrinter,
        nativeApp: (environment as any).nativeApp
      }
    }

    this.send(data);
  }

  sendDeviceID() {
    var data: WebsocketData = {
      type: 'udid',
      data: this.ds.deviceID
    }

    this.send(data);
  }

  private connect() {
    if (this.connectionPending) {
      return;
    }
    this.connectionPending = true;

    const apiAddress = environment.apiBase + 'ws';
    var ws = of(apiAddress).pipe(
      // https becomes wws, http becomes ws
      map(apiUrl => apiUrl.replace(/^http/, 'ws')),
      switchMap(wsUrl => {
        //  console.log('connect to ' + wsUrl);
        this.connection = new WebSocketSubject({
          url: wsUrl,
          openObserver: {
            next: (val: any) => {
              this.connectRetryCount.next(0);
              this.connected.next(true);
            }
          },
          closeObserver: {
            next: (val: any) => {

              if (this.socketID) {
                this.logger.info('disconnected from WS - socketID: ' + this.socketID);
                this.socketID = "";
              }

              this.connectRetryCount.next(this.connectRetryCount.value + 1);
              this.connected.next(false);
            }
          }
        });

        return this.connection;

      }),
      retryWhen((errors) => {
        return errors.pipe(delay(this.RETRY_SECONDS * 1000));
      })
    );

    this.subscription = ws.subscribe({
      next: data => this.onMessageReceived(data)
    });


  }

  observe(messageType: 'admin_orderCreated' | 'orderCreated' |
    'log' | 'event' |
    'sessionExpired' | 'orderStatusChanged' | 'table.summary' |
    'table' | 'socketDisonnected' |
    'auth' | 'socketMetadataChange' |
    'loginCompleted' |
    'companyIP' | 'orders' | 'companySettingsChanged' |
    "bannerChanged" | 'phoneCall' | 'orderPaid' | 'orderItemStatusChanged' | 'concurrentLimitReached' | 'sendNativeLog' |
    'checkVersion' | 'reload' | 'menuChanged' | 'config' | 'connected'
  ): Subject<WebsocketResult> {
    this.connect();

    if (!this.subscriptions[messageType]) {
      this.subscriptions[messageType] = new Subject<WebsocketResult>();
    }

    return this.subscriptions[messageType];
  }


  onMessageReceived(data: any) {
    if (this.subscriptions[data.type]) {
      var result: WebsocketResult = {
        success: data.success,
        data: data.data
      };

      (this.subscriptions[data.type]).next(result);
    }
  }


  send(data: WebsocketData) {
    if (this.connected.value) {
      this.logger.debug('send WS type: ', data.type);
      this.connection!.next(data);
    }
  }

  runSleepModeDetection() {
    var lastTime = (new Date()).getTime();
    interval(2000).subscribe(() => {
      var currentTime = (new Date()).getTime();
      if (currentTime > (lastTime + 2000 * 2)) {  // ignore small delays
        this.connectRetryCount.next(0);
      }
      lastTime = currentTime;
    });
  }

}
