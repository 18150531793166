import { SessionService } from '../_services/session.service';
import { Pipe, PipeTransform } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';


@Pipe({ name: 'toProducts' })
export class ToProductsPipe implements PipeTransform {
  constructor(private session: SessionService) {


  }
  transform(productIDs: number[]) {
    return this.session.products.pipe(
      map(
        products => {
          return products.filter(p => productIDs.some(pid => pid == p.productID)).sort((a, b) => {
            return productIDs.indexOf(a.productID) - productIDs.indexOf(b.productID);
          });
        }
      )
    )

  }
}
