import { WSService } from 'src/app/core/_services/ws.service';
import { OrderService } from 'src/app/core/_services/order.service';
import { Inject, Injectable } from '@angular/core';
import { TableService } from './table.service';
import { INotificationProvider, NOTIFICATION_PROVIDER } from '../_interface/notificationProvider';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    tableService: TableService,
    ws: WSService,
    @Inject(NOTIFICATION_PROVIDER) private notificationProvider: INotificationProvider,
    orderService: OrderService) {

    notificationProvider.cancelWaiterCall((tableID: number) => {
      tableService.cancelCallWaiter(tableID).subscribe();
    });

    notificationProvider.setOrderStatus((orderID: number, orderStatus: number) => {
      orderService.setStatus(orderID, orderStatus).subscribe();
    });

    ws.observe("table").pipe(
    ).subscribe(
      received => {
        var msg = received.data;

        if (msg.action == "waiterCalled") {
          var tableID = msg.tableID;

          if (!msg.called) {
            this.notificationProvider.removeNotification("callWaiter_" + tableID);
          }
        }
      }
    );

    ws.observe("orderStatusChanged").pipe(
    ).subscribe(
      received => {
        var msg = received.data;

        var orderID = msg.orderID;

        if (!msg.called) {
          this.notificationProvider.removeNotification("orderCreated_" + orderID);
        }
      }
    );


  }

  getSubscription() {
    return this.notificationProvider.getToken();
  }

}
