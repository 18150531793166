export const availableLanguages = [
  {
    code: 'sr',
    name: 'Srpski'
  },
  {
    code: 'en',
    name: 'Engleski'
  },
  {
    code: 'ru',
    name: 'Ruski'
  },
  {
    code: 'it',
    name: 'Italiano'
  },
  {
    code: 'ro',
    name: 'Română'
  }
];
