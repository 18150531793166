
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from '../_services/customer.service';
import { Customer } from '../_interface/order';
import { SessionService } from '../_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { PriceListService } from '../_services/price-list.service';
import { environment } from 'src/environments/environment';
import { Permissions } from 'src/app/core/_interface/permissions';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {
  formGroup: FormGroup;
  Permissions = Permissions;
  priceListNames: string[] = [];

  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Customer,
    private dialogRef: MatDialogRef<CustomerEditComponent>,
    public session: SessionService,
    private toast: ToastrService,
    public priceService: PriceListService,
    private customerServcie: CustomerService
  ) {
    if (!this.data) {
      this.data = {
        customerID: 0,
        name: "",
        address: "",
        phoneNumber: "",
        note: "",
        isBlocked: false,
        orderCount: 0,
        plus: 0,
        minus: 0,
        overallPlus: 0,
        overallMinus: 0,
        discount: 0,
        priceListName: "",
      };
    }

    this.formGroup = this.fb.group(this.data);


    priceService.getListNames(session.companyID)
      .subscribe(names => {
        this.priceListNames = names;
      });

  }

  ngOnInit(): void {

  }

  save() {
    if (this.formGroup.valid) {
      this.customerServcie.saveCustomer(this.session.companyID, this.formGroup.value as Customer).subscribe({
        next: success => {
          this.data = this.formGroup.value;
          this.toast.success("Podaci sačuvani");

          Object.assign(this.data, this.formGroup.value);
          this.dialogRef.close(this.data);
        },
        error: e => {
          this.toast.error("Greška pri čuvanju podataka");
        }
      }
      )
    }
  }
}
