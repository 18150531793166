import { Order } from 'src/app/core/_interface/order';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SessionService } from '../_services/session.service';

@Component({
  selector: 'app-select-preparation-time',
  templateUrl: './select-preparation-time.component.html',
  styleUrls: ['./select-preparation-time.component.scss']
})
export class SelectPreparationTimeComponent implements OnInit {

  @Input() order?: Order;

  constructor(private session: SessionService) { }

  get defaultPreparationTime() {
    if (!this.order) {
      return 0
    }

    var maxTime = 0;
    this.order.items.forEach(item => {
      var product = this.session.getProduct(item.productID);

      var result = 0;
      result += product?.preparationTime || 0;

      item.modifiers.forEach(modifier => {
        product = this.session.getProduct(modifier.productID);
        result += product?.preparationTime || 0;
      });

      maxTime = Math.max(maxTime, result);
    }, 0);

    return maxTime;
  }

  ngOnInit(): void {
  }

}
