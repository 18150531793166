import { Pipe, type PipeTransform } from '@angular/core';
import { ConnectionMetadata } from '../_interface/ConnectionMetadata';

@Pipe({
  name: 'appFilterByCompany',
  pure: false
})
export class FilterWSConnectionByCompanyPipe implements PipeTransform {

  transform(value: ConnectionMetadata[] | null | undefined, companyID: number | undefined | null) {
    if (!companyID || !value) {
      return value;
    }
    return value.filter(x => x.companyID == companyID);
  }

}
