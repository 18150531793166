import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numericalSort'
})
export class NumericalSortPipe implements PipeTransform {

  transform(data: any[]  | null, sortField?: string, direction?: 'asc' | 'desc'  ) {
    if (!data) {
      return [];
    }

    direction = direction || 'asc'; // set default sort direction to ascending

    var sorted = data.sort((a, b) => {

      const isAsc = direction === 'asc'; // detect sort direction
      if (sortField) {

        return a[sortField].localeCompare(b[sortField], undefined, { numeric: true }) * (isAsc ? 1 : -1);
      }
      else {
        return a.localeCompare(b, undefined, { numeric: true }) * (isAsc ? 1 : -1);
      }
    }
    );

    return sorted;
  }

}
