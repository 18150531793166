import { Subject } from 'rxjs';
import { InjectionToken } from "@angular/core";

export const QRCODE_SCANER = new InjectionToken<IQRCodeScaner>('qr code scaner');

export interface IQRCodeScaner {
  enable(): void;
  disable(): void;
  isEnabled: boolean;
  start(): Subject<string>;
  stop(): void;
}
