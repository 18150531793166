<span class="companyName">{{ pinConfig.companyName }}</span>
<span class="pin">
  <ng-container *ngFor="let item of pin | splitString">•</ng-container>
</span>
<div class="buttons">
  <button
    mat-stroked-button
    color="primary"
    *ngFor="let button of [].constructor(9); let i = index"
    (click)="addPin(i + 1)"
  >
    {{ i + 1 }}
  </button>
  <button mat-stroked-button color="primary" (click)="delete()">
    <mat-icon>backspace</mat-icon>
  </button>
  <button mat-stroked-button color="primary" (click)="addPin(0)">0</button>
  <button
    mat-raised-button
    color="primary"
    (click)="login()"
    [disabled]="isBusy"
    [IsBusy]="isBusy"
  >
    <mat-icon>login</mat-icon>
  </button>
</div>
<button
  class="logout"
  mat-stroked-button
  color="primary"
  [disabled]="!!pin"
  [swal]="logoutConfirmation"
  (confirm)="logout()"
>
  Odjavi se
</button>
<swal
  #logoutConfirmation
  title="Odjava"
  text="Da li ste sigurni da želite da se odjavite? Prijava putem PIN-a će biti onemogućena."
  type="warning"
  icon="question"
  [showCancelButton]="true"
></swal>
