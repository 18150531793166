import { repeat, timeout, delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BeepService {
  private _autoPlay = false;
  private _autoPlayAudio?: HTMLAudioElement;
  _player = new Audio();

  constructor() { }

  playInformation() {
    if (!this._player.ended) {
      this._player.pause();
    }
    this._player.src = "/assets/beep-22.mp3";
    this._player.currentTime = 0;
    this._player.play();
  }

  playWarning() {
    if (!this._player.ended) {
      this._player.pause();
    }

    this._player.src = "/assets/beep-warning-6387.mp3";
    this._player.play();
  }

  startAutoRepeatWarning() {
    if (this._autoPlay) {
      return;
    }
    this._autoPlay = true;

    this._autoPlayAudio = new Audio();
    this._autoPlayAudio.src = "/assets/instant_order_1.mp3";
    this._autoPlayAudio.loop = true;
    this._autoPlayAudio.play();

  }


  stopAutoRepeat() {
    this._autoPlay = false;
    if (this._autoPlayAudio) {
      this._autoPlayAudio.pause();
    }
  }
}
