import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/core/_interface/client';
import { ClientEditComponent } from '../client-edit/client-edit.component';

@Component({
  selector: 'app-client-edit-dialog',
  templateUrl: './client-edit-dialog.component.html',
  styleUrls: ['./client-edit-dialog.component.scss']
})
export class ClientEditDialogComponent {
  @ViewChild(ClientEditComponent) clientEdit?: ClientEditComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public client: Client,
    private toast: ToastrService,
    private dialogRef: MatDialogRef<ClientEditDialogComponent>,) {

    if (!this.client) {
      this.client = {
        clientID: 0,
        name: "",
        address: "",
        note: "",
        city: '',
        companyID: 0,
        eMail: '',
        identificationNo: '',
        referenceID: '',
        tin: '',
        printInvoiceCopy: null,
        allowedPaymentTypes: [],
        isSupplier: false
      };
    }
  }

  save() {
    this.clientEdit?.save()?.then((success) => {
      if (success) {
        this.toast.success("Podaci snimljeni");
        this.dialogRef.close(this.client);
      }
    });
  }

}
