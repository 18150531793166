import { AuthorizationService } from 'src/app/core/_services/authorization.service';
import { SessionService } from '../_services/session.service';
import { OrderStatus } from './../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';
import { Permissions } from '../_interface/permissions';


@Pipe({ name: 'hasPermission' })
export class HasPermissionPipe implements PipeTransform {
  constructor(private session: SessionService, private auth: AuthorizationService) {

  }
  transform(permissions: Permissions[] | null, required: Permissions): boolean {
    if (this.session.selectedCompany.value?.ownerID == this.auth.currentUserID) {
      return true;
    }

    return permissions?.some(p => p == required) || false;
  }
}
