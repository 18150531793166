<app-dialog-title title="Detalji o korisniku"></app-dialog-title>
<mat-dialog-content>
  <app-group-box title="Korisnik">
    <app-property-item
      name="Naziv"
      [value]="customer?.name || 'Korisnik ' + customer?.customerID"
    ></app-property-item>
    <app-property-item
      name="Adresa"
      [value]="customer?.address"
    ></app-property-item>
    <app-property-item
      name="Broj telefona"
      [value]="customer?.phoneNumber"
    ></app-property-item>
    <app-property-item
      name="Napomena"
      [value]="customer?.note"
    ></app-property-item>
    <app-property-item
      *ngIf="customer?.priceListName"
      name="Cenovnik"
      [value]="customer?.priceListName"
    ></app-property-item>
    <app-property-item name="Rejting">
      <app-user-rating [rating]="customer"></app-user-rating>
    </app-property-item>
  </app-group-box>
  <app-group-box title="Istorija porudžbina">
    <div class="orders">
      <app-group-box *ngFor="let order of orders">
        <div class="order" (click)="showOrder(order)">
          <div class="title">
            <span>
              {{ order.created | date : "dd.MM.yyyy HH:mm" }}
              <span class="orderNo">#{{ order.orderNo }}</span>
            </span>

            <span>
              {{ order.status | orderStatus | translate}}
            </span>
          </div>
          <div class="items">
            <div class="item" *ngFor="let item of order.items">
              <span class="quantity">{{ item.quantity }}x </span>
              {{ item.name }}
            </div>
          </div>
          <button
            mat-stroked-button
            color="primary"
            class="repeatOrder"
            title="ponovi porudžbinu"
            (click)="repeatOrder(order, $event)"
          >
            Ponovi <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </app-group-box>
    </div>
  </app-group-box>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="editCustomerDetails()">Promena podataka</button>
  <button mat-raised-button matDialogClose="" color="primary">Zatvori</button>
</mat-dialog-actions>
