import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PickupAndDeliveryRequest } from '../_interface/task';
import { Order } from '../_interface/order';

@Component({
  selector: 'app-create-pickup-and-delivery-task',
  templateUrl: './create-pickup-and-delivery-task.component.html',
  styleUrls: ['./create-pickup-and-delivery-task.component.scss']
})
export class CreatePickupAndDeliveryTaskComponent implements OnInit {
  formGroup: FormGroup;
  @Input() order?: Order;

  constructor(fb: FormBuilder) {
    this.formGroup = fb.group({
      customer_userName: '',
      customer_phone: '',
      customer_address: ''

    });
  }

  ngOnInit(): void {
    if (this.order) {
      this.formGroup.patchValue(this.order);
    }
  }

  save() {
    /*    var task: PickupAndDeliveryRequest = {

    };
*/
  }

}
