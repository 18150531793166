import { Pipe, PipeTransform } from "@angular/core";
import { ISortable } from "../_interface/product";

export enum SORT {
  General = 0,
  UserApp = 1,
  WaiterApp = 2
}

@Pipe({ name: "sort" })
export class SortPipe implements PipeTransform {
  transform<T extends ISortable>(products: T[] | null, sortType = SORT.UserApp): T[] | null {
    if (products) {
      const sorted = products.sort((a, b) => {
        if (sortType == SORT.General) {
          return (a.sortOrder || 0) - (b.sortOrder || 0)
        }
        else if (sortType == SORT.UserApp) {
          return (a.userSortOrder || 0) - (b.userSortOrder || 0)
        }
        else {
          return (a.waiterSortOrder || 0) - (b.waiterSortOrder || 0)
        }
      });

      return sorted
    }
    return null;
  }
}
