import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAgentComponent } from './select-agent/select-agent.component';
import { CreatePickupAndDeliveryTaskComponent } from './create-pickup-and-delivery-task/create-pickup-and-delivery-task.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {  MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [
    SelectAgentComponent,
    CreatePickupAndDeliveryTaskComponent
  ],
  exports: [
    SelectAgentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule

  ]
})
export class TookanModule { }
