import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface AppSettings {
  currency: string;
  defaultLanguage: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  settings?: BehaviorSubject<AppSettings>;

  constructor(private http: HttpClient) { }


  get current() {
    if (!this.settings) {
      this.settings = new BehaviorSubject<AppSettings>({} as AppSettings);

      const apiAddress = environment.apiBase + 'app/public';

      this.http.get<AppSettings>(apiAddress).subscribe(
        settings => {
          this.settings?.next(settings);
        }
      );
    }
    return this.settings;
  }
}
