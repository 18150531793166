import { LogoutComponent } from './core/logout/logout.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent,
  },
  { path: 'login/:token', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'app', loadChildren: () => import('./waiter/waiter.module').then(m => m.WaiterModule)
  },
  {
    path: ':companyid', component: HomeComponent,
  },
  { path: 'login', component: LoginComponent },
  {
    path: '**', redirectTo: '',

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
