import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private logger: NGXLogger) {
    super();
  }

  override handleError(error: any) {
    // Here you can provide whatever logging you want
    this.logger.error("web error", error);
    super.handleError(error);
  }

}
