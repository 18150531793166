import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

export enum TableType {
  Round = 1,
  Rectangle = 2,
  Square = 3,
  RectangleVertical = 4,
  Square45 = 5
}

@Component({
  selector: '[app-layout-item]',
  templateUrl: './layoutItem.component.html',
  styleUrls: ['./layoutItem.component.scss']
})
export class LayoutItemComponent {
  TableType = TableType;
  reservationWidth = 12;
  @Input() fillColor = "lightgray"
  @Input() itemTextTemplate: TemplateRef<any> | undefined;

  @ViewChild("item") item?: ElementRef<SVGElement>;

  @Input() data: any;

  get width() {
    var width = this.item?.nativeElement.getBoundingClientRect().width || 0;
    //    console.log(this.name, width);
    return this.item?.nativeElement.getBoundingClientRect().width || 0;
  }

  get height() {
    return this.item?.nativeElement.getBoundingClientRect().height || 0;
  }
  constructor() { }


}
