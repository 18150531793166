import { Injectable } from '@angular/core';
import { IQRCodeScaner } from '../_interface/IQRCodeScanner';
import { CrossAppMessagingService } from './crossApp-messaging.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NativeQRCodeScanerService implements IQRCodeScaner {

  private _isEnabled = true;
  private result$?: Subject<string>;

  constructor(private crossAppMessaging: CrossAppMessagingService) { }

  enable(): void {
    this._isEnabled = true;
  }

  disable(): void {
    this._isEnabled = false;
  }

  get isEnabled(): boolean {
    return this._isEnabled;
  }

  stop(): void {
    var msg = {
      action: "qrScaner_close"
    };
    this.result$?.complete();
    this.crossAppMessaging.sendRawMessage(msg, false);
  }

  start() {
    this.result$ = new Subject<string>();
    var msg = {
      action: "qrScaner_scan"
    };

    this.crossAppMessaging.sendRawMessage(msg, false);

    this.crossAppMessaging.onMessage("qrScaner_scaned").subscribe((msg) => {
      var code = this.extractCode(msg);

      this.result$?.next(code);
    });

    return this.result$;
  }

  extractCode(data: string): string {
    var start = data.lastIndexOf('/') + 1;
    var end: number | undefined = data.lastIndexOf("?");
    if (end == -1) {
      end = undefined;
    }
    return data.substring(start, end);
  }

}
