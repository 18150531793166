import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from "@angular/core";
import { map, switchMap } from 'rxjs/operators';
import { SessionService } from '../_services/session.service';
import { Product } from '../_interface/product';

@Pipe({ name: "getProductByID" })
export class GetProductByIDPipe implements PipeTransform {
  constructor(private session: SessionService) {

  }

  transform(id: number | undefined): Observable<Product | undefined> {
    if (id) {
      return this.session.products.pipe(
        switchMap(val => {
          if (val.length == 0) {
            return of(undefined);
          }

          var product;
          if (val) {
            product = val.find(g => g.productID === id) || null;
          }
          if (product) {
            return of(product);
          } else {
            return this.session.getSingleProduct(id)
          }
        })
      );
    }
    return of(undefined);
  }
}
