import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Invoice, InvoiceItem, InvoiceItemType } from '../_interface/Invoice';
import { ApiResponse, GenericItemApiResponse, GenericListApiResponse } from '../_interface/apiResponse';
import { accessDeniedDescriptions } from '../_helpers/accessDeniedDescriptions';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {


  constructor(private http: HttpClient, private dialog: MatDialog, private toast: ToastrService) { }

  getLastPaid() {
    const apiAddress = environment.apiBase + 'invoice/getLastPaid?count=10';

    return this.http.get<GenericListApiResponse<Invoice>>(apiAddress, {
      headers: { accessDeniedDescription: accessDeniedDescriptions.getInvoices }
    }).pipe(
      map(
        (data) => {
          if (data.success) {
            return this.convertToInvoiceObject(data.items);
          }
          return null;
        })
    );

  }

  get(companyID?: number, referenceNo?: string) {
    const apiAddress = environment.apiBase + 'invoice/get';
    const postData = {
      'companyID': companyID,
      'referenceNo': referenceNo
    };

    return this.http.post<GenericListApiResponse<Invoice>>(apiAddress, postData, {
      headers: { accessDeniedDescription: accessDeniedDescriptions.getInvoices }
    }).pipe(
      map(
        (data) => {
          if (data.success) {
            return this.convertToInvoiceObject(data.items);
          }
          return null;
        })
    );
  }

  convertToInvoiceObject(items: Invoice[]): Invoice[] {
    const list: Invoice[] = [];
    items.forEach(element => {
      const i = new Invoice();
      Object.assign(i, element);

      if (i.paidAt) {
        i.paidAt = new Date(i.paidAt);
      }
      const itemList: InvoiceItem[] = [];
      i.items?.forEach(item => {
        const iItem = new InvoiceItem();
        Object.assign(iItem, item);
        itemList.push(iItem);
      });

      i.items = itemList;
      list.push(i);
    });

    return list;
  }

  markAsPaid(invoiceID: number): Observable<boolean> {
    const apiAddress = environment.apiBase + 'invoice/markAsPaid?invoiceID=' + invoiceID;

    return this.http.post<ApiResponse>(apiAddress, null).pipe(
      map(
        (data) => data.success)
    );
  }

  recreateReceipt(invoiceID: number): Observable<boolean> {
    const apiAddress = environment.apiBase + 'invoice/RecreateReceipt?invoiceID=' + invoiceID;

    return this.http.post<ApiResponse>(apiAddress, null).pipe(
      map(
        (data) => data.success)
    );
  }

  createOrder(companyID: number, amount: number, items: InvoiceItem[]) {
    const apiAddress = environment.apiBase + `invoice/createOrder`;
    const postData = {
      companyID: companyID,
      items: items,
      amount: amount
    };

    const response = this.http.post<GenericItemApiResponse<Invoice>>(apiAddress, postData);

    return response;
  }

  download(invoiceID: number) {
    const apiAddress = environment.apiBase + 'invoice/download?invoiceID=' + invoiceID;

    this.http.post(apiAddress, null,
      {
        responseType: 'blob',
        headers: {
          'SkipApiResponseInterceptor': ''
        }
      }).subscribe(
        (response) => { // download file
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.download = invoiceID + '.pdf';
          link.href = blobUrl;
          link.click();
        },
        err => {
          if (err.status === 404) {
            this.toast.error('Račun nije nađen');
          }
          if (err.status === 403) {
            this.toast.error('Nemate prava da vidite račun');

          }
        }
      );
  }
}
