
import { Component, Input, OnInit } from '@angular/core';
import { WoltOrder } from '../_interface/wolt';
import { SessionService } from '../_services/session.service';
import { WoltService } from '../_services/wolt.service';

@Component({
  selector: 'app-wolt-order-details',
  templateUrl: './wolt-order-details.component.html',
  styleUrls: ['./wolt-order-details.component.scss']
})
export class WoltOrderDetailsComponent implements OnInit {
  order?: WoltOrder
  showRawData = false;

  @Input() orderID?: number;
  @Input() woltOrderID?: string;

  constructor(
    private woltService: WoltService,
    private session: SessionService
  ) { }

  ngOnInit(): void {

    if (this.woltOrderID) {
      this.woltService.getOrderByWoltID(this.session.companyID, this.woltOrderID).subscribe(
        order => {
          this.order = order;
        }
      )
    } else {
      this.woltService.getOrder(this.orderID!).subscribe(
        order => {
          this.order = order;
        }
      )
    }
  }

}
