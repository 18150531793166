import { WebsocketData, WSService } from 'src/app/core/_services/ws.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { map, skip, takeUntil, switchMap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorCodes, GenericItemApiResponse, LoginResult } from '../_interface/apiResponse';
import { CurrentUser } from '../_interface/user';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { IStorage, STORAGE_SERVICE } from '../_interface/storage';
import { NGXLogger } from 'ngx-logger';
import { CrossAppMessagingService } from './crossApp-messaging.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private _refreshTokenSubscription?: Subscription;

  tokenLoaded = new BehaviorSubject(false);
  socialUser: SocialUser | null = null;

  sessionExpired = new Subject<void>();

  currentUser = new BehaviorSubject<CurrentUser | null>(null);

  impersonateToken?: string;
  redirectUrl: string | null = null;

  token?: string | null;

  get isLoggedIn(): boolean {
    return !!this.currentUser.value;
  }

  get currentUserID(): number {
    return this.currentUser.value?.userID || 0;
  }


  constructor(private http: HttpClient,
    private socialAuth: SocialAuthService,
    private jwt: JwtHelperService,
    private router: Router,
    private logger: NGXLogger,
    private ws: WSService,
    private crossAppMessaging: CrossAppMessagingService,
    @Inject(STORAGE_SERVICE) private storageService: IStorage) {

    this.currentUser.subscribe(
      user => {
        if (user) {
          crossAppMessaging.sendRawMessage({ action: 'setToken', data: this.token });
          crossAppMessaging.sendRawMessage({ action: 'setUser', data: user });
        }
      }
    )

    this.currentUser.pipe(
      filter(user => !!user),
      switchMap(user => timer(1000, 6 * 60 * 60 * 1000))
    ).subscribe({
      next: (user) => {
        this.refreshToken();
      }
    });

    this.socialAuth.authState.subscribe(
      (user: any) => this.socialUser = user
    )

    storageService.get('authToken').then(token => {
      if (!environment.production) {
        this.logger.log("AUTH retreived from storage");
      }

      if (this.currentUser.value) {
        if (!environment.production) {
          this.logger.log("User already set");
        }
        this.tokenLoaded.next(true);

        return;
      }

      this.token = token || '';

      this.setCurrentUser(token);
      this.tokenLoaded.next(true);
    },
      () => {
        if (!environment.production) {
          this.logger.log("get token timeout");
        }
        this.tokenLoaded.next(true);
      });

    ws.observe("auth").subscribe(
      msg => {
        if (msg.data == "logout") {
          this.logger.info("WS reported logout - session expired");
          this.signOut().then(
            () => this.sessionExpired.next()
          );
        }
      }
    )

    ws.observe("sessionExpired").subscribe(
      msg => {
        if (this.currentUser.value?.sessionID == msg.data.sessionID) {
          this.logger.info("WS reported session expired");
          this.signOut().then(
            () => this.sessionExpired.next()
          );
        }
      }
    )

    ws.connected.subscribe(connected => {
      if (connected && this.tokenLoaded) {
        this.sendWSAuth();
      }
    });
  }

  get isImperosnating() {
    return (!!this.impersonateToken);
  }

  impersonateUser(token?: string) {
    this.impersonateToken = token;
    if (token) {
      this.setCurrentUser(token);
    } else {
      this.setCurrentUser(this.getToken()!);
    }
  }

  getToken() {
    return this.impersonateToken || this.token;
  }

  saveToken(token: string) {
    this.storageService.set('authToken', token);

    this.token = token;
  }

  setCurrentUser(token: string | null) {
    try {
      if (!token || this.jwt.isTokenExpired(token)) {
        return;
      }
    }
    catch {
      this.saveToken("");
      return;
    }

    const decoded = this.jwt.decodeToken(token);
    if (decoded == null) {
      return;
    }


    try {
      const jData = JSON.parse(decoded.user) as CurrentUser;

      var isNewUser = this.currentUserID != jData.userID;

      this.currentUser.next(jData as CurrentUser);

      this.logger.log("User set to ID " + jData.userID + " - " + jData.name);

      if (isNewUser) {
        this.sendWSAuth();
      }

      if (this.redirectUrl !== null) {
        this.router.navigate([this.redirectUrl]);
        this.redirectUrl = null;
      }
    } catch (error) {

    }

  }

  signOut() {
    this.logger.info("Signing out");

    var promise = new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        this.storageService.remove('authToken');

        this.token = null;
        this.currentUser.next(null);

        if (this._refreshTokenSubscription) {
          this._refreshTokenSubscription.unsubscribe();
          this._refreshTokenSubscription = undefined;
        }

        if (this.socialUser) {
          this.socialAuth.signOut().then(resolve);
        } else {
          resolve();
        }
      }, 300);

    });

    return promise;
  }


  refreshToken() {
    if (!this.token) {
      return;
    }

    if (!environment.production) {
      this.logger.log("Refresh user - " + this.currentUser.value?.name);
    }

    const apiAddress = environment.apiBase + 'user/RefreshToken';

    this._refreshTokenSubscription = this.http.get<GenericItemApiResponse<string>>(apiAddress).
      pipe(
        takeUntil(
          this.currentUser.pipe(skip(1))
        ))
      .subscribe(
        result => {
          if (result.success) {
            this.saveToken(result.item);

            //            this.loginCompleted(result.item);

          }
        }
      );
  }

  googleSignInExternal(googleTokenId: string): Observable<LoginResult> {
    const apiAddress = environment.apiBase + 'user/googlesigninexternal';

    return this.http.get<GenericItemApiResponse<string>>(apiAddress, {
      params: new HttpParams().set('googleTokenId', googleTokenId),
      headers: { 'SkipApiResponseInterceptor': '' }
    })
      .pipe(
        map((result) => {
          if (result.success) {
            this.loginCompleted(result.item);

            return LoginResult.OK;
          }
          if (result.errorCode === ErrorCodes.InvalidUserNamePassword) {
            return LoginResult.InvalidUserName;
          }

          return LoginResult.Disabled;
        })
      );

  }

  msSignInExternal(token: string): Observable<LoginResult> {
    const apiAddress = environment.apiBase + 'user/mssigninexternal';

    return this.http.get<GenericItemApiResponse<string>>(apiAddress, {
      params: new HttpParams().set('token', token),
      headers: { 'SkipApiResponseInterceptor': '' }
    })
      .pipe(
        map((result) => {
          if (result.success) {
            this.loginCompleted(result.item);
            return LoginResult.OK;
          }
          if (result.errorCode === ErrorCodes.InvalidUserNamePassword) {
            return LoginResult.InvalidUserName;
          }

          return LoginResult.Disabled;
        })
      );
  }

  facebookSignInExternal(fbTokenId: string): Observable<LoginResult> {
    const apiAddress = environment.apiBase + 'user/facebooksigninexternal';

    return this.http.get<GenericItemApiResponse<string>>(apiAddress, {
      params: new HttpParams().set('fbTokenId', fbTokenId),
      headers: { 'SkipApiResponseInterceptor': '' }
    })
      .pipe(
        map((result) => {
          if (result.success) {
            this.loginCompleted(result.item);

            return LoginResult.OK;
          }
          if (result.errorCode === ErrorCodes.InvalidUserNamePassword) {
            return LoginResult.InvalidUserName;
          }

          return LoginResult.Disabled;

        })
      );

  }


  login(data: { username: string, password: string }): Observable<LoginResult> {
    const apiAddress = environment.apiBase + `user/login?username=${encodeURIComponent(data.username)}&password=${encodeURIComponent(data.password)}`;

    const result = this.http.get<GenericItemApiResponse<string>>(apiAddress, {
      headers: { 'SkipApiResponseInterceptor': '' }
    });

    return this.executeLoginRequest(result);
  }

  loginWithToken(token: string) {
    const apiAddress = environment.apiBase + `user/loginWithToken`;
    var data = new FormData();
    data.append("token", token);

    const result = this.http.post<GenericItemApiResponse<string>>(apiAddress, data, {
      headers: { 'SkipApiResponseInterceptor': '' }
    });

    return this.executeLoginRequest(result);
  }


  loginWithAPIKey(api: string) {
    const apiAddress = environment.apiBase + `user/loginWithAPI`;
    var data = new FormData();
    data.append("api", api);

    const result = this.http.post<GenericItemApiResponse<string>>(apiAddress, data, {
      headers: { 'SkipApiResponseInterceptor': '' }
    });

    return this.executeLoginRequest(result);
  }

  private executeLoginRequest(request: Observable<GenericItemApiResponse<string>>) {
    var result = request.pipe(
      map(response => {
        if (response.success) {
          this.loginCompleted(response.item);

          return LoginResult.OK;
        }

        if (response.errorCode === ErrorCodes.InvalidUserNamePassword) {
          return LoginResult.InvalidUserName;
        }
        else if (response.errorCode === ErrorCodes.InvalidToken) {
          return LoginResult.InvalidToken;
        }
        else if (response.errorCode === ErrorCodes.AccessDenied) {
          return LoginResult.AccessDenied;
        }

        return LoginResult.Disabled;
      })
    );

    return result;
  }


  sendWSAuth() {
    if (!this.token) {
      return;
    }

    const data: WebsocketData = {
      type: "auth",
      data: {
        "type": "token",
        "token": this.token,
      }
    };

    this.ws.send(data);
  }

  loginCompleted(token: string) {
    this.saveToken(token);
    this.setCurrentUser(token);

  }

  loginWithPin(companyID: number, pin: string, token: string) {

    const apiAddress = environment.apiBase + `user/loginWithPIN`;

    var data = {
      companyID: companyID,
      pin: pin,
      token: token
    };

    const result = this.http.post<GenericItemApiResponse<string>>(apiAddress, data, {
      headers: { 'SkipApiResponseInterceptor': '' }
    });

    return this.executeLoginRequest(result);
  }


}
