import { filter, map } from 'rxjs/operators';

import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../_interface/product';
import { SessionService } from '../_services/session.service';


@Pipe({ name: 'firstGroupName' })
export class FirstGroupNamePipe implements PipeTransform {
  constructor(private session: SessionService) {


  }
  transform(productID: number) {
    if (!productID) {
      return null;
    }

    return this.session.groups.pipe(
      map(
        list => {
          return list.find(g => g.products.some(gp => gp.productID === productID))?.name;
        }
      )

    )
  }
}
