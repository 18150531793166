import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponse, GenericItemApiResponse, GenericListApiResponse } from '../_interface/apiResponse';
import { Customer } from '../_interface/order';
import { map } from 'rxjs';
import { ProductPrice } from '../_interface/product';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {


  constructor(private http: HttpClient) { }


  setPriceList(customerID: number, priceListName: string) {
    const url = environment.apiBase + `customer/setPriceList`;
    var data = {
      customerID: customerID,
      priceListName: priceListName
    };

    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map(response => {
          if (response.success) {
            return;
          }
          throw response.errorDescription;
        }),
      );
  }


  search(companyID: number, name: string) {
    const url = environment.apiBase + `customer/Search/${companyID}/${name}`;
    return this.http.get<GenericListApiResponse<Customer>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw (response);
        })
      );
  }

  getProductPrices(companyID: number, userID: number) {
    const url = environment.apiBase + `customer/productPrices/${companyID}/${userID}`;
    return this.http.get<GenericListApiResponse<ProductPrice>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw (response);
        })
      );
  }

  saveProductPrices(companyID: number, userID: number, items: any[]) {
    const url = environment.apiBase + `customer/saveProductPrices/${companyID}/${userID}`;
    return this.http.post<ApiResponse>(url, items)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }


  setBlocked(companyID: number, customerID: number, isBlocked: boolean) {
    const url = environment.apiBase + `customer/setBlocked`;
    var data = {
      companyID: companyID,
      customerID: customerID,
      isBlocked: isBlocked
    };

    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }

  saveCustomer(companyID: number, customer: Customer) {
    const url = environment.apiBase + `customer/save`;
    var data = {
      companyID,
      customer
    };
    return this.http.post<GenericItemApiResponse<Customer>>(url, data)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          throw (response);
        })
      );
  }
}
