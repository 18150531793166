import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'app-please-wait',
  templateUrl: './please-wait.component.html',
  styleUrls: ['./please-wait.component.scss']
})
export class PleaseWaitComponent implements OnInit, OnDestroy {
  @Input() description: string = "";

  ///koliko sekundi da se ceka pre nego sto se prikaze
  @Input() delay = 0;

  @Input() threshold = 0;
  @Input() thresholdDescription = "";

  isVisible = true;
  text = "";

  destroy = new Subject<void>();

  constructor(private logger: NGXLogger) { }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngOnInit(): void {
    this.text = this.description;

    if (this.delay) {
      this.isVisible = false;
      timer(this.delay * 1000)
        .pipe(
          takeUntil(this.destroy)
        ).subscribe(() => {
          this.isVisible = true;
        }
        );
    }

    if (this.threshold) {

      timer(this.threshold * 1000)
        .pipe(
          takeUntil(this.destroy)
        )
        .subscribe(() => {
          this.logger.warn("Please wait threshold reached after " + this.threshold + "ms");
          this.text = this.thresholdDescription;
        })
    };
  }

}
