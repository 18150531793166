import { INGXLoggerConfig, INGXLoggerMetadata, INGXLoggerWriterService, NGXLoggerWriterService } from "ngx-logger";
import { logDB } from "../data/logDB";



export class DBLogger extends NGXLoggerWriterService {
  constructor(
  ) {
    super(1);
  }

  override writeMessage(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): void {
    logDB.log.add({
      timestamp: metadata.timestamp!,
      message: metadata.message,
      level: metadata.level,
      additional: metadata.additional,
    });
  }
}
