
import { OrderStatus } from './../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'enumEnumerator' })
export class EnumEnumeratorPipe implements PipeTransform {

  transform<T>(val: any): any[] {

    var list: { key: string, value: T }[] = [];

    var d = Object.keys(val).reduce((arr, key) => {
      if (!arr.some(i => i.key == key)) {
        var newItem = {
          key: val[key],
          value: val[val[key]] as T
        }
        arr.push(newItem);
      }
      return arr;
    }, list);

    return d.map(i => i.value);
  }
}


