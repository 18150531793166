import { SessionService } from './../_services/session.service';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';


@Pipe({ name: 'childProducts' })
export class ChildProductsPipe implements PipeTransform {
  constructor(private session: SessionService) {

  }

  transform(productID: number) {

    return this.session.products.pipe(
      map(
        products => {
          return products.filter(p => p.parentProductID === productID);
        }
      )
    )
  }
}
