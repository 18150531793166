import { debounce, switchMap, filter, tap, map, distinctUntilChanged } from 'rxjs/operators';
import { observable, Subject, timer } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss']
})
export class QRCodeScannerComponent {
  cameras?: MediaDeviceInfo[];
  activeCameraIndex = 0;
  scanedCode = new Subject<string>();

  invalid = false;
  @Input()
  delayBetweenScanSuccess = 500;

  @Input() enabled = true;

  @Input() autoIgnoreSameCode = true;

  @Input() autoClose = false;

  @Output()
  scanCompleted = new EventEmitter<string>();

  @ViewChild(ZXingScannerComponent) scaner!: ZXingScannerComponent;

  constructor(private dialogRef: MatDialogRef<QRCodeScannerComponent>) {

    this.scanedCode.pipe(
      map(e => ({ code: e, time: Date.now() })),
      distinctUntilChanged((a, b) => (a.code == b.code) && (a.time > b.time - 5000))
    )
      .subscribe(
        val => {
          this.scanCompleted.next(val.code);

          if (this.autoClose && this.dialogRef) {
            this.dialogRef.close(val);
          }
        }
      );
  }

  public scanSuccessHandler($event: any) {
    if (!this.enabled) {
      return
    }

    var data = this.extractCode($event);

    this.scanedCode.next(data);
  }


  camerasFound(cameras: MediaDeviceInfo[]) {
    this.cameras = cameras;
  }

  extractCode(data: string): string {
    var start = data.lastIndexOf('/') + 1;
    var end: number | undefined = data.lastIndexOf("?");
    if (end == -1) {
      end = undefined;
    }
    return data.substring(start, end);
  }

  toggleCamera() {
    if (!this.cameras) {
      return;
    }


    this.activeCameraIndex = this.cameras.indexOf(this.scaner.device!);
    this.activeCameraIndex++;

    if (this.activeCameraIndex >= this.cameras?.length) {
      this.activeCameraIndex = 0;
    }

    var newDevice = this.cameras[this.activeCameraIndex];
    if (newDevice != this.scaner.device) {
      this.scaner.device = newDevice;
    }
  }

  toggleTorch() {
    this.scaner.torch = !this.scaner.torch;
  }
}
