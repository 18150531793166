import { interval, Subscription } from 'rxjs';
import { Directive, ElementRef, Input, AfterViewInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appOrderItemColor]'
})
export class OrderItemColorDirective {
  private timer?: Subscription;

  static counter = 0;
  @Input() appOrderItemColor = true;
  @Input() preparationTime: number | undefined | null;
  @Input() startDate?: Date | string;

  constructor(private el: ElementRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.timer?.unsubscribe();

    if (this.preparationTime && this.startDate && this.appOrderItemColor) {

      this.timer = interval(100).subscribe(() => {
        this.updateColor();
      });
    }
  }

  updateColor(): void {
    var elapsedSec = (new Date().getTime() - new Date(this.startDate!).getTime()) / 60000;
    var progres = elapsedSec * 100 / this.preparationTime!;
    if (progres >= 100) {
      this.el.nativeElement.style.backgroundColor = "#F4433666";
      this.timer?.unsubscribe();
    } else if (progres >= 90) {
      this.el.nativeElement.style.backgroundColor = "#FFEB3B66";
    }
  }
}
