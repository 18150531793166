import { Pipe, PipeTransform } from '@angular/core';

;

@Pipe({
  name: 'orderProgressColor'
})
export class OrderProgressColorPipe implements PipeTransform {

  transform(value: number) {
    if (value < 90) {
      return "#4CAF50"; //zelena
    }
    else if (value >= 100) {
      return "#F44336"; // crvena
    } else {
      return "#FFEB3B"; // žuta

    }
    //  return this.interpolateColor([0, 255, 0], [255, 0, 0], value);
  }

  interpolateColor(color1: any, color2: any, factor: number) {

    factor = factor / 100;
    if (factor > 1) {
      factor = 1;
    }

    if (arguments.length < 3) { factor = 0.5; }
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    var val = this.r2h(result);

    return val;
  };

  r2h(rgb: any) {
    return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
  };
}
