import { Order } from 'src/app/core/_interface/order';
import { Pipe, PipeTransform } from '@angular/core';

export enum SORT_FIELD {
  CreatedBy,
  DueDate,
  ModifiedAt
}

@Pipe({
  name: 'sortOrdersByDate'
})
export class SortOrdersByDatePipe implements PipeTransform {

  transform(orders: Order[] | null, descending = 0, field = SORT_FIELD.CreatedBy) {
    if (!orders) {
      return null;
    }

    var sorted = orders.sort((a, b) => {
      var aDate;
      var bDate;

      switch (field) {
        case SORT_FIELD.CreatedBy:
          aDate = a.created;
          bDate = b.created;
          break;
        case SORT_FIELD.DueDate:
          aDate = a.dueDate!;
          bDate = b.dueDate!;
          break;
        case SORT_FIELD.ModifiedAt:
          aDate = a.modified!;
          bDate = b.modified!;
          break;
      }


      if (!aDate && bDate) {
        return 1;
      }
      else if (aDate && !bDate) {
        return -1;
      } else if (!aDate && !bDate) {
        return a.created.getTime() - b.created.getTime();
      }
      else {
        return new Date(aDate)!.getTime() - new Date(bDate)!.getTime()
      }
    });

    if (descending) {
      sorted = sorted.reverse();
    }

    return sorted;
  }

}
