import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { DeviceService } from "./_services/device.service";
import { environment } from "src/environments/environment";
import { NGXLogger } from "ngx-logger";

@Injectable()
export class LogRequestsInterceptor implements HttpInterceptor {

  constructor(private logger: NGXLogger) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var id = Math.random();
    this.logger.trace("Request " + id.toString() + " started: " + req.urlWithParams);

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          var error = evt.body && evt.body.errorDescription;

          this.logger.trace("Request " + id.toString() + " completed. Status: " + evt.status + (error ? ", errorCode: " + evt.body.errorCode + ", error: " + error : ""));
        }
      }));
  }

}
