import { Pipe, type PipeTransform } from '@angular/core';
import { ConnectionMetadata } from '../_interface/ConnectionMetadata';

@Pipe({
  name: 'appFilterByApplicationName',
  pure: false
})
export class FilterByApplicationNamePipe implements PipeTransform {

  transform(value: ConnectionMetadata[] | null | undefined, appName: string) {
    if (!value) return value;

    if (appName == "api") {
      return value?.filter(x => x.isPos);
    }
    else if (appName) {
      return value?.filter(x => x.appVersion && x.appVersion.indexOf("-" + appName) != -1);
    }
    else {
      return value;
    }
  }

}
