import { NotificationService } from './../_services/notification.service';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/_services/authorization.service';
import { Component, OnInit } from '@angular/core';

@Component({
  template: ``,
  styles: [
  ]
})
export class LogoutComponent {

  constructor(authService: AuthorizationService, router: Router, notification: NotificationService) {
    authService.signOut().then(() => {
      router.navigate(["/"], { replaceUrl: true });
    })
  }

}
