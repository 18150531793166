import { AuthorizationService } from './../_services/authorization.service';
import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Invoice } from '../_interface/Invoice';
import { InvoiceService } from '../_services/invoice.service';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent {
  fileToUpload?: File | null;
  isBusy = false;

  constructor(@Inject(MAT_DIALOG_DATA) public invoice: Invoice,
    public auth: AuthorizationService,
    private invoiceService: InvoiceService,
    private toast: ToastrService,
    private dialogRef: MatDialogRef<InvoiceDetailsComponent>
  ) { }

  recreateReceipt() {
    this.isBusy = true;
    this.invoiceService.recreateReceipt(this.invoice.invoiceID).subscribe(
      success => {
        if (success) {
          this.toast.success('Račun generisan');
        }
        this.isBusy = false;
      }
    );
  }

  markAsPaid() {

    this.isBusy = true;
    this.invoiceService.markAsPaid(this.invoice.invoiceID).subscribe(
      success => {
        if (success) {
          this.invoice.paidAt = new Date();
          this.toast.success('Podaci snimljeni');
          this.dialogRef.close(true);
        }
      }
    );
  }

  download() {
    this.invoiceService.download(this.invoice.invoiceID);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
}
