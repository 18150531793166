import { TranslatePipe } from '@ngx-translate/core';
import { OrderStatus, OrderStatusLog } from './../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'orderStatus' })
export class OrderStatusPipe implements PipeTransform {

  transform(status: OrderStatus | OrderStatusLog, currentStatus?: OrderStatus): string {
    var translationKey = 'orderStatus.';
    translationKey += OrderStatus[status]?.toLowerCase() || OrderStatusLog[status]?.toLowerCase(); ;
    if (currentStatus && status !== currentStatus) {
      translationKey += '.change';
    } else {
      translationKey += '.status';
    }

    return translationKey;
  }
}
