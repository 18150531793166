<div matDialogTitle>Odaberite načine plaćanja</div>
<mat-dialog-content>
  <div class="payments">
    <div class="item" *ngFor="let payment of selectedPaments">
      <span>{{ payment.type | paymentMethodName | translate }}</span>
      <span>{{ payment.amount | number : "1.2-2" }}</span>
    </div>
    <div class="item remaining">
      <span> Za plaćanje</span>
      <span>{{ remainingAmount | number : "1.2-2" }}</span>
    </div>
  </div>
  <div>
    <div class="currentAmount">
      Uplaćeno:
      <input
        [(ngModel)]="remainingAmount"
        #amountField
        (focus)="amountField.select()"
        type="number"
      />
    </div>
    <div class="paymentMethods">
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Cash)"
        [disabled]="
          (PaymentMethod.Cash | isPaymentTypeEnabled : client | async) === false
        "
      >
        {{ PaymentMethod.Cash | paymentMethodName | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.CreditCard)"
        [disabled]="
          (PaymentMethod.CreditCard | isPaymentTypeEnabled : client | async) ===
          false
        "
      >
        {{ PaymentMethod.CreditCard | paymentMethodName | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Instant)"
        [disabled]="
          (PaymentMethod.Instant | isPaymentTypeEnabled : client | async) ===
          false
        "
      >
        {{ PaymentMethod.Instant | paymentMethodName | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Check)"
        [disabled]="
          (PaymentMethod.Check | isPaymentTypeEnabled : client | async) ===
          false
        "
      >
        {{ PaymentMethod.Check | paymentMethodName | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.WireTransfer)"
        [disabled]="
          (PaymentMethod.WireTransfer
            | isPaymentTypeEnabled : client
            | async) === false
        "
      >
        {{ PaymentMethod.WireTransfer | paymentMethodName | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Voucher)"
        [disabled]="
          (PaymentMethod.Voucher | isPaymentTypeEnabled : client | async) ===
          false
        "
      >
        {{ PaymentMethod.Voucher | paymentMethodName | translate }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Other)"
        [disabled]="
          (PaymentMethod.Other | isPaymentTypeEnabled : client | async) ===
          false
        "
      >
        {{ PaymentMethod.Other | paymentMethodName | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button
    mat-raised-button
    color="accent"
    (click)="voidOrders(PaymentMethod.None)"
    [disabled]="
      (PaymentMethod.None | isPaymentTypeEnabled : client | async) === false
    "
  >
    {{ PaymentMethod.None | paymentMethodName | translate }}
  </button>

  <button mat-stroked-button (click)="selectClient()">
    <ng-container *ngIf="!client; else clientDetails"
      >Odaberite kupca</ng-container
    >
    <ng-template #clientDetails
      >Kupac: {{ client!.name || client!.tin }}</ng-template
    >
  </button>
  <button mat-stroked-button (click)="selectCustomer()">
    <ng-container *ngIf="!customer; else customerDetails"
      >Odaberite korisnika</ng-container
    >
    <ng-template #customerDetails>korisnik: {{ customer!.name }}</ng-template>
  </button>
  <button mat-raised-button matDialogClose>Otkaži</button>
</mat-dialog-actions>

<swal
  #printCopy
  icon="question"
  title="Štampaj kopiju računa"
  text="Da li želite da štampate kopiju računa?"
  input="checkbox"
  inputPlaceholder="Ne pitaj ponovo"
  confirmButtonText="Da"
  cancelButtonText="Ne"
  denyButtonText="Ne"
  [showDenyButton]="true"
  [showCancelButton]="false"
></swal>

<app-please-wait
  *ngIf="isBusy"
  description="Pokrećem naplatu preko {{
    SHELL_APP_CONFIG.cardPaymentProvider
  }}. Molimo sačekajte..."
></app-please-wait>

<app-inactivity-monitor
  [duration]="80"
  text="Automatski prekid naplate"
  (elapsed)="inactivityElapsed()"
>
</app-inactivity-monitor>

<app-please-wait
  *ngIf="precheckActive"
  description="Proveravam iznos za naplatu. Molimo sačekajte..."
></app-please-wait>
