<div class="title">
  <ng-container *ngIf="title; else template">
    {{ title }}
  </ng-container>
</div>
<ng-content></ng-content>

<ng-template #template>
  <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
</ng-template>
