const host = 'app.test.emeni.rs';

export const environment = {
  production: true,
  apiBase: 'https://' + host + '/api/',
  backendBase: 'https://' + host,
  host: host,
  azureADClientID: '47a6b87d-a744-4f5a-ba3c-c2120befb1f4',
  googleClientID: '391804863255-hog71rc56ak8vped7sle6i2i6etkbus4.apps.googleusercontent.com',
  facebookAppID: '519232895226979',
  appVersion: require('../../package.json').version + '-w' + '-test',
  tookanURL: "https://private-anon-65f34dd33d-tookanapi.apiary-mock.com/v2/",
  nativeApp: false
};

