<swal #mySwal title="Potvrda" [showCancelButton]="true"></swal>

<div *ngIf="order">
  <div
    class="warning"
    *ngIf="
      order.status === OrderStatus.NewPreorder ||
      order.status === OrderStatus.Pending
    "
  >
    {{ 'delayedOrder' | translate }}
    <div>
      {{ 'requestedDueDate' | translate }}
      {{ order.dueDate | date : "dd.MM.yyyy. u HH:mm" }}
    </div>
  </div>

  <ng-container
    *ngIf="order.status === OrderStatus.NewPreorder; else selectStatus"
  >
    <div class="statusGroup">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="disabled"
        (click)="confirmStatusChange(OrderStatus.Pending)"
        class="orderStatus"
      >
        {{ OrderStatus.Pending | orderStatus : order.status | translate}}
      </button>

      <button
        mat-stroked-button
        [disabled]="disabled"
        color="primary"
        (click)="confirmStatusChange(OrderStatus.Rejected)"
        class="orderStatus"
      >
        {{ OrderStatus.Rejected | orderStatus : order.status | translate}}
      </button>
    </div>
  </ng-container>
  <ng-template #selectStatus>
    <div class="statusGroup">
      <ng-container *ngFor="let status of OrderStatus | enumEnumerator">
        <button
          mat-stroked-button
          color="primary"
          (click)="confirmStatusChange(status)"
          [disabled]="disabled || (!(auth.currentUser | async)?.isAdmin && isDisabled(status))"
          class="orderStatus"
          [class.mat-flat-button]="status === order.status"
          [class.notAvailable]="(status | statusAvailable | async) === false"
          *ngIf="
            (auth.currentUser | async)?.isAdmin ||
            (status | statusAvailable | async)
          "
        >
          {{ status | orderStatus : order.status | translate}}
        </button>
      </ng-container>
    </div>
  </ng-template>
</div>
