import { AuthorizationService } from 'src/app/core/_services/authorization.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Bundle } from '../_interface/Bundle';
import { ApiResponse, GenericListApiResponse } from '../_interface/apiResponse';

@Injectable({
  providedIn: 'root'
})
export class BundleService {

  private _bundles?: BehaviorSubject<Bundle[]> | null;

  constructor(private http: HttpClient, authService: AuthorizationService) {
    this.loadBundles();

    authService.currentUser.subscribe(
      (user) => {
        this._bundles = null;
        if (user) {
          this.loadBundles();
        }
      }
    );
  }

  get bundles() {
    if (this._bundles == null) {
      this._bundles = new BehaviorSubject<Bundle[]>([]);
      this.loadBundles();
    }

    return this._bundles;
  }

  get publicBundles(): Bundle[] {
    return this.bundles.value.filter(b => b.isPublic).sort((a, b) => a.price - b.price);
  }

  getBundle(id: number) {
    return this.bundles?.value.find(b => b.bundleID === id);
  }

  getName(bundleID: number) {
    return this.bundles.value.find(b => b.bundleID == bundleID)?.name;
  }

  saveBundle(bundle: Bundle): Observable<boolean> {

    const apiAddress = environment.apiBase + 'bundle/save';

    const response = this.http.post<ApiResponse>(apiAddress, bundle).pipe(
      map(data => {
        return data.success;
      }),
      tap(sucess => {
        if (sucess) {
          this.loadBundles();
        }
      })
    );

    return response;

  }

  loadBundles() {
    const apiAddress = environment.apiBase + 'bundle/get';

    this.http.get<GenericListApiResponse<Bundle>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          return data.items;
        }
        return null;
      })
    ).subscribe(data => {
      if (data) {
        this._bundles?.next(data);
      }
    }
    );

  }
}
