import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap } from 'rxjs';
import { Customer } from 'src/app/core/_interface/order';
import { CustomerService } from 'src/app/core/_services/customer.service';
import { SessionService } from '../../waiter/_services/session.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CustomerEditComponent } from 'src/app/core/customer-edit/customer-edit.component';

@Component({
  selector: 'app-search-customers',
  templateUrl: './search-customers.component.html',
  styleUrls: ['./search-customers.component.scss']
})
export class SearchCustomersComponent implements OnInit, AfterViewInit {
  customers$: Observable<Customer[]>;
  nameControl = new FormControl();
  selected?: Customer;
  loading = false;

  @ViewChild("input") input?: ElementRef<HTMLInputElement>;

  constructor(
    customerService: CustomerService,
    private session: SessionService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SearchCustomersComponent>

  ) {

    dialogRef.disableClose = true;

    this.customers$ = this.nameControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter((name) => !!name && !(typeof (name) === "object")),
      switchMap(name => {
        this.loading = true;
        return customerService.search(this.session.companyID, name).pipe(
          catchError(() => []),
          finalize(() => this.loading = false)
        )
      })
    );

  }
  ngAfterViewInit(): void {
    this.input?.nativeElement.focus();
  }

  ngOnInit(): void {
  }


  createNewCustomer() {
    var ref = this.dialog.open(CustomerEditComponent);
    ref.afterClosed().subscribe((customer) => {
      if (customer) {
        this.selected = customer;
        this.ok();
      }
    });
  };

  ok() {
    this.dialogRef.close(this.selected);
  }
}
