import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { WSService } from './ws.service';

@Injectable({
  providedIn: 'root'
})
export class ConcurrentLimitMonitorService {

  constructor(ws: WSService) {

    ws.observe('concurrentLimitReached').subscribe((data) => {
      if (data.data.maxCount === 0) {
        Swal.fire({
          title: 'Pristup zabranjen',
          text: 'Korišćenje ove aplikacije nije aktivirano za ovaj objekat. Kontaktirajte podršku za više informacija.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
      }
      else {
        Swal.fire({
          title: 'Dostignut maksimalan broj konekcija',
          text: 'Dostigli ste maksimalan broj aktivnih aplikacija. Odjavite se sa drugih uređaja da biste se prijavili na ovom.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
      }
    });
  }

}
