import { BehaviorSubject, Subject } from 'rxjs';

import { deepEqual } from '../_helpers/equaleCompare';
import { Waiter } from './user';
import { CustomerService } from '../_services/customer.service';
import { ProductPrice } from './product';
import { Client } from './client';

export enum OrderStatus {
  New = 1,
  Accepted = 3,
  InProduction = 4,
  Ready = 5,
  Dispached = 6,
  Delivered = 7,
  Rejected = 8,
  Canceled = 9,
  Completed = 10,
  Invalid = 11,
  NewPreorder = 12,
  Pending = 13,
  PendingPayment = 14
}

export enum OrderStatusLog {
  New = 1,
  Accepted = 3,
  InProduction = 4,
  Ready = 5,
  Dispached = 6,
  Delivered = 7,
  Rejected = 8,
  Canceled = 9,
  Completed = 10,
  Invalid = 11,
  NewPreorder = 12,
  Pending = 13,
  PendingPayment = 14,
  Paid = 101
}

export enum OrderItemStatus {
  Default = 0,
  Ready = 1,
  Delivered = 2
}

export enum DeliveryType {
  InHouse = "InHouse",
  Pickup = "Pickup",
  Delivery = "Delivery",
  EatIn = "EatIn"
}

export interface UserBase {
  userID: number;
  firstName: string;
  lastName: string;
  eMail: string;
}

export interface CreatedBy extends UserBase {
  verified: boolean;
}

export interface Customer extends CustomerRating {
  customerID: number;
  userID?: number;

  name: string;
  address: string;
  phoneNumber: string;
  note: string;
  isBlocked: boolean;

  discount: number;
  priceListName: string;
}

export interface CustomerRating {
  orderCount: number;
  plus: number;
  minus: number;
  overallPlus: number;
  overallMinus: number;
}
export interface CompanyBasicInfo {
  companyID: number;
  name: string;
}

export interface InvalidOrderDetails {
  items: InvalidOrderItem[],
  total: number
}

export interface InvalidOrderItem {
  name: string;
  price: number;
  quantity: number;
  modifiers?: InvalidOrderItem[];
}

export class Order {
  companyID = 0;
  orderID = 0;
  created!: Date;
  deliveryType!: DeliveryType;
  status!: OrderStatus;
  tableID?: number;
  items: OrderItem[] = [];
  itemsChanged = new BehaviorSubject<OrderItem[]>([]);
  dueDate?: string;
  modified?: string | Date;
  accepted?: string | Date;
  deliveryDetail?: OrderDeliveryDetail;
  createdBy?: CreatedBy;
  waiter?: Waiter;
  note: string = '';
  orderNo: string = '';
  group: string = '';
  rejectReason = '';
  provider: 'wolt' | 'mrd' | '' = '';
  isPaid: boolean = false;
  private _customer?: Customer;
  originCompany?: CompanyBasicInfo;
  client?: Client;
  invalidOrderDetails?: InvalidOrderDetails;

  customerProductPrices = new BehaviorSubject<ProductPrice[]>([]);

  get customer() {
    return this._customer;
  }
  set customer(val: Customer | undefined) {
    this._customer = val;
    if (val && !this.orderID && val.userID) {
      var cs = globalThis.AppInjector.get(CustomerService);

      cs.getProductPrices(this.companyID, val.userID!).subscribe({
        next: prices => {
          this.customerProductPrices.next(prices);
        }
      });
    }
  }

  get total(): number {
    var total = this.items.filter(i => !i.isCanceled).reduce((s, i) => {
      return s + i.total;
    }, 0);

    return total;
  }

  constructor(json?: any) {
    if (json) {
      Object.assign(this, json);
    }

    this.created = new Date(this.created);

    this.items.forEach((item, index) => {
      this.items[index] = OrderItem.fromJSON(item);
    });

    this.deliveryType = <any>DeliveryType[this.deliveryType];
  }


  replaceOrderItem(old: OrderItem, newItem: OrderItem) {
    var index = this.items.indexOf(old);
    if (index != -1) {
      this.items[index] = newItem;

      this.itemsChanged.next(this.items);
    }
  }

  addItem(item: OrderItem): void {
    item.order = this;
    const hash = JSON.stringify(item);

    let found = false;
    for (const i of this.items) {
      const tmp1 = {} as OrderItem;
      Object.assign(tmp1, i);
      tmp1.quantity = 0;

      const tmp2 = {} as OrderItem;
      Object.assign(tmp2, item);
      tmp2.quantity = 0;

      if (deepEqual(tmp1, tmp2)) {
        i.quantity += item.quantity;
        found = true;
        break;
      }
    }

    if (!found) {
      this.items.push(item);
    }

    this.items = [... this.items];
    this.itemsChanged.next(this.items);
  }

  removeItem(item: OrderItem): void {
    var index = this.items.indexOf(item);

    this.items.splice(index, 1);
    this.itemsChanged.next(this.items);
  }

  public toJSON() {
    const { customerProductPrices, itemsChanged, ...otherProps } = this;

    var obj = Object.entries(otherProps) // Create array from object
      .map(([key, value]) => [  // change key to remove '_'
        key.startsWith('_') ? key.substring(1) : key,
        value
      ])
      .reduce((acc, [key, value]) => { // Transform back to object
        acc[key] = value;
        return acc;
      }, {} as any);

    return obj;

  }
}

export class OrderItemModifier {
  modifierID: number | null = null;
  productID = 0;
  quantity = 0;
  price = 0;
  get total() {
    return this.quantity * this.price;
  }

  static fromJSON(json: any): OrderItemModifier {
    const i = new OrderItemModifier();
    Object.assign(i, json);
    return i;
  }
}

export interface OrderItemToCancel {
  orderItemID: number;
  orderItemReferenceID: string;
  orderID: number;
}

export class OrderItem {
  orderItemID: number = 0;
  modifiers: OrderItemModifier[] = [];
  quantity: number = 0;
  productID: number = 0;
  referenceID: string = '';
  name: string = '';
  note: string = '';
  price: number = 0;
  status: OrderItemStatus = OrderItemStatus.Default;
  isCanceled = false;
  orderItemReferenceID = '';
  invalidOrderDetails?: InvalidOrderItem[];
  order?: Order;

  get total() {
    var total = this.quantity * (this.price + this.modifierTotal());
    if (this.order?.customer?.discount) {
      total = total - (total * this.order.customer.discount / 100);
    }
    return total;
  }

  private modifierTotal(): number {
    return this.modifiers?.reduce((s, i) => {
      return s + i.total;
    }, 0) || 0;
  }

  static fromJSON(json: any): OrderItem {
    const oi = new OrderItem();
    Object.assign(oi, json);

    oi.modifiers = oi.modifiers || [];

    oi.modifiers?.forEach((m, i) => {
      oi.modifiers[i] = OrderItemModifier.fromJSON(m);
    })
    return oi;
  }


  public toJSON() {
    const { order, ...otherProps } = this;

    var obj = Object.entries(otherProps) // Create array from object
      .map(([key, value]) => [  // change key to remove '_'
        key.startsWith('_') ? key.substring(1) : key,
        value
      ])
      .reduce((acc, [key, value]) => { // Transform back to object
        acc[key] = value;
        return acc;
      }, {} as any);

    return obj;

  }
}

export interface OrderDeliveryDetail {
  name: string;
  phoneNumber: string;
  address: string;
  note: string;
  provider?: any;
}
export interface CancelOrderItem {
  tableID: number;
  orderItemID: string;
}

export interface SearchOrderOptions {
  companyID: number;
  createdFrom?: string | null | Date;
  createdTo?: string | null | Date;
  modifiedFrom?: string | null | Date;
  modifiedTo?: string | null | Date;
  fromOrderID?: number | null;
  status?: OrderStatus[];
  deliveryType?: DeliveryType[] | null;
  orderNo?: string | null;
  invalid?: boolean | null;
  provider?: string | null;
  dueDateFrom?: string | null | Date;
  dueDateTo?: string | null | Date;
}

export interface CancelOrderItemResponse {
  success: boolean;
  error: string;
}

export interface OrderStatusChangeItem {
  orderID: number;
  status: OrderStatusLog;
  date: string;
  changedBy: string;
}

export interface PlacedOrder {
  created: string;
  status: OrderStatus;
  items: OrderItem[];
  orderNo: string;
  orderID: number;
}


