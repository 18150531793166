import { first } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DeliveryType, Order, OrderItem, OrderItemModifier, OrderItemStatus, OrderStatus } from '../_interface/order';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component';
import { OrderService } from '../_services/order.service';
import { SessionService } from '../_services/session.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  DeliveryType = DeliveryType;
  OrderStatus = OrderStatus;
  OrderItemStatus = OrderItemStatus;

  destroyed = new Subject<void>();
  @Input() onClick = (order: Order) => { this.showOrder(order) };
  @Input() items?: OrderItem[] | null;
  @Input() modifiersFilter = (items: OrderItemModifier[]) => { return items };
  @Input() showDetails = true;
  @Input() showItemProgress = true;
  @Input() enableOrderItemStatusChange = false;
  @Input() showProgressBar = true;

  @Input() order!: Order;

  @Output() orderItemClicked: EventEmitter<OrderItem> = new EventEmitter();

  constructor(private dialog: MatDialog,
    private session: SessionService,
    private orderService: OrderService) {

  }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }


  ngOnInit(): void {
  }


  showOrder(order: Order) {
    var ref = this.dialog.open(OrderDetailsDialogComponent);
    ref.componentInstance.order = order;
  }

  onOrderItemClicked(item: OrderItem) {
    if (this.enableOrderItemStatusChange) {
      this.orderItemClicked.emit(item);
    }
  }
  /*
    setStatus(item: OrderItem, status?: OrderItemStatus) {
      if (!this.enableOrderItemStatusChange) {
        return;
      }

      if (!status) {
        status = item.status == OrderItemStatus.Ready ? OrderItemStatus.Delivered : OrderItemStatus.Ready;
      }

      this.session.companySettings.pipe(
        first()
      ).subscribe(
        val => {
          if (val?.requestConfirmationForOrderItemStatusChange) {
            var statusName = "";
            switch (status) {
              case OrderItemStatus.Ready:
                statusName = "spremno";
                break;
              case OrderItemStatus.Delivered:
                statusName = "isporučeno";
                break;
            }

            Swal.fire({
              title: "Potvrda promene statusa",
              html: "Da li ste sigurni da želite da promenite status stavke <b>" + item.name + "</b> u <b>" + statusName + "</b>?",
              icon: "question",
              showCancelButton: true
            }).then(
              result => {
                if (result.isConfirmed) {
                  this.setStatusInternal(item, status!);
                }
              }
            )
          }
          else {
            this.setStatusInternal(item, status!);
          }
        }
      )
    }

    private setStatusInternal(item: OrderItem, status: OrderItemStatus) {

      this.orderService.setOrderItemStatus(item, status).subscribe(
        {
          next: () => {
            item.status = status!;
          }
        }
      )
    }
    */
}
