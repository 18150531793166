import { Directive, ElementRef, OnDestroy, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[splitLetters]'
})
export class SplitLettersDirective {
  changeInProgress = false;

  private _text: string = '';

  @Input('splitLetters')
  public get text(): string {
    return this._text;
  }
  public set text(value: string) {
    this._text = value;
    this.split();

  }

  constructor(private el: ElementRef) { }


  split() {
    if (this.changeInProgress) {
      return;
    }

    this.changeInProgress = true;
    var newcontent = this.text.split("").map(letter => `<span ${letter.trim() === "" ? "" : 'class="letter"'}>${letter}</span>`).join("")


    this.el.nativeElement.innerHTML = newcontent;

    this.changeInProgress = false;

  }
}
