import { Directive, ElementRef, Input, Renderer2, RendererStyleFlags2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[DisableAnimation]'
})
export class DisableAnimationDirective {
  @Input()
  set DisableAnimation(val: any) {
    if (val) {
      this.renderer.setStyle(this.hostElement.nativeElement, "animation-duration", "0s", RendererStyleFlags2.Important);
    } else {
      this.renderer.removeStyle(this.hostElement.nativeElement, 'animation-duration');
    }
  }

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {

  }

}
