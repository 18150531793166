import { Pipe, PipeTransform } from '@angular/core';
import { Allergens } from 'src/app/core/_interface/product';


@Pipe({ name: 'toAllergenName' })
export class ToAllergenNamePipe implements PipeTransform {

  transform(allergen: Allergens): string {
    switch (allergen) {
      case (Allergens.Wheat):
        return 'Žitarice';
      case (Allergens.Sulfur):
        return 'Sumpor';
      case (Allergens.Nut):
        return 'Jezgrasto voće';
      case (Allergens.Sesame):
        return 'Susam';
      case (Allergens.Soya):
        return 'Soja';
      case (Allergens.Peanuts):
        return 'Kikiriki';
      case (Allergens.Mustard):
        return 'Senf';
      case (Allergens.Mollusca):
        return 'Mekušci';
      case (Allergens.Milk):
        return 'Mleko';
      case (Allergens.Crustacean):
        return 'Rakovi';
      case (Allergens.Lupine):
        return 'Lupin';
      case (Allergens.Fish):
        return 'Riba';
      case (Allergens.Egg):
        return 'Jaja';
      case (Allergens.Celery):
        return 'Celer';
    }

    return '';
  }

}
