import { TookanProxyService } from './tookan-proxy.service';
import { Injectable } from '@angular/core';
import { Agent } from '../_interface/agent';
import { BehaviorSubject } from 'rxjs';
import { PickupAndDeliveryRequest, PickupAndDeliveryResponse } from '../_interface/task';

@Injectable({
  providedIn: 'root'
})
export class TookanService {

  private _agents?: BehaviorSubject<Agent[]>;

  constructor(private proxy: TookanProxyService) { }


  setApiKey(api_key: string) {
    this.proxy.api_key = api_key;
  }

  isEnabled() {
    return this.proxy.api_key != "";
  }


  get agents() {
    if (!this._agents) {
      this._agents = new BehaviorSubject<Agent[]>([]);
      this.proxy.getAllAgents().then(val => this._agents?.next(val));
    }

    return this._agents;
  }


  createTask(task: PickupAndDeliveryRequest) {
    return this.proxy.createTask<PickupAndDeliveryResponse>(task);
  }
}
