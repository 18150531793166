<app-dialog-title>
  Detalji o porudžbini <span class="orderNo">#{{ orderID }}</span>
</app-dialog-title>

<mat-dialog-content>
  <ng-container *ngIf="order; else loading">
    <app-property-item name="id" [value]="order.id"></app-property-item>
    <app-property-item
      name="Broj porudžbine"
      [value]="order.order_number"
    ></app-property-item>
    <app-property-item
      name="Kreirana"
      [value]="order.created_at | date : 'dd.MM.yyyy HH:mm:ss'"
    ></app-property-item>
    <app-property-item
      name="Modifikovana"
      [value]="order.modified_at | date : 'dd.MM.yyyy HH:mm:ss'"
    ></app-property-item>
    <app-property-item
      name="Preuzimanje"
      [value]="order.pickup_eta | date : 'dd.MM.yyyy HH:mm:ss'"
    ></app-property-item>
    <app-property-item
      name="Isporučeno"
      [value]="order.delivery.time | date : 'dd.MM.yyyy HH:mm:ss'"
    ></app-property-item>
    <app-property-item
      name="Status"
      [value]="order.order_status | woltOrderStatusName"
    ></app-property-item>
    <app-property-item name="Ukupno">
      <span class="price">
        {{ order.price.amount / 100 | number : "1.2-2" }}
      </span>
    </app-property-item>
    <app-property-item
      name="Napomena"
      [value]="order.consumer_comment"
    ></app-property-item>

    <app-group-box title="Korisnik">
      <app-property-item
        name="Naziv"
        [value]="order.consumer_name"
      ></app-property-item>
      <app-property-item
        name="Telefon"
        [value]="order.consumer_phone_number"
      ></app-property-item>
    </app-group-box>
    <app-group-box title="Artikli">
      <div *ngFor="let item of order.items">
        <div class="item">
          <div class="name">
            {{ item.count }}x <span>{{ item.name }}</span>
          </div>
          <div class="price">
            {{ item.base_price.amount / 100 | number : "1.2-2" }}
          </div>
        </div>

        <div class="modifiers">
          <div *ngFor="let option of item.options" class="item">
            <div class="name">
              {{ option.count }}x <span>{{ option.name }}</span
              >-<span>{{ option.value }}</span>
            </div>
            <div class="price">
              {{ option.price.amount / 100 | number : "1.2-2" }}
            </div>
          </div>
        </div>
      </div>
    </app-group-box>

    <button mat-icon-button (click)="showRawData = !showRawData">raw</button>
    <pre *ngIf="showRawData">{{ order | json }}</pre>
  </ng-container>

  <ng-template #loading>
    <mat-spinner></mat-spinner>
  </ng-template>
</mat-dialog-content>
